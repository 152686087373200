import React, { useState } from "react";
import { SetupDropdown } from "../../../data/setupGuide";
import { SetupGuideListWrapper } from "./SetGuide.style";
import { TextWrapper } from "../../../components/tab/account-settings/Account.style";
import { Typography } from "../../../components/typrography/Typography";

export interface setupGuideProps {
  data: SetupDropdown[];
  width?: string;
  padding?: string;
  margin?: string;
}

const SetupGuideList = ({ data, padding, width, margin }: setupGuideProps) => {
  const [activeId, setActiveId] = useState<number | null>(null);

  const handleToggle = (id: number) => {
    if (activeId === id) {
      setActiveId(null); // Close the accordion if it is already open
    } else {
      setActiveId(id); // Open the clicked accordion and close others
    }
  };
  return (
    <SetupGuideListWrapper padding={padding}>
      <TextWrapper>
        {data.map((item, index) => (
          <>
            <Typography
              direction="flex-start"
              variant="2xl"
              color="primary"
              style={{ padding: "0.5vw 0" }}
            >
              {item.question}
            </Typography>
            <Typography
              direction="flex-start"
              variant="lg"
              style={{ padding: "0.5vw 0" }}
            >
              {item.answer}
            </Typography>
          </>
        ))}
      </TextWrapper>
    </SetupGuideListWrapper>
  );
};
export default SetupGuideList;
