import React from "react";
import {
  AllSubscriptionCardWrapper,
  AllSubscriptionWrapper,
  TextButtonWrapper,
  TextWrapper,
} from "../../../components/card/subscription-card/SubscriptionCard.style";
import { StripeProduct } from "../../../interfaces/authentication/Subscription";
import SubscriptionCard from "../../../components/card/subscription-card/SubscriptionCard";
import { Typography } from "../../../components/typrography/Typography";

interface AvailableSubscriptionsProps {
  subscriptions: StripeProduct[] | undefined;
  selectedSubscription: StripeProduct | null;
  handleSubscriptionSelection: (sub: StripeProduct) => void;
}

const AvailableSubscriptions: React.FC<AvailableSubscriptionsProps> = ({
  subscriptions,
  selectedSubscription,
  handleSubscriptionSelection,
}: AvailableSubscriptionsProps) => {
  return (
    <AllSubscriptionWrapper>
      <TextWrapper>
        <Typography
          direction="flex-start"
          variant="xl"
          style={{ padding: "0.5vw 0" }}
        >
          Available Subscriptions
        </Typography>
      </TextWrapper>
      <AllSubscriptionCardWrapper>
        {subscriptions?.map((sub: StripeProduct) => (
          <SubscriptionCard
            key={sub.Id}
            title={sub.Name}
            price={`CAD $${sub.Metadata["monthly-price"]}/Mo`}
            descriptions={sub.Description?.split(",") || []}
            displayTriangleTag={
              sub.Metadata["months"] === "24" ? "flex" : "none"
            }
            isSelected={selectedSubscription?.Id === sub.Id}
            onClick={() => handleSubscriptionSelection(sub)}
          />
        ))}
      </AllSubscriptionCardWrapper>
      <TextButtonWrapper>
        <TextWrapper>
          <Typography
            direction="flex-start"
            variant="2xl"
            style={{ padding: "0.5vw 0" }}
          >
            Attention!
          </Typography>
          <Typography
            direction="flex-start"
            variant="lg"
            color="primary"
            style={{ padding: "0.75vw 0" }}
          >
            Any changes will take effect at the end of the current subscription
            cycle.
          </Typography>
          <Typography
            direction="flex-start"
            variant="lg"
            style={{ padding: "0.75vw 0" }}
          >
            Note: Please email support@swrm.ai if additional storage space is
            required.
          </Typography>
        </TextWrapper>
      </TextButtonWrapper>
    </AllSubscriptionWrapper>
  );
};

export default AvailableSubscriptions;
