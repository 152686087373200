import React, { useMemo } from "react";
import { Area, Column } from "@ant-design/charts";
import { FramesChartWrapper } from "../../components/data-stats/DataStats.style";
import { Typography } from "../../components/typrography/Typography";
import { DailyHistoricalData, HistoricalData } from "./HistoricalChartSection";
import { DefaultThemeColor } from "../../app/Theme";

// Define the expected props for the WeeklyChart component
interface FramesChartProps {
  type: string;
  title: string;
  data: HistoricalData[] | DailyHistoricalData[];
  chartType: "Bar" | "Line";
  yFieldLabel: string;
}

const HistoricalChart: React.FC<FramesChartProps> = ({
  type,
  title,
  data,
  chartType,
  yFieldLabel,
}: FramesChartProps) => {
  // Memoize the chart configuration to avoid unnecessary recalculations on re-renders
  const chartConfig = useMemo(
    () => ({
      data: data,
      xField: "date",
      yField: yFieldLabel || "average",
      xAxis: {
        title: { text: "Date" },
        range: [0, 1],
      },
      yAxis: {
        title: { text: "Sensor Value" },
      },
      color: DefaultThemeColor["primary"],
    }),
    [data],
  );

  const dailyChartConfig = useMemo(
    () => ({
      data: data,
      xField: "time",
      yField: yFieldLabel || "average",
      xAxis: {
        title: { text: "Hour of Day" },
        range: [0, 1],
      },
      yAxis: {
        title: { text: "Sensor Value" },
      },
      color: DefaultThemeColor["primary"],
    }),
    [data],
  );

  const barConfig = useMemo(
    () => ({
      data,
      xField: "date",
      yField: yFieldLabel || "count",
      seriesField: "type",
      isGroup: true,
      xAxis: {
        title: { text: "Date" },
        range: [0, 1],
      },
      yAxis: {
        title: { text: "Sensor Value" },
      },
      color: [DefaultThemeColor["primary"], "#41D8CE", "#D84187"],
      columnStyle: {
        radius: [0, 0, 0, 0],
      },
    }),
    [data],
  );

  const dailyBarConfig = useMemo(
    () => ({
      data,
      xField: "time",
      yField: yFieldLabel || "count",
      seriesField: "type",
      isGroup: true,
      xAxis: {
        title: { text: "Hour" },
        range: [0, 1],
      },
      yAxis: {
        title: { text: "Sensor Value" },
      },
      color: [DefaultThemeColor["primary"], "#41D8CE", "#D84187"],
      columnStyle: {
        radius: [0, 0, 0, 0],
      },
    }),
    [data],
  );

  return (
    <FramesChartWrapper>
      {/* Display chart type */}
      <Typography variant="xl" display="flex" direction="flex-start">
        {type}
      </Typography>
      {/* Display chart title */}
      <Typography
        variant="xl"
        weight="bold"
        color="primary"
        display="flex"
        direction="flex-start"
      >
        {title}
      </Typography>
      {/* Render Area chart with the memoized configuration */}
      {chartType === "Bar" ?
        <Column {...(type === "Daily" ? dailyBarConfig : barConfig)} />
      : <Area {...(type === "Daily" ? dailyChartConfig : chartConfig)} />}
    </FramesChartWrapper>
  );
};

export default HistoricalChart;
