import styled from "styled-components";
import { TabLinksProps } from "./TabLinks";

export const CustomTabLinks = styled.button<
  Pick<
    TabLinksProps,
    "className" | "active" | "borderDirection" | "tabWidth" | "fontSize"
  >
>`
  padding-left: 8px;
  font-size: ${({ fontSize, theme }) =>
    fontSize ? fontSize : theme.fontSizes.xl};
  background-color: transparent;
  border: none;
  color: ${(props) =>
    props.className === "active" ?
      props.theme.colors["primary"]
    : props.theme.colors["grey-800"]};
  display: flex;
  flex-direction: ${({ borderDirection }) =>
    borderDirection ? borderDirection : "column"};
  gap: 9px;
  align-items: center;
  width: ${({ tabWidth }) => (tabWidth ? tabWidth : "auto")};
  cursor: pointer;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.white};
  }
  &.active {
    color: ${(props) => props.theme.colors["primary"]};

    :after {
      display: block;
      height: ${({ borderDirection }) =>
        borderDirection && borderDirection === "row" ? "3.7vw" : "7px"};
      width: ${({ borderDirection }) =>
        borderDirection && borderDirection === "row" ? "7px" : "5vw"};
      background: ${(props) => props.theme.colors["primary"]};
      content: "";
      align-self: center;
    }
  }
`;

export const CustomChartTabLinks = styled.button<
  Pick<TabLinksProps, "children" | "className" | "active">
>`
  padding: 8px;
  font-size: ${(props) => props.theme.fontSizes.xl};
  background-color: transparent;
  border: none;
  border-radius: 26px;
  width: 6rem;

  &:hover {
    color: ${(props) => props.theme.colors["black-200"]};
    background: ${(props) => props.theme.colors["primary"]};
    cursor: pointer;
  }
  &.active {
    color: ${(props) => props.theme.colors["black-200"]};
    background: ${(props) => props.theme.colors["primary"]};
  }

  color: ${(props) =>
    props.className === "active" ?
      props.theme.colors["black-200"]
    : props.theme.colors["primary"]};
  display: flex;
  flex-direction: column;
  gap: 9px;
  align-items: center;
`;
