import React, { useCallback, useState } from "react";
import {
  CustomToggleButton,
  ToggleButtonWrapper,
} from "./VerticalToggleButton.style";
import { useAppDispatch } from "../../../store/hooks";
import { toggleSwitchButton } from "../../../store/slices/tabs/tabListSlice";

export interface ToggleButtonProps {
  children?: any;
  id: number;
  label: string;
  isActive: boolean;
  width?: string;
  height?: string;
  onToggle?: (newState: boolean) => void;
}

export const VerticalToggleButton: React.FC<ToggleButtonProps> = ({
  id,
  label,
  isActive,
  width,
  height,
  onToggle,
}: ToggleButtonProps) => {
  const [switchState, setSwitchState] = useState(isActive);

  const handleOnChange = useCallback(() => {
    const newState = !switchState;
    setSwitchState(newState);

    if (onToggle) {
      onToggle(newState);
    }
    if (id) {
      dispatch(toggleSwitchButton(id));
    }
  }, [onToggle, switchState]);
  const dispatch = useAppDispatch();

  return (
    <ToggleButtonWrapper width={width} height={height}>
      <label>{label}</label>
      <CustomToggleButton htmlFor={`checkbox${id}`} checked={switchState}>
        <input
          id={`checkbox${id}`}
          type="checkbox"
          checked={switchState}
          onChange={handleOnChange}
        />
      </CustomToggleButton>
    </ToggleButtonWrapper>
  );
};
