import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosResponse } from "axios";
import { ResponseObject } from "../../interfaces/response/Response";
import { DeviceGroup } from "../../interfaces/device/DeviceGroup";
import {
  validateErrorResult,
  validateSuccessResult,
} from "../../utils/axiosUtils";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

export const postDeviceGroup = async (
  payload: {
    id: number;
    deviceId: string;
    groupId: number;
  },
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .post(`${API_URL}/DeviceGroup`, payload)
    .then(async (response: AxiosResponse<ResponseObject<DeviceGroup>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Device updated successfully.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

export const deleteDeviceGroup = async (
  id: number,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .delete(`${API_URL}/DeviceGroup?id=${id}`)
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Device updated successfully.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};
