import { useEffect, useState } from "react";
import { getLastScannedSensorData } from "../../services/dashboard/dashboard.service";
import GaugePanel from "../../components/data-stats/GaugePanel";
import { isTodayDate } from "../../utils/dateUtils";
import { GaugeChartBlock, GaugeChartWrapper } from "./Dashboard.style";
import { Typography } from "../../components/typrography/Typography";
import { RootState } from "../../store/store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Loader from "../../components/loader/Loader";
import NoDataAvailable from "../../components/loader/NoDataAvailable";
import {
  booleanSensor,
  formatIntegratedSensorValues,
} from "../../utils/dashboardUtils";
import { integrationService } from "../../services/integration/integration.service";
import { ResponseObject } from "../../interfaces/response/Response";
import { IntegratedLatestSensorData } from "../../interfaces/sensorData/IntegratedSensorData";
import {
  DeviceWithSensorValues,
  FormattedSensorValues,
  GroupedDevice,
  GroupedSensor,
  GroupedSensorValue,
} from "../../interfaces/sensorData/SensorData";

// Define interfaces
interface Props {
  selectedLocation: number | null | undefined;
  selectedDevice: string | null | undefined;
}

const GaugeChartSection = ({ selectedLocation, selectedDevice }: Props) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const devices = useAppSelector((state: RootState) => state.devices.data);
  const auth = useAppSelector((state: RootState) => state.persisted.auth);
  const integrations = useAppSelector(
    (state: RootState) => state.integration.integrations,
  );
  // State to manage sensor data
  const [devicesWithSensor, setDevicesWithSensor] = useState<any>([]);
  const [integrationSensors, setIntegrationSensors] = useState<any>([]);
  const specificOrder = [
    "Temperature",
    "Humidity",
    "AQI",
    "Current",
    "Voltage",
    "Wind",
    "CO2",
    "Moisture",
    "Conductivity",
    "DiElectricity",
    "Salinity",
    "Field Capacity",
    "Wilting Point",
    "Water Balance",
    "Oxygen",
  ];
  const hideSensor = ["Unknown", "Door"];

  // Fetch data initially when the component mounts
  useEffect(() => {
    fetchData();
    // Fetch data every 10 minutes
    const interval = setInterval(
      () => {
        fetchData();
      },
      10 * 60 * 1000,
    ); // 10 minutes in milliseconds

    // Clean up the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, [selectedLocation, selectedDevice]);

  // Function to format date for integrated sensors
  const formatDate = (timeTaken: string) => {
    const currentTime = new Date(timeTaken || "");

    // Convert UTC time to local time
    const localTime = new Date(currentTime + "UTC");

    const time =
      !timeTaken ? "No Data"
      : isTodayDate(currentTime) ?
        `Today at ${localTime.toLocaleTimeString("en-US", {
          timeStyle: "short",
        })}`
      : localTime.toLocaleString("en-US", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });

    return time;
  };

  // Function to format sensor data
  const formatSensorData = (
    sensor: string,
    sensorValue: number,
    timeTaken?: string,
    valueTypeId?: number,
  ): FormattedSensorValues => {
    const currentTime = new Date(timeTaken || "");

    // Convert UTC time to local time
    const localTime = new Date(currentTime + "UTC");

    const title =
      !timeTaken ? "No Data"
      : isTodayDate(currentTime) ?
        `Today at ${localTime.toLocaleTimeString("en-US", {
          timeStyle: "short",
        })}`
      : localTime.toLocaleString("en-US", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });

    // value type id 1 = int
    if (booleanSensor.includes(sensor)) {
      //this line of code is causing a mismatch on the gauge
      //let roundedValue = Number(sensorValue) === 0 ? "Close" : "Open";

      return { value: sensorValue, title };
    } else if (valueTypeId !== 1) {
      const parsedValue = parseFloat(sensorValue.toString()) || 0;
      let roundedValue = parseFloat(parsedValue.toFixed(2)); // Round to two decimal places
      return { value: roundedValue, title };
    } else {
      let roundedValue = Math.round(Number(sensorValue) || 0);
      return { value: roundedValue, title };
    }
  };

  // Function to fetch Integration Device Data
  const fetchIntegrationData = async () => {
    try {
      // Fetch Integration Devices
      if (
        auth.company.IntegratedWith !== "" &&
        auth.company.IntegratedWith !== null &&
        integrations[0].Enabled
      ) {
        const integrationResponse: ResponseObject<
          IntegratedLatestSensorData[]
        > | null = await integrationService.getLatestSensorData(
          dispatch,
          selectedDevice,
          selectedLocation,
        );

        if (integrationResponse !== null) {
          if (
            integrationResponse.Result.length !== 0 &&
            integrationResponse.Result !== null &&
            integrationResponse.Result !== undefined
          ) {
            setIntegrationSensors(integrationResponse.Result);
            return;
          }
        }
      }

      setIntegrationSensors([]);
      return;
    } catch (error) {
      console.error(`Error fetching data:`, error);
    }
  };

  // Function to fetch SWRM Device Data
  const fetchSWRMData = async () => {
    try {
      const deviceSensorValuesMap: Map<
        string,
        Map<string, GroupedSensorValue>
      > = new Map();

      // Fetch SWRM Devices
      const response: ResponseObject<GroupedDevice[]> =
        await getLastScannedSensorData(
          selectedLocation,
          selectedDevice?.toLocaleString(),
        );
      console.log(response);
      console.log(response.Result !== null && !response.IsError);
      if (response.Result !== null && !response.IsError) {
        response.Result.map((gd: GroupedDevice) => {
          const groupedSensorsMap: Map<string, GroupedSensorValue> = new Map();
          gd.Value.map((gs: GroupedSensor) => {
            groupedSensorsMap.set(gs.Key, gs.Value);
          });

          deviceSensorValuesMap.set(gd.Key, groupedSensorsMap);
        });

        // Create an array of devices sensor values
        const deviceSensorValues: DeviceWithSensorValues[] = Array.from(
          deviceSensorValuesMap,
        ).map(
          ([deviceId, groupedSensorValuesMap]: [
            string,
            Map<string, GroupedSensorValue>,
          ]) => {
            // Extract Sensor Names for each device
            const sensorNames: string[] = Array.from(groupedSensorValuesMap)
              .filter(([sensorName]: [string, GroupedSensorValue]) => {
                return !hideSensor.includes(sensorName); // Only include sensors not in hideSensor
              })
              .map(([sensorName]: [string, GroupedSensorValue]) => sensorName); // Return sensor names

            // Extract Unique Sensor Names for each device e.g "Door"
            const uniqueSensorNames: string[] = Array.from(
              groupedSensorValuesMap,
            )
              .filter(([sensorName]: [string, GroupedSensorValue]) => {
                return booleanSensor.includes(sensorName);
              })
              .map(([sensorName]: [string, GroupedSensorValue]) => sensorName);

            // Extract Remaining Sensor Names if there are
            const restSensors = [...sensorNames, ...uniqueSensorNames].filter(
              (sensorNames: string) => !specificOrder.includes(sensorNames),
            );

            // Reorder Sensor Names and Extract Device Sensors
            const reorderedSensors: string[] =
              specificOrder.concat(restSensors);
            const deviceSensors = reorderedSensors.filter(
              (sensorName) =>
                sensorNames.includes(sensorName) ||
                restSensors.includes(sensorName),
            );

            // Format Sensors and Return
            const formattedSensorData: FormattedSensorValues[] =
              deviceSensors.map((sensor) =>
                formatSensorData(
                  sensor,
                  Number(groupedSensorValuesMap.get(sensor)!.Value),
                  groupedSensorValuesMap.get(sensor)?.TimeTaken.toString(),
                  groupedSensorValuesMap.get(sensor)?.ValueTypeId,
                ),
              );
            return {
              deviceId: deviceId,
              sensors: deviceSensors,
              formattedSensorData,
            };
          },
        );

        setDevicesWithSensor(deviceSensorValues);
      } else {
        console.log("No SWRM data available");
      }
    } catch (error) {
      console.error(`Error fetching data:`, error);
    }
  };

  // Function to fetch sensor data
  const fetchData = async () => {
    try {
      setIsLoading(true);
      await fetchIntegrationData();
      await fetchSWRMData();
    } catch (error) {
      console.error(`Error fetching SWRM and Integration data:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDeviceName = (deviceId: string) => {
    const device = devices.find((d) => d.Id === deviceId);
    if (device) {
      return device?.Name || device?.PiSerial || deviceId;
    } else {
      const deviceWithChildDevice = devices.find(
        (d) => d?.ChildDevices?.findIndex((c) => c.Id === deviceId) !== -1,
      );
      if (deviceWithChildDevice) {
        const childDevice = deviceWithChildDevice.ChildDevices?.find(
          (c) => c.Id === deviceId,
        );
        return `${deviceWithChildDevice?.Name} (${
          childDevice?.Name || childDevice?.PiSerial || deviceId
        })`;
      } else {
        return deviceId;
      }
    }
  };
  const renderSensorPanels = (data: any) => {
    return data.sensors.map((sensor: any, index: number) => {
      if (sensor && data.formattedSensorData) {
        return (
          <GaugePanel
            key={sensor}
            percent={data.formattedSensorData[index]?.value || 0}
            title={data.formattedSensorData[index]?.title || "No Data"}
            type={sensor}
            variant={sensor}
            titleVariant="lg"
            indicator={!booleanSensor.includes(sensor)}
          />
        );
      }
      return null;
    });
  };

  const isValidKey = (key: string): boolean => {
    const excludedKeys = ["device", "timestamp", "site"];
    return !excludedKeys.includes(key);
  };

  const renderIntegratedSensorPanels = (
    sensors: IntegratedLatestSensorData[],
  ) => {
    if (sensors.length > 0) {
      return sensors.map((sensor) => (
        <GaugeChartBlock key={sensor.DeviceId}>
          <Typography
            variant="xl"
            display="flex"
            direction="flex-start"
            weight="bold"
          >
            {sensor.TagName} (Soil Scout Sensor)
          </Typography>
          <GaugeChartWrapper>
            {Object.entries(sensor.Measurement).map(([key, value]) => {
              if (value !== undefined && isValidKey(key)) {
                return (
                  <GaugePanel
                    key={`${sensor.DeviceId}-${key}`}
                    percent={formatIntegratedSensorValues(key, value)}
                    title={
                      formatDate(sensor.Measurement.timestamp?.toString()) ||
                      "No Data"
                    }
                    type={key}
                    variant={key}
                    titleVariant="lg"
                  />
                );
              }
              return null;
            })}
          </GaugeChartWrapper>
        </GaugeChartBlock>
      ));
    }
  };

  return (
    <div className="position-relative">
      {isLoading ?
        <Loader />
      : (
        !devicesWithSensor ||
        (!devicesWithSensor.length && !integrationSensors.length)
      ) ?
        <NoDataAvailable />
      : <>
          {/* Integration Specific Devices */}
          {auth.company.IntegratedWith !== "" && (
            <>{renderIntegratedSensorPanels(integrationSensors)}</>
          )}

          {/* SWRM Specific Devices */}
          {devicesWithSensor?.map((data: any) => (
            <GaugeChartBlock key={data.deviceId}>
              <Typography
                variant="xl"
                display="flex"
                direction="flex-start"
                weight="bold"
              >
                {getDeviceName(data.deviceId)}
              </Typography>
              <GaugeChartWrapper>{renderSensorPanels(data)}</GaugeChartWrapper>
            </GaugeChartBlock>
          ))}
        </>
      }
    </div>
  );
};

export default GaugeChartSection;
