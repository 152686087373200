import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { integrationService } from "../../services/integration/integration.service";
import { RootState } from "../../store/store";

const TokenRefresh = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state: RootState) => state.persisted.auth);

  const refreshSession = async () => {
    try {
      await dispatch(integrationService.refreshSession());
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let interval: number | NodeJS.Timeout | undefined;

    if (
      auth.company.IntegratedWith !== "" &&
      auth.tokens.integrationRefreshToken !== ""
    ) {
      // Refresh Session every 13 minutes
      interval = setInterval(refreshSession, 780000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [auth.company.IntegratedWith, auth.tokens.integrationRefreshToken]);

  return null;
};

export default TokenRefresh;
