import CustomModal from "../../../components/modals/CustomModal";
import { Typography } from "../../../components/typrography/Typography";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

const InviteModal = (props: Props) => {
  const { isOpen, onClose, title } = props;

  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0 20px", fontWeight: "700" }}
      >
        {title}
      </Typography>
    );
  };
  const cardFooter = () => {
    return null;
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      padding="10px 0"
      width={""}
      height={""}
      closeBtnTopPosition="0.2rem"
      header={cardHeader()}
      footer={cardFooter()}
    >
      <div style={{ padding: "1rem 1.25rem" }}>
        <Typography
          variant="xl"
          display="flex"
          direction="flex-start"
          style={{ marginBottom: "0.5rem" }}
        >
          Instructions step-by-step:
        </Typography>
        <Typography
          variant="md"
          weight="bold"
          color="primary"
          display="flex"
          direction="flex-start"
        >
          1. Contact Company Administration:
        </Typography>
        <Typography
          variant="md"
          display="flex"
          direction="flex-start"
          padding="0.5rem 1.25rem"
        >
          Request an invitation for the company's administration.
        </Typography>
        <Typography
          variant="md"
          weight="bold"
          color="primary"
          display="flex"
          direction="flex-start"
          padding="0.25rem 0rem"
        >
          2. Check Your Email (including Spam Folder):
        </Typography>
        <Typography
          variant="md"
          display="flex"
          direction="flex-start"
          padding="0.25rem 1.25rem"
        >
          Keep an eye on your email inbox for the invitation.
        </Typography>
        <Typography
          variant="md"
          display="flex"
          direction="flex-start"
          padding="0.25rem 1.25rem"
        >
          If you don't see it in your inbox, check your spam or junk folder to
          ensure it hasn't been filtered incorrectly.
        </Typography>
        <Typography
          variant="md"
          display="flex"
          direction="flex-start"
          padding="0.25rem 1.25rem"
        >
          Once received, follow any instructions provided in the email to
          proceed with the invitation process.
        </Typography>
      </div>
    </CustomModal>
  );
};

export default InviteModal;
