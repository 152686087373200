import React, { useEffect, useRef, useState } from "react";
import { Formik, FormikProps } from "formik";
import { StyledFormikForm } from "../../ai-model-manager/Upload.style";
import * as Yup from "yup";
import { IOption } from "../../../form-field/drop-down/CustomDropdown";
import { FlexContainer } from "../../../flex-container/FlexContainer";
import { FormControl } from "../../../form-field/form-control/FormControl";
import Input from "../../../input/Input";
import { ScaledButtonWrapper } from "../../../modals/CustomModal.style";
import {
  RelayRule,
  RelayRuleAction,
  RuleComparison,
} from "../../../../interfaces/device/Relay";
import relayService from "../../../../services/device/deviceRelays.service";
import { Typography } from "../../../typrography/Typography";
import {
  FilterDropdown,
  FilterOption,
} from "../../../dropdown/filter-dropdown/FilterDropdown";
import {
  MessagePopupSetup,
  showPopupMessage,
} from "../../../../store/slices/messagePopups/messagePopupSlice";
import { useAppDispatch } from "../../../../store/hooks";
import Loader from "../../../loader/Loader";
import CustomModal from "../../../modals/CustomModal";
import { InputLabel, InputWrapper } from "../../../input/Input.style";
import { nonNumericalSensors } from "./RelayRuleDialog";
import { SimplifiedSensor } from "../../../../interfaces/device/Sensor";
interface Props {
  relayId: number;
  allSensor: SimplifiedSensor[];
  deviceDropdownOptions: FilterOption[];
  onCancel: () => void;
  onSaveRuleSuccess: (newRule: RelayRule) => void;
}
interface FormValues {
  DeviceId: string;
  SensorId: number;
  Action: RelayRuleAction;
  Comparison: RuleComparison;
  value: number;
}

const initialValues: FormValues = {
  DeviceId: "",
  SensorId: 0,
  Action: RelayRuleAction.On,
  Comparison: RuleComparison.LessThan,
  value: 0,
};

const validationSchema = Yup.object({
  value: Yup.number()
    .required("Value is required")
    .integer("Only integer allowed"),
});

const TurnOptions: FilterOption[] = [
  {
    label: "On",
    value: RelayRuleAction.On,
  },
  {
    label: "Off",
    value: RelayRuleAction.Off,
  },
];

const DoorSensorOptions: FilterOption[] = [
  {
    label: "Open",
    value: 1,
  },
  {
    label: "Closed",
    value: 0,
  },
];

const WaterSensorOptions: FilterOption[] = [
  {
    label: "Available",
    value: 1,
  },
  {
    label: "Unavailable",
    value: 0,
  },
];

const IsOptions: FilterOption[] = [
  {
    label: "<",
    value: RuleComparison.LessThan,
  },
  {
    label: "<=",
    value: RuleComparison.LessThanEqual,
  },
  {
    label: ">",
    value: RuleComparison.GreaterThan,
  },
  {
    label: ">=",
    value: RuleComparison.GreaterThanEqual,
  },
];

const BooleanIsOptions: FilterOption[] = [
  {
    label: "Is",
    value: RuleComparison.EqualTo,
  },
];

export default function AddRelayRuleModal({
  relayId,
  allSensor,
  deviceDropdownOptions,
  onCancel,
  onSaveRuleSuccess,
}: Props) {
  const dispatch = useAppDispatch();
  const [sensors, setSensors] = useState<IOption[]>([]);
  const [selectedSensorName, setSelectedSensorName] = useState<string>("");
  const [isAddPending, setIsAddPending] = useState<boolean>(false);

  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ fontWeight: "700" }}
      >
        Add Relay Rule
      </Typography>
    );
  };

  const populateSensorValueDropdowns = (sensorName: string) => {
    switch (sensorName) {
      case "Door":
        return DoorSensorOptions;
      case "Water":
        return WaterSensorOptions;
      default:
        return DoorSensorOptions;
    }
  };

  const renderSensorComparisonDropdown = (
    sensorName: string,
    setFieldValue: (field: string, value: string | number | boolean) => void,
  ) => {
    const isNonNumericalSensorValue: boolean =
      nonNumericalSensors.includes(sensorName);
    const resetComparisonInitialValue: FilterOption =
      isNonNumericalSensorValue ? BooleanIsOptions[0] : IsOptions[0];

    return (
      <FormControl>
        <InputWrapper>
          <InputLabel
            htmlFor="Is"
            isColoredLabel
          >{`Is (Comparison)`}</InputLabel>
          <FilterDropdown
            key={sensorName}
            defaultValue={resetComparisonInitialValue}
            title="Is"
            margin="5px 0 0"
            options={isNonNumericalSensorValue ? BooleanIsOptions : IsOptions}
            onChange={({ label, value }: FilterOption) => {
              setFieldValue("Comparison", value);
            }}
          />
        </InputWrapper>
      </FormControl>
    );
  };

  const renderSensorTypeValueInput = (
    sensorName: string,
    handleChange: (e: React.ChangeEvent<any>) => void,
    setFieldValue: (field: string, value: string | number | boolean) => void,
    value?: string,
  ) => {
    const isNonNumericalSensorValue: boolean =
      nonNumericalSensors.includes(sensorName);

    // Render a dropdown or input field
    if (isNonNumericalSensorValue) {
      return (
        <FormControl>
          <InputWrapper>
            <InputLabel htmlFor="Value" isColoredLabel>
              Value
            </InputLabel>
            <FilterDropdown
              key={sensorName}
              defaultValue={populateSensorValueDropdowns(sensorName)[0]}
              title="Value"
              margin="5px 0 0"
              options={populateSensorValueDropdowns(sensorName)}
              onChange={({ label, value }: FilterOption) => {
                setFieldValue("value", value);
              }}
            />
          </InputWrapper>
        </FormControl>
      );
    } else {
      return (
        <FormControl>
          <Input
            key={sensorName}
            type="number"
            name="value"
            placeHolder="Value"
            label={"Value"}
            width="100%"
            inputWrapperWidth={"100%"}
            isColoredLabel
            value={value && value}
            onChange={handleChange}
          />
        </FormControl>
      );
    }
  };

  const handleDeviceIdDropdownChange = (
    e: number | string | boolean,
    field: keyof RelayRule,
    setFieldValue: (field: string, value: string | number | boolean) => void,
  ) => {
    if (field === "DeviceId") {
      setFieldValue(field, e);
      setSensors(allSensor.filter((s: any) => s.deviceId === e));
    }
  };

  const handleSubmit = async (values: FormValues) => {
    setIsAddPending(true);
    if (!values.SensorId) {
      const messagePopupSetup: MessagePopupSetup = {
        code: null,
        message: "Please select sensor",
      };

      dispatch(showPopupMessage(messagePopupSetup));
      setIsAddPending(false);
      return;
    }
    const payload: RelayRule = {
      RelayId: relayId,
      Id: 0,
      DeviceId: values.DeviceId,
      Enable: true,
      At: values.SensorId,
      Action: values.Action,
      SensorId: values.SensorId,
      Comparison: values.Comparison,
      Value: values.value,
    };
    const response = await relayService.addRelayRule(payload, dispatch);

    if (response !== null) {
      onSaveRuleSuccess(response.Result);
    }

    setIsAddPending(false);
  };

  useEffect(() => {
    const isNonNumericalSensorValue =
      nonNumericalSensors.includes(selectedSensorName);

    // Set values based on selected sensor
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        "value",
        isNonNumericalSensorValue ? 1 : 0,
      );
      formikRef.current.setFieldValue(
        "Comparison",
        isNonNumericalSensorValue ? 4 : 0,
      );
    }
  }, [selectedSensorName]);

  const formikRef = useRef<FormikProps<FormValues>>(null);

  return (
    <CustomModal
      isOpen={true}
      onClose={onCancel}
      padding="1rem"
      width="63vw"
      height="auto"
      header={cardHeader()}
    >
      {isAddPending && (
        <Loader loadingText="Adding relay rule" isLoadingScreen />
      )}

      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, setFieldValue }) => (
          <StyledFormikForm id="add-relay-rule" key={relayId}>
            <FlexContainer
              gap="1rem"
              justifyContent="center"
              alignItems="center"
            >
              {/* Add relay rules  */}
              {/*1. Turn: Switch On/Off */}
              <FormControl>
                <InputWrapper>
                  <InputLabel htmlFor="Turn" isColoredLabel>
                    Turn (On/Off)
                  </InputLabel>
                  <FilterDropdown
                    defaultValue={TurnOptions[0]}
                    title="Action"
                    margin="5px 0 0"
                    options={TurnOptions}
                    onChange={({ label, value }: FilterOption) => {
                      setFieldValue("Action", value);
                    }}
                  />
                </InputWrapper>
              </FormControl>

              {/*2. At: Select Device */}
              <FormControl>
                <InputWrapper>
                  <InputLabel htmlFor="At" isColoredLabel>
                    At (Device)
                  </InputLabel>
                  <FilterDropdown
                    title="Select Device"
                    margin="5px 0 0"
                    options={deviceDropdownOptions}
                    onChange={({ label, value }: FilterOption) => {
                      handleDeviceIdDropdownChange(
                        value,
                        "DeviceId",
                        setFieldValue,
                      );
                    }}
                  />
                </InputWrapper>
              </FormControl>

              {/*3. When: Select Sensor */}
              <FormControl>
                <InputWrapper>
                  <InputLabel htmlFor="When" isColoredLabel>
                    When (Sensor)
                  </InputLabel>
                  <FilterDropdown
                    title="Select Sensor"
                    margin="5px 0 0"
                    options={
                      sensors?.map((sensor) => ({
                        label: sensor.value,
                        value: sensor.id,
                      })) || []
                    }
                    onChange={({ label, value }: FilterOption) => {
                      setFieldValue("SensorId", value);
                      setSelectedSensorName(label);
                    }}
                  />
                </InputWrapper>
              </FormControl>

              {/*4. Is:  >, >=, <, <=, Is */}
              {renderSensorComparisonDropdown(
                selectedSensorName,
                setFieldValue,
              )}

              {/*5. Value */}
              {renderSensorTypeValueInput(
                selectedSensorName,
                handleChange,
                setFieldValue,
                values.value.toString(),
              )}
            </FlexContainer>

            {/* Action buttons */}
            <FlexContainer
              gap="1rem"
              justifyContent="center"
              alignItems="center"
            >
              <ScaledButtonWrapper buttonPadding={"10px"}>
                <button type="submit" form="add-relay-rule">
                  Add
                </button>
                <button type="button" className="danger" onClick={onCancel}>
                  Cancel
                </button>
              </ScaledButtonWrapper>
            </FlexContainer>
          </StyledFormikForm>
        )}
      </Formik>
    </CustomModal>
  );
}
