import React from "react";
import { Theme } from "../../app/Theme";
import { ContentWrapper } from "../../components/content-wrapper/ContentWrapper";
import SideNavBar from "../../components/nav/side-nav/SideNavBar";
import TopNavBar from "../../components/nav/top-nav/TopNavBar";
import DashboardContent from "./DashboardContent";

const Dashboard: React.FC = () => {
  return (
    <Theme>
      {/* Sidebar Navigation */}
      <SideNavBar />

      {/* Content Section */}
      <ContentWrapper>
        {/* Top Navigation */}
        <TopNavBar />

        {/* Dashboard Content */}
        <DashboardContent />
      </ContentWrapper>
    </Theme>
  );
};

export default Dashboard;
