import { Device } from "./Device";

export interface Relay {
  Id: number;
  DeviceId: string;
  Devices?: Device;
  Name: string;
  Active: boolean;
  PinNumber: number;
}
export interface RelayValues {
  Id: number;
  RelayId: number;
  Relay: Relay | null;
  Value: boolean;
  TimeTaken: Date;
}
export interface RelayWithValues {
  Id: number;
  DeviceId: string;
  Devices?: Device;
  Name: string;
  Active: boolean;
  PinNumber: number;
  RelayValues: RelayValues[];
}

export interface RelayRule {
  Id: number;
  DeviceId: string;
  Devices?: Device;
  Enable: boolean;
  Action: RelayRuleAction;
  At: number;
  RelayId: number;
  Relay?: Relay;
  SensorId: number;
  Comparison: RuleComparison;
  Value: number;
}
export enum RuleComparison {
  LessThan,
  LessThanEqual,
  GreaterThan,
  GreaterThanEqual,
  EqualTo,
}
export enum RelayRuleAction {
  On,
  Off,
}
export enum RuleComparisonToDisplay {
  "<",
  "<=",
  ">",
  ">=",
  "=",
}
