import { Routes } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { RouteSelector } from "./routerModules/componentBuilder";
import { useAuth0 } from "@auth0/auth0-react";

// Instead of BrowserRouter, we use the regular router,
// but we pass in a customer history to it.
function AppRouter(props: any) {
  //get the auth state from redux persist
  const { isLoading, isAuthenticated, logout } = useAuth0();
  const routeOption = useAppSelector(
    (state) => state.persisted.routes.RouteOption,
  );
  const persistRoot = JSON.parse(localStorage.getItem("persist:root")!);
  const auth = persistRoot?.auth ? JSON.parse(persistRoot?.auth) : null;

  return (
    <>
      {!isLoading && (
        <Routes>
          {RouteSelector(
            isAuthenticated,
            routeOption,
            auth?.hasSignedInPreviously || false,
            auth?.userRole,
          )}
        </Routes>
      )}
    </>
  );
}

export default AppRouter;
