import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  getCompanySubscriptions,
  getLastPaymentCardDetails,
  getSubscriptions,
} from "../../../services/payment/payment.service";
import {
  CardDetails,
  CompanySubscription,
  StripeProduct,
} from "../../../interfaces/authentication/Subscription";

export interface SubscriptsState {
  subscriptionPurchaseCount: number;
  companySubscription: CompanySubscription[] | [];
  subscriptions: StripeProduct[];
  selectedSubscription: StripeProduct | null;
  lastPaymentCard: CardDetails | null;
}

// initial state for all options starts as an empty array
const initialState: SubscriptsState = {
  subscriptionPurchaseCount: 0,
  companySubscription: [],
  subscriptions: [],
  selectedSubscription: null,
  lastPaymentCard: null,
};

export const subscriptionsSlice = createSlice({
  name: "subscriptionsOptions",
  initialState,
  reducers: {
    setSelectedSubscription: (state, action: PayloadAction<StripeProduct>) => {
      state.selectedSubscription = { ...action.payload };
    },
    resetSelectedSubscription: (state) => {
      state.selectedSubscription = null;
    },
    increaseSubscriptionPurchaseCount: (state) => {
      state.subscriptionPurchaseCount += 1;
    },
  },
  extraReducers: (build) => {
    build.addCase(getSubscriptions.fulfilled, (state, action) => {
      state.subscriptions = action.payload.Result;
    });
    build.addCase(getCompanySubscriptions.fulfilled, (state, action) => {
      if (action.payload.Result) {
        state.companySubscription = action.payload.Result;
      }
    });
    build.addCase(getLastPaymentCardDetails.fulfilled, (state, action) => {
      state.lastPaymentCard = action.payload.Result;
    });
  },
});
export const {
  setSelectedSubscription,
  resetSelectedSubscription,
  increaseSubscriptionPurchaseCount,
} = subscriptionsSlice.actions;
// export slice as a reducer
export default subscriptionsSlice.reducer;
