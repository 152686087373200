export const booleanSensor = ["Door"];
export const activationSensor = ["Water"];
// Function to convert sensor names to readable names
export const sensorNameToReadableName = (sensor: string): string => {
  // Convert the sensor string to lowercase for easier comparison
  const sensorLowercase = sensor.toLowerCase();

  // Object to map sensor names to their readable counterparts
  const sensorMap: Record<string, string> = {
    temperature: "Temperature",
    humidity: "Humidity",
    aqi: "AQI",
    current: "Current",
    voltage: "Voltage",
    wind: "Wind Speed",
    co2: "CO2",
    pollution: "Pollution",
    differentialpressure: "Differential Pressure",
    ozone: "Ozone",
    moisture: "Moisture",
    conductivity: "Conductivity",
    dielectricity: "DiElectricity",
    salinity: "Salinity",
    field_capacity: "Field Capacity",
    wilting_point: "Wilting Point",
    water_balance: "Water Balance",
    oxygen: "Oxygen",
    // Add more sensor mappings here as needed
  };

  // Check if the sensor name exists in the mapping, else return the original sensor name
  return sensorMap[sensorLowercase] || sensor;
};

// Function to format property values from integrated sensors
export const formatIntegratedSensorValues = (
  key: string,
  value: number,
): number => {
  if (value !== undefined && value !== null) {
    switch (key) {
      case "moisture":
        return Number((value * 100).toFixed(1));
      case "dielectricity":
        return Number(value.toFixed(2));
      case "salinity":
        return Number(value.toFixed(2));
      case "field_capacity":
        return Number(value * 100);
      case "water_balance":
        return Number(value.toFixed(2));
      default:
        return Number(value.toFixed(2));
    }
  }

  return value;
};

// Function to format sensor values with appropriate units
export const sensorValueWithUnit = (variant: string, value: number): string => {
  if (booleanSensor.includes(variant)) {
    return Number(value) ? "Open" : "Closed";
  }

  if (activationSensor.includes(variant)) {
    return Number(value) ? "Available" : "Unavailable";
  }

  // Default formatting: convert value to string
  let formattedValue = `${Number(value)}`;
  // Object to map sensor variants to their corresponding units
  const unitMap: Record<string, string> = {
    temperature: "℃",
    wind: "km/h",
    humidity: "%",
    current: "mA",
    ozone: "ppb",
    voc: "ppb", // Assuming VOC uses the same unit as ozone
    co2: "ppm",
    uvc: "mW/cm^2",
    differentialpressure: "kPa",
    voltage: "mV",
    moisture: "%",
    salinity: "dS/m",
    field_capacity: "%",
    // Add more variants and units here as needed
  };

  // Check if the variant exists in the mapping, else return the default formatted value
  if (unitMap.hasOwnProperty(variant.toLowerCase())) {
    formattedValue = `${Number(value)}${unitMap[variant.toLowerCase()]}`;
  }

  return formattedValue;
};
