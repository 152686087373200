import React, { FC } from "react";
import { TabLinks } from "../tab/tab-links/TabLinks";
import { TabList } from "../tab/tab-list/TabList";
import { TabPanel } from "../tab/tab-panel/TabPanel";
import { CustomTabs } from "./Tabs.style";
import { ChartTabLinks } from "../tab/tab-links/ChartTabLinks";

export type TabsProps = {
  tabs: {
    label: string;
    index: number;
    disabled?: boolean;
    Component: FC<{ index: number }>;
  }[];
  selectedTab: number;
  onClick: (index: number) => void;
  className?: string;
  variant?: "pageTab" | "subPageTab" | "chartTab";
  justifyContent?: string;
  style?: any;
  padding?: string;
  tabsDirection?: string;
  customTabsDisplay?: string;
  borderDirection?: string;
  tabWidth?: string;
  fontSize?: string;
  tabPanelPadding?: string;
  backgroundColor?: string;
};

export const Tabs: FC<TabsProps> = ({
  tabs = [],
  selectedTab = 0,
  onClick,
  variant,
  justifyContent,
  style,
  padding,
  tabsDirection,
  customTabsDisplay,
  borderDirection,
  tabWidth,
  fontSize,
  tabPanelPadding,
  backgroundColor,
}) => {
  const Panel = tabs && tabs.find((tab) => tab.index === selectedTab);
  return (
    <CustomTabs
      tabs={tabs}
      selectedTab={selectedTab}
      padding={padding}
      customTabsDisplay={customTabsDisplay}
      backgroundColor={backgroundColor}
    >
      <TabList
        variant={variant}
        justifyContent={justifyContent}
        tabsDirection={tabsDirection}
      >
        {variant === "chartTab" ?
          tabs.map((tab) => (
            <ChartTabLinks
              className={selectedTab === tab.index ? "active" : ""}
              onClick={() => onClick(tab.index)}
              tabIndex={selectedTab === tab.index ? 0 : -1}
              key={tab.index}
              active={selectedTab === tab.index}
              style={style}
            >
              {tab.label}
            </ChartTabLinks>
          ))
        : tabs.map((tab) => (
            <TabLinks
              className={selectedTab === tab.index ? "active" : ""}
              onClick={() => onClick(tab.index)}
              tabIndex={selectedTab === tab.index ? 0 : -1}
              key={tab.index}
              active={selectedTab === tab.index}
              style={style}
              borderDirection={borderDirection}
              tabWidth={tabWidth}
              fontSize={fontSize}
              disabled={tab.disabled || false}
            >
              {tab.label}
            </TabLinks>
          ))
        }
      </TabList>
      <TabPanel tabPanelPadding={tabPanelPadding}>
        {Panel && <Panel.Component index={selectedTab} key={selectedTab} />}
      </TabPanel>
    </CustomTabs>
  );
};
