import React, { useEffect } from "react";
import { BillingWrapper } from "./Billing.style";
import { TextWrapper } from "./Account.style";
import { Typography } from "../../typrography/Typography";
import Subscription from "../../../pages/accountSettings/Billing/Subscription";
import PaymentMethod from "../../../pages/accountSettings/Billing/PaymentMethod";
import {
  getCompanySubscriptions,
  getSubscriptions,
} from "../../../services/payment/payment.service";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

type Props = {};

const Billing = (props: Props) => {
  const auth = useAppSelector((state) => state.persisted.auth);
  const { selectedSubscription, subscriptionPurchaseCount } = useAppSelector(
    (state) => state.subscriptions,
  );
  const dispatch = useAppDispatch();
  // fetch all subscriptions on initial load of page
  useEffect(() => {
    dispatch(getSubscriptions());
  }, []);
  useEffect(() => {
    dispatch(getCompanySubscriptions());
  }, [auth?.company?.UID, subscriptionPurchaseCount]);
  return (
    <BillingWrapper>
      <TextWrapper>
        <Typography
          direction="flex-start"
          variant="2xl"
          style={{ padding: "0.5vw 0" }}
        >
          Billing and Payment Settings
        </Typography>
        <Typography
          direction="flex-start"
          variant="lg"
          color="primary"
          style={{ padding: "0.5vw 0" }}
        >
          Sets payment preferences for this account. (If this is a company
          account, payment is handled through the administrative account).
        </Typography>
      </TextWrapper>
      <Subscription />
      {selectedSubscription?.Id ? <PaymentMethod /> : null}
    </BillingWrapper>
  );
};

export default Billing;
