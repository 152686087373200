import React, { useCallback, useRef, useState } from "react";
import {
  DropZone,
  FileIconWrapper,
  FileName,
  StyledIoMdCloseCircle,
} from "./DragUpload.style";
import { MdUploadFile } from "react-icons/md";
import { ImUpload } from "react-icons/im";
import { OpenWarningNotification } from "../../notification/Notification";
import { formatBytes } from "../../../utils";

export const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 104857600; // 100MB
export interface DragUploadProps {
  onFileUpload: (file: File) => void;
  id?: string;
  ref?: any;
  variant?: "input";
  labelText?: string;
  width?: string;
  height?: string;
  color?: string;
  iconColor?: string;
  onFileRemove?: Function;
  accept?: string;
  maxFileSizeInBytes?: number;
  fileType?: Array<"image" | "video">;
  flex?: number;
}

const DragUpload: React.FC<DragUploadProps> = ({
  onFileUpload,
  id,
  variant,
  labelText,
  accept,
  width,
  height,
  color,
  iconColor,
  onFileRemove,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  fileType,
  flex,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      handleFileUploading(file);
    },
    [onFileUpload],
  );
  const validateFileType = (file: File, fileType?: string[]) => {
    if (!fileType) return true;
    const inputFileType: string = file?.type?.split("/")[0];
    if (inputFileType && fileType.includes(inputFileType)) {
      return true;
    } else {
      const msg = fileType.join(", ");
      OpenWarningNotification(
        `Only ${msg} ${fileType.length > 1 ? "files" : "file"} allowed!`,
      );
      resetFileInput();
      return false;
    }
  };
  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
  }, []);

  const handleFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        handleFileUploading(file);
      }
    },
    [onFileUpload],
  );
  const handleFileUploading = (file: File) => {
    if (validateFileType(file, fileType)) {
      if (file.size <= maxFileSizeInBytes) {
        onFileUpload(file);
        setFileName(file.name);
      } else {
        OpenWarningNotification(
          `File must smaller than ${formatBytes(maxFileSizeInBytes)}`,
        );
        resetFileInput();
      }
    }
  };
  const resetFileInput = () => {
    if (fileInputRef?.current?.value) {
      fileInputRef.current.value = "";
    }
  };
  const handleFileUploadClick = (event: any) => {
    fileInputRef.current!.click();
  };

  return (
    <DropZone
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onClick={handleFileUploadClick}
      width={width}
      height={height}
      color={color}
      flex={flex}
      style={{ cursor: "pointer" }}
    >
      {fileName ?
        <>
          <FileIconWrapper style={{ cursor: "pointer" }}>
            <MdUploadFile size={"2rem"} style={{ margin: "0.5rem 0" }} />
            <StyledIoMdCloseCircle
              onClick={(event) => {
                event.stopPropagation();
                onFileRemove && onFileRemove();
                setFileName(null);
                resetFileInput();
              }}
            />
          </FileIconWrapper>
          <FileName title={fileName}>{fileName}</FileName>
        </>
      : <>
          <label style={{ cursor: "pointer" }}>
            <ImUpload
              size={"4rem"}
              color={iconColor}
              style={{ margin: "0.5rem 0" }}
            />
          </label>
          <label style={{ cursor: "pointer" }}>
            {labelText ? labelText : "Choose a file or drag it here"}
          </label>
        </>
      }
      <input
        ref={fileInputRef}
        id={id || "file-input"}
        type="file"
        accept={accept ? accept : "*"}
        onChange={handleFileInputChange}
        onClick={handleFileUploadClick}
        hidden
      />
    </DropZone>
  );
};

export default DragUpload;
