import React from "react";
import { useAppSelector } from "../../store/hooks";
import { SubTitle } from "../title/Titles.styles";
import Radio from "../radio-button/Radio";
import { selectAllRadioButtons } from "../radio-button/radioSlice";
import { RadioSection } from "./StatusRadio.style";

interface StatusProps {
  label: string;
  onChange?: Function;
}

const DeviceStatus: React.FC<StatusProps> = (props: StatusProps) => {
  const label = props.label;
  const allRadioButtons = useAppSelector(selectAllRadioButtons);
  const handleChange = () => {
    props.onChange && props.onChange();
  };
  return (
    <>
      <SubTitle>{label}</SubTitle>
      <RadioSection>
        {allRadioButtons.map((radio) =>
          label === radio.label ?
            <Radio key={radio.id} radio={radio} onChange={handleChange}></Radio>
          : "",
        )}
      </RadioSection>
    </>
  );
};

export default DeviceStatus;
