import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store/store";

const initialState = [
  {
    id: 1,
    name: "Dashboard Stats",
    isActive: true,
    href: "/dashboard",
    position: "middle",
    allowedUserRole: ["viewer", "editor", "owner"],
  },
  {
    id: 2,
    name: "Device Manager",
    isActive: false,
    href: "/devices",
    position: "middle",
    allowedUserRole: ["editor", "owner"],
  },
  {
    id: 3,
    name: "Captures",
    isActive: false,
    href: "/captures",
    position: "middle",
    allowedUserRole: ["editor", "owner"],
  },
  {
    id: 4,
    name: "Locations & Groups",
    isActive: false,
    href: "/locations",
    position: "middle",
    allowedUserRole: ["editor", "owner"],
  },
  {
    id: 5,
    name: "Permissions",
    isActive: false,
    href: "/permissions",
    position: "middle",
    allowedUserRole: ["owner"],
  },
  {
    id: 6,
    name: "Computer Vision Manager",
    isActive: false,
    href: "/cv-model-manager",
    position: "middle",
    allowedUserRole: ["editor", "owner"],
  },
  // Hide the "integration", "notifictions" and "report a bug" side nav bars on Nov 29,2023 as required
  // {
  //   id: 7,
  //   name: "Integration",
  //   isActive: false,
  //   href: "/integration",
  //   position: "middle",
  // },
  // {
  //   id: 8,
  //   name: "Notifications",
  //   isActive: false,
  //   href: "/notifications",
  //   position: "bottom",
  // },

  {
    id: 8,
    name: "Wiki",
    isActive: false,
    href: "/wiki",
    position: "bottom",
  },
  {
    id: 9,
    name: "Help",
    isActive: false,
    href: "/help",
    position: "bottom",
  },
  {
    id: 10,
    name: "Report a Bug",
    isActive: false,
    href: "/reportABug",
    position: "bottom",
  },
];

export const sideNavBarSlice = createSlice({
  name: "sideNavBar",
  initialState,
  reducers: {
    activeNavLink: (state, action: PayloadAction<number>) => {
      const activedLinkId = action.payload;

      state.map((link) => {
        if (activedLinkId === link.id) return (link.isActive = true);
        else return (link.isActive = false);
      });
    },
    resetNavLink: (state) => {
      state.map((link) => {
        if (link.name === "Dashboard Stats") return (link.isActive = true);
        else return (link.isActive = false);
      });
    },
  },
});

export const { activeNavLink, resetNavLink } = sideNavBarSlice.actions;

export const selectAllSideNavLinks = (state: RootState) =>
  state.persisted.sideNavBar;
export const selectMiddleSideNavLinks = (state: RootState) => {
  const userRole =
    state.persisted.auth.userRole?.toLocaleLowerCase() || "viewer";
  return state.persisted.sideNavBar.filter(
    (link) =>
      link.position === "middle" && link.allowedUserRole?.includes(userRole),
  );
};
export const selectBottomSideNavLinks = (state: RootState) =>
  state.persisted.sideNavBar.filter((link) => link.position === "bottom");

export default sideNavBarSlice.reducer;
