import Layout from "./Layout";
import AppRouter from "../router";
import TokenRefresh from "../components/token-refresh/TokenRefresh";
import MessagePopup from "../components/message-popup/MessagePopup";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";

const MainApp = () => {
  const messagePopup = useAppSelector((state: RootState) => state.messagePopup);

  return (
    <>
      <TokenRefresh />
      <Layout>
        <AppRouter />
      </Layout>
      <MessagePopup
        code={messagePopup.code}
        message={messagePopup.message}
        isOpen={messagePopup.isOpen}
      />
    </>
  );
};

export default MainApp;
