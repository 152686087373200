import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { IoMdLogOut } from "react-icons/io";
import {
  NavContainer,
  DropdownWrapper,
  ProfileDropDown,
  TextWrapper,
  AvatarWrapper,
} from "./TopNavBar.style";
import { useDispatch } from "react-redux";
import { userLogout } from "../../../store/slices/auth/authSlice";
import { resetNavLink } from "../side-nav/sideNavBarSlice";
import NotificationModal from "../../modals/notification-modal/NotificationModal";
import { useAppSelector } from "../../../store/hooks";
import { Image } from "../../image/Image";
import { Typography } from "../../typrography/Typography";
import { useAuth0 } from "@auth0/auth0-react";
import { DefaultThemeFontSize } from "../../../app/Theme";
import { setRouteOption } from "../../../store/slices/routes/routesSlice";
import { NavLink } from "react-router-dom";
import { awsUtils } from "../../../utils/awsUtils";

function TopNavBar() {
  const { user, logout } = useAuth0();
  const dispatch = useDispatch();
  const auth = useAppSelector((state) => state.persisted.auth);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const description =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";
  const handleOpenNotificationModal = () => {
    setIsNotificationModalOpen(true);
  };
  const handleCloseNotificationModal = () => {
    setIsNotificationModalOpen(false);
  };
  const handleLogout = () => {
    logout();
    dispatch(userLogout());
    dispatch(resetNavLink());
    dispatch(setRouteOption(0));
  };

  return (
    <>
      <NavContainer>
        <nav>
          <a href="/dashboard">DASHBOARD STATS</a>
          {/* Replace the link of "Help" side nav bar to wiki as required */}
          {/* <a href="/help">HELP</a> */}
          <a href="https://wiki.swrm.ai/" target="_blank" rel="noreferrer">
            HELP
          </a>
        </nav>
        <ProfileDropDown>
          <DropdownWrapper>
            <AvatarWrapper>
              <Image
                src={awsUtils.getSignedImageURL(
                  auth?.userInfo.MediaURL || user?.picture || "",
                )}
                alt="User Image"
                height="50px"
                width="50px"
              />
              {/* Hide Notification Counter as required */}
              {/* <div>5</div>  */}
            </AvatarWrapper>
            <TextWrapper>
              {(
                auth.userInfo.FirstName !== null &&
                auth.userInfo.LastName !== null
              ) ?
                `${auth.userInfo.FirstName} ${auth.userInfo.LastName}`
              : user?.email!}
              <br />
              {auth.userRole}
            </TextWrapper>
            <div className="arrow-icon">
              <MdKeyboardArrowDown size={"36px"} />
            </div>
          </DropdownWrapper>
          <nav style={{ minWidth: "15vw" }}>
            <NavLink to="/account-settings">
              <CgProfile
                size={DefaultThemeFontSize.lg}
                style={{ marginRight: "5px" }}
              />
              <Typography variant="sm">ACCOUNT SETTINGS</Typography>
            </NavLink>
            {/* Hide Notifications Option as required */}
            {/* <button
              onClick={handleOpenNotificationModal}
              style={{ width: "100%" }}
            >
              <ImNotification size={"30px"} /> <span>NOTIFICATIONS</span>
            </button> */}
            {/* <a href={"/account-settings?tab=billing"} style={{ width: "100%" }}>
              <BsCreditCardFill size={"30px"} style={{ marginRight: "5px" }} />
              <Typography variant="sm"> BILLING</Typography>
            </a> */}
            <a href="/" onClick={handleLogout} style={{ width: "100%" }}>
              <IoMdLogOut
                size={DefaultThemeFontSize.lg}
                style={{ marginRight: "5px" }}
              />
              <Typography variant="sm"> LOG OUT</Typography>
            </a>
          </nav>
        </ProfileDropDown>
      </NavContainer>
      <NotificationModal
        isOpen={isNotificationModalOpen}
        onClose={handleCloseNotificationModal}
        title={"My Notifications"}
        dropdownLabel={"Select Location Type"}
        buttonText={"Submit"}
        description={description}
        editorLabel={"Describe what happened"}
        buttonPadding={"10px"}
      />
    </>
  );
}

export default TopNavBar;
