import axios, { AxiosError, AxiosResponse } from "axios";
import {
  Relay,
  RelayRule,
  RelayValues,
  RelayWithValues,
} from "../../interfaces/device/Relay";
import { OpenErrorNotification } from "../../components/notification/Notification";
import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { ResponseObject } from "../../interfaces/response/Response";
import {
  validateErrorResult,
  validateFetchErrorResult,
  validateFetchSuccessResult,
  validateSuccessResult,
} from "../../utils/axiosUtils";
import { constants } from "perf_hooks";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

const addRelayValue = async (
  relayId: number,
  value: boolean,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .post(`${API_URL}/RelayValue`, { relayId, value })
    .then(async (response: AxiosResponse<ResponseObject<RelayValues>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Relay updated.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const updateRelay = async (
  payload: Relay,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(`${API_URL}/Relay`, payload)
    .then(async (response: AxiosResponse<ResponseObject<Relay>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Relay updated.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const getRelayRuleByRelay = async (
  relayId: number,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .get(`${API_URL}/RelayRules/by?relayId=${relayId}`)
    .then(async (response: AxiosResponse<ResponseObject<RelayRule[]>>) => {
      // Validate Success Result
      const result = response.data;
      await validateFetchSuccessResult(response);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateFetchErrorResult(error, dispatch);
    });

  return response;
};

const addRelayRule = async (
  payload: RelayRule,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .post(`${API_URL}/RelayRules`, payload)
    .then(async (response: AxiosResponse<ResponseObject<RelayRule>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Relay rule added.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const updateRelayRule = async (
  payload: RelayRule,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(`${API_URL}/RelayRules`, payload)
    .then(async (response: AxiosResponse<ResponseObject<RelayRule>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Relay rule updated.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const deleteRelayRule = async (
  ruleId: number,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .delete(`${API_URL}/RelayRules?id=${ruleId}`)
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Relay rule removed.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

export const syncRelays = createAsyncThunk(
  "device/syncRelays",
  async (deviceId: string, { dispatch }) => {
    const response = await axios
      .get(`${API_URL}/Relay/sync?deviceId=${deviceId}`)
      .then(
        async (response: AxiosResponse<ResponseObject<RelayWithValues[]>>) => {
          // Validate Success Result
          const result = response.data;
          const successMessage: string = "Relays synced.";
          await validateSuccessResult(response, dispatch, successMessage);

          return result;
        },
      )
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateErrorResult(error, dispatch);
      });

    return response;
  },
);

const relayService = {
  addRelayValue,
  updateRelay,
  getRelayRuleByRelay,
  addRelayRule,
  updateRelayRule,
  deleteRelayRule,
  syncRelays,
};

export default relayService;
