import React from "react";
import { useAppDispatch } from "../../store/hooks";
import { checkRadio } from "./radioSlice";
import { InputSection, RadioButton } from "./Radio.style";
export interface RadioProps {
  radio: { id: number; value: string; isChecked: boolean; label: String };
  onChange?: Function;
}

const Radio: React.FC<RadioProps> = (props: RadioProps) => {
  const { id, value, isChecked } = props.radio;
  const dispatch = useAppDispatch();

  const handleRadioClick = () => {
    if (isChecked) return;
    dispatch(checkRadio(id));
    props.onChange && props.onChange();
  };

  return (
    <InputSection
      onClick={() => {
        handleRadioClick();
      }}
    >
      <RadioButton
        type="radio"
        name={value}
        checked={isChecked}
        onChange={handleRadioClick}
        id={value}
      />
      <label htmlFor={value}>{value}</label>
    </InputSection>
  );
};

export default Radio;
