import React, { Fragment, useEffect, useState } from "react";
import {
  DropdownSection,
  DropdownWrapper,
  FilterOptionContainer,
  SortBySection,
  StatusAndSortContainer,
  VerticalLine,
} from "./FilterOptions.style";
import { SubTitle } from "../title/Titles.styles";
import DeviceStatus from "../status-radio/StatusRadio";
import SortBy from "../sort-by/SortBy";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import {
  FetchParamsForDevices,
  setDeviceParameters,
} from "../../store/slices/fetchParameters/fetchParametersSlice";
import { filterDevices } from "../../services/device/deviceGet.service";
import { RadioState, resetDeviceStatusRadio } from "../radio-button/radioSlice";
import { resetSortByState } from "../sort-by/sortBySlice";
import {
  DropdownItem,
  getLocationGroupDropdown,
} from "../../store/slices/dropdownOptions/dropdownListSlice";
import {
  CustomDropdown,
  IOption,
} from "../form-field/drop-down/CustomDropdown";
import { Button } from "../button/Button";
import SearchInput from "../input/SearchInput";
import { FilterDropdownsWrapper } from "../dropdown/filter-dropdown/FilterDropdown.style";
import {
  FilterDropdown,
  FilterOption,
} from "../dropdown/filter-dropdown/FilterDropdown";
import { changeDevicesOrder } from "../../store/slices/devices/devicesSlice";

interface FilterOptionContainerProps {
  background?: string;
  style?: React.CSSProperties;
  modalName?: string;
  aiModelId?: number;
}

const DeviceManagerFilterOptions: React.FC<FilterOptionContainerProps> = ({
  background,
  style,
  modalName,
  aiModelId,
}) => {
  const dispatch = useAppDispatch();
  const locationGroupDropdown = useAppSelector(getLocationGroupDropdown);
  const searchParams = useAppSelector(
    (state: RootState) => state.fetchParams.DeviceSearchParams,
  );
  const deployedRadioBtn = useAppSelector((state: RootState) =>
    state.radio.find((x: RadioState) => x.value === "Enabled"),
  );
  const [keyToResetDropdown, setKeyToResetDropdown] = useState<number>(0); // to reset dropdown selection on clear

  useEffect(() => {
    resetFilteredDeviceData();
  }, []);

  const onStatusChange = () => {
    const updatedParams: FetchParamsForDevices = {
      ...searchParams,
      AiModelId: modalName === "viewDeviceModal" ? aiModelId! : 0,
      Deployed: !deployedRadioBtn?.isChecked,
    };
    filterData(updatedParams);
  };
  const onSortByChange = (sortByItem: any) => {
    const updatedParams: FetchParamsForDevices = {
      ...searchParams,
      AiModelId: modalName === "viewDeviceModal" ? aiModelId! : 0,
      OrderByDesc: sortByItem.name === "descending",
    };
    filterData(updatedParams);
  };
  const filterData = (searchParams: FetchParamsForDevices) => {
    dispatch(setDeviceParameters(searchParams));
    dispatch(filterDevices(searchParams));
  };
  const resetFilteredDeviceData = () => {
    // Reset device status radio and sortby states
    dispatch(resetDeviceStatusRadio());
    dispatch(resetSortByState());

    //Reset the device search data
    const initialSearchDeviceParams = {
      ...searchParams,
      DeviceName: "",
      DeviceType: "",
      Deployed: true,
      GroupName: "",
      OrderByDesc: false,
      AiModelId: aiModelId!,
    };
    filterData(initialSearchDeviceParams);
    setKeyToResetDropdown((prev) => prev + 1);
  };
  const handleSearch = (inputValue: string) => {
    const searchDeviceParams = {
      ...searchParams,
      SearchedName: inputValue,
      AiModelId: modalName === "viewDeviceModal" ? aiModelId! : 0,
    };
    filterData(searchDeviceParams);
  };

  const handleDropdownOptionClick = ({ label, value }: FilterOption) => {
    const updatedParams: FetchParamsForDevices = {
      ...searchParams,
      GroupName: label,
      AiModelId: aiModelId ? aiModelId : 0,
    };
    filterData(updatedParams);
  };

  return (
    <>
      <SearchInput
        label={""}
        placeHolder={"Search devices"}
        searchIcon={true}
        background={background}
        handleSearch={handleSearch}
      />
      <FilterOptionContainer background={background} style={style || {}}>
        <DropdownSection>
          <SubTitle>FILTER OPTIONS</SubTitle>
          <FilterDropdownsWrapper>
            <Fragment key={keyToResetDropdown}>
              <FilterDropdown
                title="Group"
                options={locationGroupDropdown?.options}
                onChange={({ label, value }: FilterOption) => {
                  handleDropdownOptionClick({ label, value });
                }}
              />
            </Fragment>
            <Button
              color="primary"
              variant="filled"
              onClick={resetFilteredDeviceData}
            >
              Clear Filters
            </Button>
          </FilterDropdownsWrapper>
        </DropdownSection>
        <StatusAndSortContainer>
          <DropdownSection>
            <DeviceStatus label={"Device Status"} onChange={onStatusChange} />
          </DropdownSection>
          <VerticalLine />
          <SortBySection>
            <SortBy onChange={onSortByChange} />
          </SortBySection>
        </StatusAndSortContainer>
      </FilterOptionContainer>
    </>
  );
};

export default DeviceManagerFilterOptions;
