import React, { Fragment, useEffect, useState } from "react";
import {
  DropdownSection,
  DropdownWrapper,
  FilterOptionContainer,
  SortBySection,
  StatusAndSortContainer,
  VerticalLine,
} from "./FilterOptions.style";
import { SubTitle } from "../title/Titles.styles";
import SortBy from "../sort-by/SortBy";
import ThumbnailSize from "../thumbnail-size/ThumbnailSize";
import { ThumbnailSizeItem } from "../thumbnail-size/thumbnailSizeSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import {
  FetchParamsForCaptures,
  FilterParam,
  resetSearchParams,
  setCaptureParameters,
  updateCaptureParameter,
} from "../../store/slices/fetchParameters/fetchParametersSlice";
import {
  getAllCaptures,
  searchCaptures,
} from "../../services/captures/capture.service";
import {
  resetSortByState,
  setSortBysForCaptures,
} from "../sort-by/sortBySlice";
import { useParams } from "react-router-dom";
import { Button } from "../button/Button";
import { CustomDropdown } from "../form-field/drop-down/CustomDropdown";
import {
  DropdownItem,
  getAIConfidenceDropdown,
  getAIModelsDropdown,
  getDevicesDropdown,
  getLocationsDropdown,
} from "../../store/slices/dropdownOptions/dropdownListSlice";
import {
  FilterDropdown,
  FilterOption,
} from "../dropdown/filter-dropdown/FilterDropdown";

interface CapturesFilterOptionsProps {
  isArchivedData?: boolean;
  thumbnailSizeItems: ThumbnailSizeItem[];
  handleChangeThumbnailViewSize: (id: number) => void;
}

const CapturesFilterOptions: React.FC<CapturesFilterOptionsProps> = ({
  isArchivedData,
  thumbnailSizeItems,
  handleChangeThumbnailViewSize,
}) => {
  const [isUpdatedParams, setIsUpdatedParams] = useState<boolean>(false);
  const { activeTab } = useParams<any>();
  const dispatch = useAppDispatch();
  const locationsDropdown = useAppSelector(getLocationsDropdown);
  const devicesDropdown = useAppSelector(getDevicesDropdown);
  const aIModelsDropdown = useAppSelector(getAIModelsDropdown);
  const aIConfidenceDropdown = useAppSelector(getAIConfidenceDropdown);
  const sortBys = useAppSelector((state: RootState) => state.sortBy);
  const searchParams = useAppSelector(
    (state: RootState) => state.fetchParams.CaptureSearchParams,
  );
  const selectedSortByOrderOption = sortBys.find((item) => {
    return (item.id === 1 || item.id === 2) && item.isActive;
  });
  const selectedSortByTypeOption = sortBys.find((item) => {
    return (item.id === 3 || item.id === 4) && item.isActive;
  });
  const [keyToResetDropdown, setKeyToResetDropdown] = useState<number>(0); // to reset dropdown selection on clear
  const onSortByChange = (sortByItem: any) => {
    const updatedParams: FetchParamsForCaptures = {
      ...searchParams,
      ascending: sortByItem.name === "ascending",
      mediaType: activeTab == "videos" ? "video" : "image",
    };
    dispatch(setCaptureParameters(updatedParams));
    filterData(updatedParams);
  };
  const filterData = (searchParams: FetchParamsForCaptures) => {
    dispatch(searchCaptures(searchParams));
  };

  const resetFilteredCaptureData = () => {
    // Reset sortby state
    dispatch(resetSortByState());
    dispatch(setSortBysForCaptures());
    dispatch(resetSearchParams({ page: "Captures" }));
    //Reset the capture search data
    dispatch(
      getAllCaptures({
        mediaType: activeTab == "videos" ? "video" : "image",
        ascending: false,
        archive: activeTab == "archived" ? true : false,
      }),
    );
    setKeyToResetDropdown((prev) => prev + 1);
  };

  const handleDropdownOptionClick = (
    dropdownId: DropdownItem,
    value: number | string,
  ) => {
    switch (dropdownId) {
      case DropdownItem.Locations:
        const locationParam: FilterParam = {
          paramName: "locationId",
          paramValue: value,
        };
        dispatch(updateCaptureParameter(locationParam));
        break;
      case DropdownItem.Devices:
        const deviceParam: FilterParam = {
          paramName: "deviceId",
          paramValue: value.toString(),
        };
        dispatch(updateCaptureParameter(deviceParam));
        break;
      case DropdownItem.AIModels:
        const modelParam: FilterParam = {
          paramName: "modelId",
          paramValue: value,
        };
        dispatch(updateCaptureParameter(modelParam));
        break;
      case DropdownItem.AIConfidence:
        const aiConfidenceParam: FilterParam = {
          paramName: "highConfidence",
          paramValue: value === 1 ? true : false,
        };
        dispatch(updateCaptureParameter(aiConfidenceParam));
    }

    const ascendingParam: FilterParam = {
      paramName: "ascending",
      paramValue: selectedSortByOrderOption?.name == "ascending" ? true : false,
    };

    const archivedParam: FilterParam = {
      paramName: "archive",
      paramValue: activeTab == "archived" ? true : false,
    };

    const mediaTypeParam: FilterParam = {
      paramName: "mediaType",
      paramValue:
        activeTab == "videos" || selectedSortByTypeOption?.name == "video" ?
          "video"
        : "image",
    };

    dispatch(updateCaptureParameter(ascendingParam));
    dispatch(updateCaptureParameter(archivedParam));
    dispatch(updateCaptureParameter(mediaTypeParam));
    setIsUpdatedParams(true);
  };

  useEffect(() => {
    if (isUpdatedParams) {
      filterData(searchParams);
      setIsUpdatedParams(false);
    }
  }, [searchParams, isUpdatedParams]);

  return (
    <>
      <FilterOptionContainer>
        <DropdownSection>
          <SubTitle style={{ marginBottom: "4px" }}>Filter Options</SubTitle>
          <DropdownWrapper>
            <Fragment key={keyToResetDropdown}>
              {locationsDropdown ?
                <FilterDropdown
                  title="Location"
                  options={locationsDropdown.options}
                  onChange={({ label, value }: FilterOption) => {
                    handleDropdownOptionClick(locationsDropdown.id, value);
                  }}
                />
              : null}
              {devicesDropdown ?
                <FilterDropdown
                  title="Device"
                  options={devicesDropdown.options}
                  onChange={({ label, value }: FilterOption) => {
                    handleDropdownOptionClick(devicesDropdown.id, value);
                  }}
                />
              : null}
              {aIModelsDropdown ?
                <FilterDropdown
                  title="Computer Vision Model"
                  options={aIModelsDropdown.options}
                  onChange={({ label, value }: FilterOption) => {
                    handleDropdownOptionClick(aIModelsDropdown.id, value);
                  }}
                />
              : null}
              {aIConfidenceDropdown ?
                <FilterDropdown
                  title="Confidence"
                  options={aIConfidenceDropdown.options}
                  onChange={({ label, value }: FilterOption) => {
                    handleDropdownOptionClick(aIConfidenceDropdown.id!, value);
                  }}
                />
              : null}
            </Fragment>
            <Button
              color="primary"
              variant="filled"
              onClick={resetFilteredCaptureData}
            >
              Clear Filters
            </Button>
          </DropdownWrapper>
        </DropdownSection>
        <StatusAndSortContainer>
          {isArchivedData && (
            <>
              <SortBySection>
                <SortBy page="captures" isForCaptureTypes />
              </SortBySection>
              <VerticalLine />
            </>
          )}
          <SortBySection>
            <SortBy
              onChange={isArchivedData ? null : onSortByChange}
              page="captures"
            />
          </SortBySection>
          <VerticalLine />
          <SortBySection>
            <ThumbnailSize
              thumbnailSizeItems={thumbnailSizeItems}
              handleChangeThumbnailViewSize={handleChangeThumbnailViewSize}
            />
          </SortBySection>
        </StatusAndSortContainer>
      </FilterOptionContainer>
    </>
  );
};

export default CapturesFilterOptions;
