import CustomModal from "../CustomModal";
import { Typography } from "../../typrography/Typography";
import { ModalProvider } from "styled-react-modal";
import { ScaledButtonWrapper } from "../CustomModal.style";
import * as Yup from "yup";
import { Formik, FormikHelpers, ErrorMessage } from "formik";
import { StyledFormikForm } from "../../tab/ai-model-manager/Upload.style";
import { FormControl } from "../../form-field/form-control/FormControl";
import Input from "../../input/Input";
import { DeviceInfoWrapper } from "../deploy-device-modal/DeployDeviceModal.styled";
import { updateDeviceName } from "../../../store/slices/devices/devicesSlice";
import { useAppDispatch } from "../../../store/hooks";
import LocationService from "../../../services/locations/Location.service";
interface Props {
  isOpenModel: boolean;
  locationId?: number | null;
  locationName?: string | null;
  editLocationAddress?: boolean | null;
  onClose: () => void;
}

interface LocationNameDispatchProps {
  id?: number | undefined;
  Name?: string | undefined;
}

interface LocationAddressDispatchProps {
  id?: number | undefined;
  address?: string | undefined;
}

interface FormValues {
  name: string;
}

const EditLocationInfoModal = ({
  isOpenModel,
  locationId,
  locationName,
  editLocationAddress,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const initialValues = {
    name:
      (
        locationName &&
        !locationName.toLocaleLowerCase().includes("unnamed device")
      ) ?
        locationName
      : "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(
      editLocationAddress ?
        "Location address is required"
      : "Location name is required",
    ),
  });

  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0.725rem", fontWeight: "700" }}
      >
        Edit Location Info
      </Typography>
    );
  };
  const cardFooter = () => {
    return (
      <ScaledButtonWrapper
        buttonMargin="0.5rem"
        style={{ justifyContent: "end" }}
      >
        <button type="submit" form="select-device">
          Save
        </button>
      </ScaledButtonWrapper>
    );
  };
  /**
   * Handle form submission
   */
  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    try {
      if (editLocationAddress) {
        const addressDispatchObject: LocationAddressDispatchProps = {
          id: locationId!,
          address: values.name,
        };

        await dispatch(
          LocationService.patchLocationAddress(addressDispatchObject),
        );
      } else {
        const nameDispatchObject: LocationNameDispatchProps = {
          id: locationId!,
          Name: values.name,
        };

        await dispatch(LocationService.patchLocationName(nameDispatchObject));
      }

      onClose();
    } catch (error) {
      console.error("Error updating enabled status:", error);
    }
    actions.setSubmitting(false);
  };
  return (
    <ModalProvider>
      <CustomModal
        isOpen={isOpenModel}
        onClose={onClose}
        header={cardHeader()}
        footer={cardFooter()}
      >
        <DeviceInfoWrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              values,
              handleChange,
              handleBlur,
              submitForm,
            }) => (
              <StyledFormikForm id={"select-device"}>
                <FormControl>
                  <Input
                    name="name"
                    placeHolder={
                      editLocationAddress ? "Location Address" : "Location Name"
                    }
                    label={
                      editLocationAddress ? "Location Address" : "Location Name"
                    }
                    width="100%"
                    inputWrapperWidth={"100%"}
                    value={values.name} // Now it will recognize values.name
                    onChange={handleChange}
                  />
                </FormControl>
              </StyledFormikForm>
            )}
          </Formik>
        </DeviceInfoWrapper>
      </CustomModal>
    </ModalProvider>
  );
};

export default EditLocationInfoModal;
