// Loader.tsx
import React, { useState } from "react";
import { ScaleLoader } from "react-spinners";
import { Typography } from "../typrography/Typography";
import { LoaderWrapper } from "./Loader.style";
import { DefaultThemeColor } from "../../app/Theme";

type Props = {
  isLoadingScreen?: boolean;
  size?: number;
  loadingText?: string;
  displayLoadingText?: boolean;
};

const color = DefaultThemeColor["primary"];
const defaultLoadingText = "Please wait while data is loading!";

const Loader: React.FC<Props> = ({
  isLoadingScreen,
  size,
  loadingText = defaultLoadingText,
  displayLoadingText = true,
}: Props) => {
  const override = {
    display: "block",
    margin: "0 auto",
  };

  return (
    <LoaderWrapper $isLoadingScreen={!!isLoadingScreen}>
      {!!isLoadingScreen && <div className="overlay" />}
      <div className="loader-container">
        <ScaleLoader
          color={color}
          cssOverride={override}
          width={"20px"}
          height={"120px"}
        />
        {displayLoadingText && !!loadingText && (
          <Typography variant="md" style={{ margin: "1rem" }}>
            {loadingText}
          </Typography>
        )}
      </div>
    </LoaderWrapper>
  );
};

export default Loader;
