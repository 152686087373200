import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosResponse } from "axios";
import { UserUpdatePayload } from "../../interfaces/authentication/User";
import { ResponseObject } from "../../interfaces/response/Response";
import { showPopupMessage } from "../../store/slices/messagePopups/messagePopupSlice";
import { createMessagePopup } from "../../utils/messagePopup";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2/UserCompany";

const updateUserProfile = createAsyncThunk(
  "auth/updateUserProfile",
  async (payload: UserUpdatePayload, { dispatch }) => {
    const response = await axios
      .patch(`${API_URL}/user`, payload)
      .then((response: AxiosResponse<ResponseObject<any>>) => {
        const result = response.data;
        if (result.ErrorCode === 500) throw new Error();

        const message: string =
          result.IsError ? result.ErrorMessage : "User profile updated.";
        dispatch(
          showPopupMessage(createMessagePopup(result.ErrorCode, message)),
        );

        return result;
      })
      .catch(function (error: AxiosError<ResponseObject<any>>) {
        if (error.request) {
          const message: string = "Server cannot be reached.";
          dispatch(showPopupMessage(createMessagePopup(500, message)));
          console.error(error.message);

          return null;
        }
      });

    return response;
  },
);

const accountService = {
  updateUserProfile,
};
export default accountService;
