import React from "react";
import { DeviceStatisticsWrapper } from "../../../device-manager-elements/DeviceManagerMain.style";
import { useAppSelector } from "../../../../store/hooks";
import GaugeChartSection from "../../../../pages/dashboard/GaugeChartSection";

export const CurrentData = () => {
  const { selectedDevice } = useAppSelector((state) => state.devices);
  return (
    <DeviceStatisticsWrapper>
      {selectedDevice.Id ?
        <GaugeChartSection
          selectedLocation={null}
          selectedDevice={selectedDevice?.Id}
        />
      : null}
    </DeviceStatisticsWrapper>
  );
};
