import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import {
  Company,
  CompanyReturnPayload,
} from "../../../services/company/company.service";
import { UserInfo } from "../../../interfaces/authentication/User";
import accountService from "../../../services/account/account.services";
import { integrationService } from "../../../services/integration/integration.service";
import { IntegrationTokens } from "../../../interfaces/integration/IntegrationTokens";
import { ResponseObject } from "../../../interfaces/response/Response";

//Define a type for the slice state
interface AuthState {
  userInfo: UserInfo;
  company: Company;
  userRole: string;
  timeZoneOffset: string;
  hasSignedInPreviously: boolean;
  isSigningUp: boolean;
  tokens: {
    accessToken: string;
    integrationAccessToken: string;
    integrationRefreshToken: string;
  };
}

const initialState: AuthState = {
  userInfo: {
    MediaId: 0,
    Email: "",
    FirstName: "",
    LastName: "",
    MediaURL: "",
    JobTitle: "",
  },
  company: {
    UID: "",
    Name: "",
    CompanyOwnerId: "",
    CompanyEmail: "",
    IntegratedWith: "",
  },
  userRole: "",
  timeZoneOffset: "",
  hasSignedInPreviously: false,
  isSigningUp: false,
  tokens: {
    accessToken: "",
    integrationAccessToken: "",
    integrationRefreshToken: "",
  },
};
// slice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<CompanyReturnPayload>) => {
      state.company = action.payload.Company;
      state.userRole = action.payload.Role;
      state.userInfo = action.payload.UserInfo;
    },
    setTimeZone: (state, action: PayloadAction<AuthState>) => {
      state.timeZoneOffset = action.payload.timeZoneOffset;
    },
    // setTokens: (state, action: PayloadAction<Tokens>) => {
    //   state.tokens = action.payload;
    // },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.tokens.accessToken = action.payload;
    },
    clearIntegrationTokens: (state) => {
      state.tokens.integrationAccessToken = "";
      state.tokens.integrationRefreshToken = "";
    },
    setHasSignedInPreviously: (state, action: PayloadAction<boolean>) => {
      state.hasSignedInPreviously = action.payload;
    },
    setIsSigningUp: (state, action: PayloadAction<boolean>) => {
      state.isSigningUp = action.payload;
    },
    userLogout: (state) => {
      localStorage.removeItem("auth");
      localStorage.removeItem("tokens");
      localStorage.removeItem("company");
      sessionStorage.clear();
      localStorage.clear();
      return initialState;
    },
  },
  extraReducers: (build) => {
    build.addCase(
      integrationService.authenticate.fulfilled,
      (
        state,
        action: PayloadAction<
          ResponseObject<IntegrationTokens> | null | undefined
        >,
      ) => {
        if (action.payload !== null && action.payload !== undefined) {
          const { refresh, access }: IntegrationTokens = action.payload.Result;
          state.tokens.integrationRefreshToken = refresh;
          state.tokens.integrationAccessToken = access;
        }
      },
    );
    build.addCase(
      accountService.updateUserProfile.fulfilled,
      (
        state,
        action: PayloadAction<ResponseObject<any> | null | undefined>,
      ) => {
        if (action.payload !== null && action.payload !== undefined) {
          state.userInfo = action.payload.Result.UserReturnPayload;
          state.company.Name = action.payload.Result.CompanyName;
        }
      },
    );
  },
});

export const {
  setCompany,
  setTimeZone,
  setAccessToken,
  clearIntegrationTokens,
  setHasSignedInPreviously,
  setIsSigningUp,
  userLogout,
} = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.persisted.auth;

export default authSlice.reducer;
