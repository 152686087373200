import axios, { AxiosError, AxiosResponse } from "axios";
import { OpenErrorNotification } from "../../components/notification/Notification";
import { CreateRoutinePayload, Routine } from "../../interfaces/device/Routine";
import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { ResponseObject } from "../../interfaces/response/Response";
import {
  validateErrorResult,
  validateFetchErrorResult,
  validateFetchSuccessResult,
  validateSuccessResult,
} from "../../utils/axiosUtils";
import { Step } from "../../interfaces/device/Step";
import { Relay } from "../../interfaces/device/Relay";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

export const getRoutines = createAsyncThunk(
  "device/getDeviceRoutines",
  async (deviceId: string, { dispatch }) => {
    const response = await axios
      .get(`${API_URL}/Routine?deviceId=${deviceId}`)
      .then(async (response: AxiosResponse<ResponseObject<Routine[]>>) => {
        // Validate Success Result
        const result = response.data;
        await validateFetchSuccessResult(response);

        return result;
      })
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateFetchErrorResult(error, dispatch);
      });

    return response;
  },
);

export const getRoutineSteps = createAsyncThunk(
  "device/getDeviceRoutineSteps",
  async (deviceId: string, { dispatch }) => {
    const response = await axios
      .get(`${API_URL}/Routine/getSteps?deviceId=${deviceId}`)
      .then(async (response: AxiosResponse<ResponseObject<Step[]>>) => {
        // Validate Success Result
        const result = response.data;
        await validateFetchSuccessResult(response);

        return result;
      })
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateFetchErrorResult(error, dispatch);
      });

    return response;
  },
);

export const getRoutineStepRelays = createAsyncThunk(
  "device/getDeviceRoutineStepRelays",
  async (deviceId: string, { dispatch }) => {
    const response = await axios
      .get(`${API_URL}/Routine/getRoutineStepRelays?deviceId=${deviceId}`)
      .then(async (response: AxiosResponse<ResponseObject<number[][]>>) => {
        // Validate Success Result
        const result = response.data;
        await validateFetchSuccessResult(response);

        return result;
      })
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateFetchErrorResult(error, dispatch);
      });

    return response;
  },
);

const createRoutine = async (
  payload: CreateRoutinePayload,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .post(`${API_URL}/Routine`, payload)
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Relay timer added.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const alterRoutine = async (
  payload: CreateRoutinePayload,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .post(`${API_URL}/Routine/alter`, payload)
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Relay timer updated.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const updateRoutineStatus = async (
  deviceId: string,
  status: boolean,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(
      `${API_URL}/Routine/updateStatus?deviceId=${deviceId}&status=${status}`,
    )
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Relay timer status updated.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

export const deleteRoutine = createAsyncThunk(
  "device/deleteDeviceRoutine",
  async (deviceId: string, { dispatch }) => {
    const response = await axios
      .delete(`${API_URL}/Routine/?deviceId=${deviceId}`)
      .then(async (response: AxiosResponse<ResponseObject<string>>) => {
        // Validate Success Result
        const result = response.data;
        const successMessage: string = "Relay timer deleted.";
        await validateSuccessResult(response, dispatch, successMessage);

        return result;
      })
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateErrorResult(error, dispatch);
      });

    return response;
  },
);

const routineService = {
  createRoutine,
  alterRoutine,
  updateRoutineStatus,
};

export default routineService;
