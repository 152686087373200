import { Field, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import CustomModal from "../../../components/modals/CustomModal";
import { useAppDispatch } from "../../../store/hooks";
import { StyledFormikForm } from "../../../components/tab/ai-model-manager/Upload.style";
import {
  BasicModalFormWrapper,
  ScaledButtonWrapper,
} from "../../../components/modals/CustomModal.style";
import Input from "../../../components/input/Input";
import { Typography } from "../../../components/typrography/Typography";
import {
  CompanyCreation,
  companyService,
} from "../../../services/company/company.service";
import { ResponseObject } from "../../../interfaces/response/Response";
import {
  OpenErrorNotification,
  OpenInfoNotification,
} from "../../../components/notification/Notification";
import { setRouteOption } from "../../../store/slices/routes/routesSlice";
import { useNavigate } from "react-router-dom";
import { Option } from "../../../interfaces/dropdownOption/Option";
import {
  CitySelectDropdown,
  CountrySelectDropdown,
  ProvinceStateSelectDropdown,
  SelectProps,
} from "../../../components/select/SelectDropdown";
import PhoneNumberInput from "../../../components/input/PhoneNumberInput";
import { setIsSigningUp } from "../../../store/slices/auth/authSlice";
import FocusError from "../../../components/input/FocusError";

interface CreationModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  buttonText: string;
  companyOwnerId: string;
}

interface FormValues {
  name: string;
  companyEmail: string;
  phoneNumber: string;
  address: string;
  country: Option | null;
  city: Option | null;
  provinceState: Option | null;
  postalCode: string;
}

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name must have at least 2 characters")
    .max(50, "Name must have less than 50 characters"),
  companyEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .min(7, "Invalid phone number"),
  address: Yup.string().required("Address is required"),
  country: Yup.object().required("Country is required"),
  provinceState: Yup.object().required("Province/State is required"),
  city: Yup.object().required("City is required"),
  postalCode: Yup.string()
    .min(5, "Postal code must be 5-6 characters")
    .max(6, "Postal code must be 5-6 characters")
    .required("Postal Code is required"),
});

const initialValues: FormValues = {
  name: "",
  companyEmail: "",
  phoneNumber: "",
  address: "",
  country: null,
  city: null,
  provinceState: null,
  postalCode: "",
};

const CompanyOptionsCreationModal = (props: CreationModalProps) => {
  const { isOpen, onClose, title, buttonText, companyOwnerId } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const provinceStateSelectProps: SelectProps = {
    name: "provinceState",
  };

  const citySelectProps: SelectProps = {
    name: "city",
  };

  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0 20px", fontWeight: "700" }}
      >
        {title}
      </Typography>
    );
  };
  const cardFooter = () => {
    return (
      <ScaledButtonWrapper>
        <button type="submit" form="create-company">
          {buttonText}
        </button>
      </ScaledButtonWrapper>
    );
  };

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    try {
      OpenInfoNotification("Company creation in progress...");

      const company: CompanyCreation = {
        CompanyOwnerId: companyOwnerId,
        Name: values.name.trim(),
        CompanyEmail: values.companyEmail.trim(),
        PhoneNumber: values.phoneNumber.trim(),
        Address: values.address.trim(),
        Country: values.country?.label!,
        City: values.city?.label!,
        ProvinceState: values.provinceState?.label!,
        PostalCode: values.postalCode.trim().toUpperCase(),
      };

      const creationResponse: ResponseObject<string> =
        await companyService.registerCompany(company);

      if (creationResponse.IsError) {
        OpenErrorNotification(creationResponse.ErrorMessage);
        return;
      }

      dispatch(setIsSigningUp(false));
      dispatch(setRouteOption(0));
      navigate("/");
    } catch (error) {
      console.error("Error creating company:", error);
    }

    actions.setSubmitting(false);
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => onClose()}
      closeBtnTopPosition="0.2rem"
      padding="10px 0"
      width={"80vw"}
      height={"80vh"}
      header={cardHeader()}
      footer={cardFooter()}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleBlur }) => (
          <StyledFormikForm id={"create-company"}>
            <BasicModalFormWrapper
              formPadding="0 1.25rem"
              className="company-options-creation-modal"
            >
              <Input
                name="name"
                placeHolder="Insert Name"
                label="Company Name"
                width="100%"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                name="companyEmail"
                placeHolder="Insert Company Email"
                label={"Company Email"}
                width="100%"
                value={values.companyEmail}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Field name="phoneNumber">
                {({ field }: any) => (
                  <PhoneNumberInput
                    name={field.name}
                    label="Company Phone #"
                    onChange={field.onChange}
                  />
                )}
              </Field>
              <Input
                name="address"
                placeHolder="Insert Address"
                label={"Company Address"}
                width="100%"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                name="postalCode"
                placeHolder="Insert Postal Code"
                label={"Company Postal Code"}
                width="100%"
                value={values.postalCode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <CountrySelectDropdown name="country" />
              <ProvinceStateSelectDropdown
                selectedCountryValue={values.country}
                selectProps={provinceStateSelectProps}
              />
              <CitySelectDropdown
                selectedCountryValue={values.country}
                selectedStateValue={values.provinceState}
                selectProps={citySelectProps}
              />
            </BasicModalFormWrapper>
            <FocusError />
          </StyledFormikForm>
        )}
      </Formik>
    </CustomModal>
  );
};

export default CompanyOptionsCreationModal;
