import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface MessagePopupState {
  code?: number | null;
  message: string;
  isOpen: boolean;
}

export interface MessagePopupSetup {
  code?: number | null;
  message: string;
}

const initialState: MessagePopupState = {
  code: null,
  message: "",
  isOpen: false,
};

export const messagePopupSlice = createSlice({
  name: "messagePopup",
  initialState,
  reducers: {
    showMessagePopup(state, action: PayloadAction<MessagePopupSetup>) {
      state.code = action.payload.code;
      state.message = action.payload.message;
      state.isOpen = true;
    },
    closeMessagePopup() {
      return initialState;
    },
  },
});

export const {
  showMessagePopup: showPopupMessage,
  closeMessagePopup: closePopupMessage,
} = messagePopupSlice.actions;
export default messagePopupSlice.reducer;
