import React from "react";
import Login from "../../pages/login/Login";
import DeviceManager from "../../pages/deviceManager/DeviceManager";
import Permissions from "../../pages/permissions/Permissions";
import { Location } from "../../pages/location";
import AiModelManager from "../../pages/aiModelManager/AiModelManager";
import Captures from "../../pages/captures/Captures";
import Integration from "../../pages/integration/Integration";
import Help from "../../pages/help/Help";
import AccountSettings from "../../pages/accountSettings/AccountSettings";
import Dashboard from "../../pages/dashboard/Dashboard";
import RouterLoadingScreen from "../RouterLoadingScreen";
import CompanyOptions from "../../pages/companyOptions/CompanyOptions";
import AcceptInvitation from "../../pages/signup/AcceptInvitation";
import IntegrationLogin from "../../pages/integrationLogin/IntegrationLogin";
import NotFound from "../../pages/fallback/NotFound";

export interface PathComponent {
  path: string;
  component: any;
}

export const setupRoutes: PathComponent[] = [
  { path: "/accept-invitation", component: <AcceptInvitation /> },
  { path: "/integrationLogin", component: <IntegrationLogin /> },
  { path: "/", component: <RouterLoadingScreen /> },
  { path: "*", component: <NotFound /> },
];

export const newUserRoutes: PathComponent[] = [
  { path: "/accept-invitation", component: <AcceptInvitation /> },
  { path: "/getStarted", component: <CompanyOptions /> },
  { path: "/", component: <CompanyOptions /> },
  { path: "*", component: <NotFound /> },
];

export const loggedInRoutes: PathComponent[] = [
  { path: "/", component: <Dashboard /> },
  { path: "*", component: <NotFound /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/login", component: <Login /> },
  { path: "/devices", component: <DeviceManager /> },
  { path: "/devices/:activeTab", component: <DeviceManager /> },
  { path: "/captures", component: <Captures /> },
  { path: "/captures/:activeTab", component: <Captures /> },
  { path: "/permissions", component: <Permissions /> },
  { path: "/locations", component: <Location /> },
  { path: "/locations/:activeTab", component: <Location /> },
  { path: "/cv-model-manager", component: <AiModelManager /> },
  { path: "/cv-model-manager/:activeTab", component: <AiModelManager /> },
  { path: "/integration", component: <Integration /> },
  { path: "/help", component: <Help /> },
  { path: "/account-settings", component: <AccountSettings /> },
  // { path: "/notifications", component: <Notifications /> },
  // { path: "/reportBug", component: <ReportBug /> },
  // { path: "/oldDashboardStats", component: <DashboardStats /> }, /* old dashboard with dummy data and charts*/
];
export const viewerRoutes: PathComponent[] = [
  { path: "/", component: <Dashboard /> },
  { path: "*", component: <NotFound /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/account-settings", component: <AccountSettings /> },
];
export const editorRoutes: PathComponent[] = [
  { path: "/", component: <Dashboard /> },
  { path: "*", component: <NotFound /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/devices", component: <DeviceManager /> },
  { path: "/devices/:activeTab", component: <DeviceManager /> },
  { path: "/captures", component: <Captures /> },
  { path: "/captures/:activeTab", component: <Captures /> },
  { path: "/locations", component: <Location /> },
  { path: "/locations/:activeTab", component: <Location /> },
  { path: "/cv-model-manager", component: <AiModelManager /> },
  { path: "/cv-model-manager/:activeTab", component: <AiModelManager /> },
  { path: "/integration", component: <Integration /> },
  { path: "/help", component: <Help /> },
  { path: "/account-settings", component: <AccountSettings /> },
];
export const notLoggedInRoutes: PathComponent[] = [
  { path: "/", component: <Login /> },
  { path: "*", component: <NotFound /> },
  { path: "/accept-invitation", component: <AcceptInvitation /> },
  { path: "/login", component: <Login /> },
];
