import React, { useState } from "react";
import { VerticalToggleButton } from "../../../components/button/vertical-toggle-button/VerticalToggleButton";
import {
  UserCompany,
  UserRoles,
} from "../../../interfaces/authentication/User";
import { ResponseObject } from "../../../interfaces/response/Response";
import { companyService } from "../../../services/company/company.service";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../components/notification/Notification";
import { useAppDispatch } from "../../../store/hooks";
import { updateUserRole } from "../../../store/slices/users/usersSlice";
interface Props {
  data: UserCompany;
}
export default function Permission({ data }: Props) {
  const dispatch = useAppDispatch();
  const handleChange = async (value: boolean) => {
    // service call
    const payload = {
      ...data,
      RoleId: value ? UserRoles.Editor : UserRoles.Viewer,
    };
    const response: ResponseObject<string> =
      await companyService.updateUserRole(payload);

    if (response.IsError) {
      OpenErrorNotification(response.ErrorMessage);
      return;
    }

    dispatch(updateUserRole(payload));
    OpenSuccessNotification(response.Result);
  };
  return (
    <div>
      <VerticalToggleButton
        key={0}
        id={0}
        isActive={data.RoleId === UserRoles.Editor}
        label={"Allow Editing"}
        onToggle={handleChange}
      />
    </div>
  );
}
