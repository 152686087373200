import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosResponse } from "axios";
import { ResponseObject } from "../../interfaces/response/Response";
import { BoardType } from "../../interfaces/device/BoardTypes";
import {
  validateFetchErrorResult,
  validateFetchSuccessResult,
} from "../../utils/axiosUtils";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

export const getAllBoardTypes = createAsyncThunk(
  "boardTypes/getAllBoardTypes",
  async (_, { dispatch }) => {
    const response = await axios
      .get(`${API_URL}/BoardTypes`)
      .then(async (response: AxiosResponse<ResponseObject<BoardType[]>>) => {
        // Validate Success Result
        const result = response.data;
        await validateFetchSuccessResult(response);

        return result;
      })
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateFetchErrorResult(error, dispatch);
      });
    return response;
  },
);
