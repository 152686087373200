import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export interface LoaderState {
  displayLoader: boolean;
  loaderMessage: string;
}

// initial state for all options starts as an empty array
const initialState: LoaderState = {
  displayLoader: false,
  loaderMessage: "",
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    startAppLoader: (state, action: PayloadAction<string>) => {
      state.displayLoader = true;
      state.loaderMessage = action.payload;
    },
    stopAppLoader: (state) => {
      state.displayLoader = false;
      state.loaderMessage = "";
    },
  },
});
export const { startAppLoader, stopAppLoader } = loaderSlice.actions;
// export slice as a reducer

export const isGlobalLoaderDisplay = (state: RootState) =>
  state.loader.displayLoader;
export const getLoaderMessage = (state: RootState) =>
  state.loader.loaderMessage;

export default loaderSlice.reducer;
