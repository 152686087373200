import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosResponse } from "axios";
import { FetchParamsForDevices } from "../../store/slices/fetchParameters/fetchParametersSlice";
import qs from "qs";
import {
  validateFetchErrorResult,
  validateFetchSuccessResult,
} from "../../utils/axiosUtils";
import { ResponseObject } from "../../interfaces/response/Response";
import { SwrmDevices } from "../../interfaces/device/SwrmDevice";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

export const getAllDevices = createAsyncThunk(
  "device/getAllDevices",
  async (_, { dispatch }) => {
    const response = await axios
      .get(`${API_URL}/Device`)
      .then(async (response: AxiosResponse<ResponseObject<SwrmDevices[]>>) => {
        // Validate Success Result
        const result = response.data;
        await validateFetchSuccessResult(response);

        return result;
      })
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateFetchErrorResult(error, dispatch);
      });

    return response;
  },
);

export const getDevice = createAsyncThunk(
  "device/getDevice",
  async (deviceId: string, { dispatch }) => {
    const response = await axios
      .get(`${API_URL}/Device/getById?id=${deviceId}`)
      .then(async (response: AxiosResponse<ResponseObject<SwrmDevices>>) => {
        // Validate Success Result
        const result = response.data;
        await validateFetchSuccessResult(response);

        return result;
      })
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateFetchErrorResult(error, dispatch);
      });
    return response;
  },
);

export const filterDevices = createAsyncThunk(
  "device/filterDevices",
  async (params: FetchParamsForDevices, { dispatch }) => {
    const response = await axios
      .get(`${API_URL}/Device/filter`, {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      })
      .then(async (response) => {
        // Validate Success Result
        const result = response.data;
        await validateFetchSuccessResult(response);

        return result;
      })
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateFetchErrorResult(error, dispatch);
      });

    return response;
  },
);

const deviceManService = {
  getAllDevices,
  getDevice,
  filterDevices,
};

export default deviceManService;
