import React from "react";
import { PaymentMethodWrapper } from "../../../components/card/payment-card/PaymentCard.style";
import LastPaymentCardInfo from "./LastPaymentCardInfo";
import Payment from "../Payment/Payment";

type Props = {};

const PaymentMethod = (props: Props) => {
  return (
    <PaymentMethodWrapper>
      <LastPaymentCardInfo />
      <Payment />
    </PaymentMethodWrapper>
  );
};

export default PaymentMethod;
