import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store/store";
import MainApp from "./app/MainApp";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { Theme } from "./app/Theme";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { ToastContainer } from "react-toastify";
import { Auth0Provider } from "@auth0/auth0-react";
import "leaflet/dist/leaflet.css";

const container = document.getElementById("root")!;
const root = createRoot(container);
const persistor = persistStore(store);
const domain = process.env.REACT_APP_AUTH0_DOMAIN!;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID!;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE!;

root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: audience,
      scope: "openid profile email",
      prompt: "login",
    }}
    cacheLocation="localstorage"
  >
    <ToastContainer />
    <React.StrictMode>
      <Theme>
        <Provider store={store}>
          {/* The provided loading value will be rendered until persistence is complete at which point children will be rendered. */}
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <MainApp />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </Theme>
    </React.StrictMode>
  </Auth0Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
