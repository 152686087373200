import React from "react";
import { GroupBox, GroupDelete, GroupTitle } from "./Grouping.style";
import { DefaultThemeFontSize } from "../../../../app/Theme";
import { DeviceGroup } from "../../../../interfaces/device/DeviceGroup";
import { deleteDeviceGroup } from "../../../../services/device/deviceGroups.service";
import { OpenSuccessNotification } from "../../../notification/Notification";
import { useAppDispatch } from "../../../../store/hooks";
import { removeDeviceGroup } from "../../../../store/slices/devices/devicesSlice";
import RemoveIcon from "../../../icons/RemoveIcon";
interface Props {
  group: DeviceGroup;
}
export default function DeviceGroupCard({ group }: Props) {
  const dispatch = useAppDispatch();
  const onRemove = async () => {
    const response = await deleteDeviceGroup(group.Id, dispatch);
    if (response !== null && !response.IsError) {
      dispatch(removeDeviceGroup(group.Id));
    }
  };
  return (
    <GroupBox>
      <GroupDelete className="deleteIcon">
        <RemoveIcon
          size={DefaultThemeFontSize.md}
          handleRemoveOnClick={onRemove}
        />
      </GroupDelete>
      <GroupTitle>{group.Group.Name}</GroupTitle>
    </GroupBox>
  );
}
