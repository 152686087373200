import React, { useEffect, useState } from "react";
import { Theme } from "../../app/Theme";
import SideNavBar from "../../components/nav/side-nav/SideNavBar";
import { ContentWrapper } from "../../components/content-wrapper/ContentWrapper";
import TopNavBar from "../../components/nav/top-nav/TopNavBar";
import Titles from "../../components/title/Titles";
import { TabsType } from "../location";
import { Tabs } from "../../components/tab/Tabs";
import ImagesAndVideos from "../../components/tab/captures/ImagesAndVideos";
import Archive from "../../components/tab/captures/Archive";
import { getAllLocations } from "../../services/locations/Location.service";
import { getAllDevices } from "../../services/device/deviceGet.service";
import { getAllAIModels } from "../../services/aiModels/aimodel.service";
import { useAppDispatch } from "../../store/hooks";
import { getAllCaptures } from "../../services/captures/capture.service";
import { useNavigate, useParams } from "react-router-dom";
import { resetCaptureParameter } from "../../store/slices/fetchParameters/fetchParametersSlice";
import { setSortBysForCaptures } from "../../components/sort-by/sortBySlice";

interface CapturesProps {}

const tabs: TabsType = [
  {
    label: "Images",
    index: 0,
    Component: ImagesAndVideos,
    path: "images",
  },
  {
    label: "Videos",
    index: 1,
    Component: ImagesAndVideos,
    path: "videos",
  },
  {
    label: "Archived",
    index: 2,
    Component: Archive,
    path: "archived",
  },
];

const Captures = (props: CapturesProps) => {
  const initialTabIndex = 0;
  const navigate = useNavigate();
  const { activeTab } = useParams<any>();
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState<number>(initialTabIndex);

  // fetch all locations, devices and ai models on initial load of page
  useEffect(() => {
    dispatch(setSortBysForCaptures());
    dispatch(getAllLocations({}));
    dispatch(getAllDevices());
    dispatch(getAllAIModels());
    dispatch(
      getAllCaptures({
        mediaType: getByMediaType(),
        ascending: false,
        archive: activeTab == "archived" ? true : false,
      }),
    );
    dispatch(resetCaptureParameter(getByMediaType()));
  }, [activeTab]);

  useEffect(() => {
    if (!activeTab) {
      navigate(`/captures/${getPathByIndex()}`);
    } else {
      const tab =
        tabs?.find((t) => t.path === activeTab)?.index || initialTabIndex;
      setSelectedTab(tab);
    }
  }, [activeTab]);

  const getPathByIndex = (index = 0) => {
    return tabs[index].path;
  };

  const getByMediaType = () => {
    switch (activeTab) {
      case "images":
        return "image";
      case "videos":
        return "video";
      default:
        return "image";
    }
  };

  const handleTabEvent = (e: number) => {
    setSelectedTab(e);
    // update navigation
    navigate(`/captures/${getPathByIndex(e)}`);
  };

  return (
    <>
      <Theme>
        <SideNavBar />
        <ContentWrapper>
          <TopNavBar />
          <Titles
            heading="Captures"
            subheading="Find and sort photos & videos"
          />
          <Tabs
            selectedTab={selectedTab}
            onClick={handleTabEvent}
            tabs={tabs}
          />
        </ContentWrapper>
      </Theme>
    </>
  );
};

export default Captures;
