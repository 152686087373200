import React, { useEffect, useState } from "react";
import {
  CardIconsWrapper,
  CardImageWrapper,
  ImageCardWrapper,
  StyledBiSolidArchiveOut,
  VerticalLineWrapper,
} from "./CaptureImageVideoCard.style";
import { Image } from "../../image/Image";
import {
  StyledBiSolidArchiveIn,
  StyledIoMdDownload,
  StyledBiInfoCircle,
} from "./CaptureImageVideoCard.style";
import AbsoluteCheckbox from "../../checkbox/AbsoluteCheckbox";
import { ICaptureImageCard } from "../../../interfaces/cardTypes/captures";
import { useAppDispatch } from "../../../store/hooks";
import {
  archiveCapture,
  handleDownloadCapture,
} from "../../../services/captures/capture.service";
import { Tooltip } from "react-tooltip";
import ConfirmationModal from "../../modals/confirmation-modal/ConfirmationModal";
// import { saveAs } from "file-saver";
import axios from "axios";

export interface CaptureImageCardProps {
  id: number;
  image?: string | null;
  isChecked: boolean;
  isArchivedData?: boolean;
  imageCardInfo: ICaptureImageCard;
  handleItemCheckChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOpenThumbnailModal: (id: number) => void;
  handleThumbnailClick: (id: number) => void;
}

const CaptureImageVideoCard: React.FC<CaptureImageCardProps> = ({
  image,
  isChecked,
  isArchivedData,
  id,
  imageCardInfo,
  handleItemCheckChange,
  handleOpenThumbnailModal,
  handleThumbnailClick,
}) => {
  const dispatch = useAppDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState<string>("");

  const handleConfirmationModalOpen = () => {
    const archiveMsg = `Are you sure you'd like to archive the capture that capture ID is ${imageCardInfo.id}?`;
    const unarchiveMsg = `Are you sure you'd like to unarchive the capture that capture ID is ${imageCardInfo.id}?`;

    if (isArchivedData) {
      setConfirmMsg(unarchiveMsg);
    } else {
      setConfirmMsg(archiveMsg);
    }
    setIsConfirmModalOpen(true);
  };

  const handleArchiveCaptureStatus = () => {
    //Archive or Unarchive capture and update capture states
    dispatch(
      archiveCapture({
        Id: imageCardInfo.id,
        ModelId: imageCardInfo.modelId,
        DeviceId: imageCardInfo.deviceId,
        Archive: isArchivedData ? false : true,
      }),
    ).catch((error) => {
      console.error("Error during dispatch archiveCapture:", error);
    });
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  return (
    <>
      <ImageCardWrapper>
        <CardImageWrapper onClick={() => handleThumbnailClick(id)}>
          <AbsoluteCheckbox
            isChecked={isChecked}
            value={id}
            onChange={handleItemCheckChange}
          />
          <Image src={image} alt="Capture Image Card" objectFit="fill"></Image>
        </CardImageWrapper>
        <CardIconsWrapper>
          {isArchivedData ?
            <>
              <StyledBiSolidArchiveOut
                onClick={handleConfirmationModalOpen}
                data-tooltip-id="unarchive-tooltip"
                data-tooltip-content="Unarchive"
                data-tooltip-place="top"
              />
              <Tooltip id="unarchive-tooltip" />
            </>
          : <>
              <StyledBiSolidArchiveIn
                onClick={handleConfirmationModalOpen}
                data-tooltip-id="archive-tooltip"
                data-tooltip-content="Archive"
                data-tooltip-place="top"
              />
              <Tooltip id="archive-tooltip" />
            </>
          }

          <VerticalLineWrapper>|</VerticalLineWrapper>

          <StyledIoMdDownload
            data-tooltip-id="download-tooltip"
            data-tooltip-content="Download"
            onClick={() =>
              handleDownloadCapture(imageCardInfo.mediaPath!, dispatch)
            }
          />
          <Tooltip id="download-tooltip" />

          <VerticalLineWrapper>|</VerticalLineWrapper>

          <StyledBiInfoCircle
            onClick={() => handleOpenThumbnailModal(id)}
            data-tooltip-id="info-tooltip"
            data-tooltip-content="More Information"
          />
          <Tooltip id="info-tooltip" />
        </CardIconsWrapper>
      </ImageCardWrapper>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        onConfirm={handleArchiveCaptureStatus}
        headerTitle={`${isArchivedData ? "Unarchive" : "Archive"} a Capture`}
        confirmMsg={confirmMsg}
        onClose={handleConfirmModalClose}
        modalWidth="30vw"
        minWidth="450px"
      />
    </>
  );
};

export default CaptureImageVideoCard;
