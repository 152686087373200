import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { FilterOption } from "../../../components/dropdown/filter-dropdown/FilterDropdown";
export enum DropdownItem {
  Locations = "Locations",
  Devices = "Devices",
  DeviceBoardTypes = "DeviceBoardTypes",
  AIModels = "AIModels",
  LocationGroups = "LocationGroups",
  LocationTypes = "LocationTypes",
  AIConfidence = "AIConfidence",
  AICreatedDate = "AICreatedDate",
  ActiveDevices = "ActiveDevices",
}
export interface DropdownOption {
  id: number;
  value: string;
}
interface DropdownListState {
  id: DropdownItem;
  title: string;
  name: string;
  options: FilterOption[];
}

const initialState: DropdownListState[] = [
  {
    id: DropdownItem.Devices,
    name: "devices",
    title: "Devices",
    options: [],
  },
  {
    id: DropdownItem.Locations,
    name: "locations",
    title: "Locations",
    options: [],
  },
  {
    id: DropdownItem.DeviceBoardTypes,
    name: "boardTypes",
    title: "Board Type",
    options: [],
  },
  {
    id: DropdownItem.AIModels,
    name: "aiModels",
    title: "AI Models",
    options: [],
  },
  {
    id: DropdownItem.LocationGroups,
    name: "locationGroups",
    title: "Location Groups",
    options: [],
  },
  {
    id: DropdownItem.LocationTypes,
    name: "locationTypes",
    title: "Location Types",
    options: [],
  },
  {
    id: DropdownItem.AIConfidence,
    name: "aiConfidence",
    title: "AI Confidence",
    options: [
      { label: "High", value: 1 },
      { label: "Low", value: 2 },
    ],
  },
  {
    id: DropdownItem.AICreatedDate,
    name: "aiCreatedDate",
    title: "Date Created",
    options: [],
  },
  {
    id: DropdownItem.ActiveDevices,
    name: "activeDevices",
    title: "Active Devices",
    options: [],
  },
];

const dropdownListSlice = createSlice({
  name: "dropdownOptions",
  initialState,
  reducers: {
    setDropdownOptions: (
      state,
      action: PayloadAction<{
        id: DropdownItem;
        options: FilterOption[];
      }>,
    ) => {
      const { id, options } = action.payload;
      const dropdown = state.find((item) => item.id === id);
      if (dropdown) {
        dropdown.options = options;
      }
    },
  },
});

export const { setDropdownOptions } = dropdownListSlice.actions;

export const getLocationTypeDropdown = (state: RootState) => {
  const data = state.dropdownList.find(
    (dropdown) => dropdown.id === DropdownItem.LocationTypes,
  );
  if (data) {
    const options: FilterOption[] = state.locations.locationType.map(
      (item: any) => ({
        label: item.Name,
        value: item.Id,
      }),
    );
    return {
      ...data,
      options: options,
    };
  }
  return data;
};

export const getLocationGroupDropdown = (state: RootState) => {
  const data = state.dropdownList.find(
    (dropdown) => dropdown.id === DropdownItem.LocationGroups,
  );
  if (data) {
    const options: FilterOption[] = state.locations.groupOptions.map(
      (item: any) => ({
        label: item.Name,
        value: item.Id,
      }),
    );
    return {
      ...data,
      options: options,
    };
  }
  return data;
};

export const getLocationsDropdown = (state: RootState) => {
  const data = state.dropdownList.find(
    (dropdown) => dropdown.id === DropdownItem.Locations,
  );
  if (data) {
    const options: FilterOption[] = state.locations.data.map((item: any) => ({
      label: item.Name,
      value: item.Id,
    }));
    return {
      ...data,
      options: options,
    };
  }
  return data;
};

export const getDevicesDropdown = (state: RootState) => {
  const data = state.dropdownList.find(
    (dropdown) => dropdown.id === DropdownItem.Devices,
  );
  if (data) {
    const options: FilterOption[] = state.devices.data.map((item: any) => ({
      label: item.Name,
      value: item.Id,
    }));
    return {
      ...data,
      options: options,
    };
  }
  return data;
};

export const getDeviceBoardTypeDropdown = (state: RootState) => {
  const data = state.dropdownList.find(
    (dropdown) => dropdown.id === DropdownItem.DeviceBoardTypes,
  );
  if (data) {
    const options = state.devices.boardTypes.map((item: any) => ({
      id: item.Id,
      value: item.Name,
    }));
    return {
      ...data,
      options: options,
    };
  }
  return data;
};

export const getAIModelsDropdown = (state: RootState) => {
  const data = state.dropdownList.find(
    (dropdown) => dropdown.id === DropdownItem.AIModels,
  );
  if (data) {
    const options: FilterOption[] = state.aiModels.data.map((item: any) => ({
      label: item.Name,
      value: item.Id,
    }));
    return {
      ...data,
      options: options,
    };
  }
  return data;
};

export const getAIConfidenceDropdown = (state: RootState) => {
  const data = state.dropdownList.find(
    (dropdown) => dropdown.id === DropdownItem.AIConfidence,
  );

  return {
    ...data,
    options: data?.options,
  };
};

export const getAIModelFilterDropdown = (state: RootState) => {
  const requiredIds: DropdownItem[] = [
    DropdownItem.AICreatedDate,
    DropdownItem.ActiveDevices,
  ];
  return state.dropdownList.filter((dropdown) =>
    requiredIds.includes(dropdown.id),
  );
};

export default dropdownListSlice.reducer;
