import CustomModal from "../CustomModal";
import { ContentWrapper } from "../CustomModal.style";
import SearchInput, { inputParamsType } from "../../input/SearchInput";
import DeviceManagerFilterOptions from "../../filter-options/DeviceManagerFilterOptions";
import { ImageCardsWrapper } from "../Modals.style";
import { Typography } from "../../typrography/Typography";
import DeviceCard from "../../../pages/deviceManager/DeviceCard";
import { SwrmDevices } from "../../../interfaces/device/SwrmDevice";

export interface ViewDevicesModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  subTitle?: string;
  inputLabel?: string;
  inputPlaceholder?: string;
  buttonText?: string;
  buttonPadding?: string;
  editorHeight?: number;
  modalWidth?: string;
  modalHeight?: string;
  cardList: SwrmDevices[];
  inputParams: inputParamsType;
  aiModelId: number;
}

const ViewDevicesModal = (props: ViewDevicesModalProps) => {
  const {
    isOpen,
    onClose,
    modalWidth,
    modalHeight,
    cardList,
    inputParams,
    aiModelId,
  } = props;
  const { label, placeHolder, searchIcon, background } = inputParams;

  const handleSearch = (inputValue: string) => {
    console.log("handleSearch clicked");
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      width={modalWidth}
      height={modalHeight}
    >
      <ContentWrapper>
        <DeviceManagerFilterOptions
          aiModelId={aiModelId}
          modalName="viewDeviceModal"
          background={background}
        />

        <ImageCardsWrapper>
          {cardList && cardList.length > 0 ?
            cardList.map((card) => (
              <DeviceCard
                key={card.Id}
                device={card}
                onClick={() => undefined}
              />
            ))
          : <Typography color="red-100" variant="lg">
              No devices found
            </Typography>
          }
        </ImageCardsWrapper>
      </ContentWrapper>
    </CustomModal>
  );
};

export default ViewDevicesModal;
