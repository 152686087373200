import axios, { AxiosError, AxiosResponse } from "axios";
import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { IntegrationTokens } from "../../interfaces/integration/IntegrationTokens";
import authService from "../authentication/auth.service";
import { ResponseObject } from "../../interfaces/response/Response";
import {
  validateErrorResult,
  validateFetchErrorResult,
  validateFetchSuccessResult,
  validateSuccessResult,
} from "../../utils/axiosUtils";
import { clearIntegrationTokens } from "../../store/slices/auth/authSlice";
import { IntegrationCompany } from "../../store/slices/integration/integrationSlice";
import {
  IntegratedLatestSensorData,
  IntegratedWeeklySensor,
} from "../../interfaces/sensorData/IntegratedSensorData";

const SWRM_BE_API_URL =
  process.env.REACT_APP_SWRMBE_URL + "api/v2/IntegrationCompany";
const SOIL_SCOUT_API_URL =
  process.env.REACT_APP_SWRMBE_URL + "api/v2/Integration";

export interface AuthenticatePayload {
  Username: string;
  Password: string;
  IntegratedWith: string;
}

export interface CredentialsPayload {
  Username: string;
  Password: string;
}

export interface UpdateIntegrationStatusPayload {
  companyId: string;
  integrationId: number;
  status: boolean;
}

// SWRM Service Methods
const getIntegrations = createAsyncThunk(
  "integration/getIntegrations",
  async (_, { dispatch }) => {
    let response = await axios
      .get(`${SWRM_BE_API_URL}`)
      .then(
        async (response: AxiosResponse<ResponseObject<IntegrationCompany>>) => {
          // Validate Success Result
          const result = response.data;
          await validateFetchSuccessResult(response);

          return result;
        },
      )
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateFetchErrorResult(error, dispatch);
      });

    return response;
  },
);

const updateIntegrationStatus = createAsyncThunk(
  "integration/updateIntegrationStatus",
  async (payload: UpdateIntegrationStatusPayload, { dispatch }) => {
    const response = await axios
      .patch(
        `${SWRM_BE_API_URL}/updateStatus?companyId=${payload.companyId}
        &integrationId=${payload.integrationId}
        &status=${payload.status}`,
      )
      .then(async (response: AxiosResponse<ResponseObject<string>>) => {
        // Validate Success Result
        const result = response.data;
        const successMessage: string = "Changes saved.";
        await validateSuccessResult(response, dispatch, successMessage);

        return result;
      })
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        await dispatch(integrationService.getIntegrations());
        dispatch(clearIntegrationTokens());
        authService.setIntegrationAuthTokenToHeader("");
        authService.setIntegrationRefreshTokenToHeader("");
        return await validateErrorResult(error, dispatch);
      });

    return response;
  },
);

// Soil Scout Service Methods
const authenticate = createAsyncThunk(
  "integration/authenticate",
  async (payload: AuthenticatePayload, { dispatch }) => {
    const credentialsPayload: CredentialsPayload = {
      Username: payload.Username,
      Password: payload.Password,
    };

    const response = await axios
      .post(`${SOIL_SCOUT_API_URL}/authenticate`, credentialsPayload)
      .then(
        async (response: AxiosResponse<ResponseObject<IntegrationTokens>>) => {
          const result = response.data;
          const setTokens = async () => {
            authService.setIntegrationAuthTokenToHeader(result.Result.access);
            authService.setIntegrationRefreshTokenToHeader(
              result.Result.refresh,
            );
          };
          // Validate Success Result
          const successMessage: string = "Integration sign-in successful.";
          await validateSuccessResult(
            response,
            dispatch,
            successMessage,
            setTokens,
          );
          return result;
        },
      )
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateErrorResult(error, dispatch);
      });

    return response;
  },
);

const refreshSession = createAsyncThunk(
  "integration/refreshSession",
  async (_, { dispatch }) => {
    const response = await axios
      .post(`${SOIL_SCOUT_API_URL}/refreshSession`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(
        async (response: AxiosResponse<ResponseObject<IntegrationTokens>>) => {
          const result = response.data;
          const setTokens = async () => {
            authService.setIntegrationAuthTokenToHeader(result.Result.access);
            authService.setIntegrationRefreshTokenToHeader(
              result.Result.refresh,
            );
          };

          // Validate Success Result
          await validateFetchSuccessResult(response, setTokens);
          return result;
        },
      )
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateErrorResult(error, dispatch);
      });

    return response;
  },
);

const getLatestSensorData = async (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  deviceId?: string | undefined | null,
  locationId?: number | undefined | null,
) => {
  const formattedLocationId = locationId ?? 0;
  const response = await axios
    .get(
      `${SOIL_SCOUT_API_URL}/latestSensorData?deviceId=${deviceId}&locationId=${formattedLocationId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    .then(
      async (
        response: AxiosResponse<ResponseObject<IntegratedLatestSensorData[]>>,
      ) => {
        const result = response.data;

        // Validate Success Result
        await validateFetchSuccessResult(response);
        return result;
      },
    )
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateFetchErrorResult(error, dispatch);
    });

  return response;
};

const getWeeklySensorData = async (
  deviceId: string | undefined | null,
  locationId: number | undefined | null,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const formattedLocationId = locationId ?? 0;
  const response = await axios
    .get(
      `${SOIL_SCOUT_API_URL}/weeklySensorData?deviceId=${deviceId}&locationId=${formattedLocationId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    .then(
      async (
        response: AxiosResponse<ResponseObject<IntegratedWeeklySensor>>,
      ) => {
        const result = response.data;

        // Validate Success Result
        await validateFetchSuccessResult(response);
        return result;
      },
    )
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateFetchErrorResult(error, dispatch);
    });

  return response;
};

export const integrationService = {
  getIntegrations,
  updateIntegrationStatus,
  authenticate,
  refreshSession,
  getLatestSensorData,
  getWeeklySensorData,
};
