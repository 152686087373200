import { FilterOption } from "../../components/dropdown/filter-dropdown/FilterDropdown";
import { Media } from "../device/Media";

export interface User {
  UserId: string;
  FirstName: string;
  LastName: string;
  Email: string;
  MediaId: number;
  Media: Media | null;
}

export interface UserCompany {
  Id: number;
  UserId: string;
  User: User;
  CompanyId: string;
  CreatedById: string;
  CreatedBy: User;
  CreatedAt: Date;
  JobTitle: string;
  UpdatedAt: Date;
  RoleId: UserRoles;
}

export interface UserUpdatePayload {
  UserId: string | undefined;
  MediaId: number;
  FirstName: string;
  LastName: string;
  CompanyName: string;
  JobTitle: string;
}

export interface UserUpdatedReturnPayload {
  UserInfo: UserInfo;
  CompanyName: string;
}

export interface UserInfo {
  MediaId: number;
  Email: string;
  FirstName: string;
  LastName: string;
  MediaURL: string;
  JobTitle: string;
}

export enum UserRoles {
  Root = 1,
  NoOrganization = 2,
  Viewer = 3,
  Editor = 4,
  Owner = 5,
}

export const RoleOptions: FilterOption[] = [
  {
    label: "Viewer",
    value: UserRoles.Viewer,
  },
  {
    label: "Editor",
    value: UserRoles.Editor,
  },
  /* 
    Commented out for the time being.
    Will re-implement when we owner replacement
    feature is needed.
  */
  // {
  //   id: UserRoles.Owner,
  //   value: "Owner",
  // },
];
