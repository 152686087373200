import { AxiosError, AxiosResponse } from "axios";
import { ResponseObject } from "../interfaces/response/Response";
import { showPopupMessage } from "../store/slices/messagePopups/messagePopupSlice";
import { createMessagePopup } from "./messagePopup";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

/* Validation methods for specific GET and Silent API calls */
export const validateFetchSuccessResult = async (
  response: AxiosResponse<ResponseObject<any>>,
  callBackSuccessMethod?: () => void,
) => {
  const result = response.data;
  if (result.IsError) throw new Error(result.ErrorMessage);
  if (callBackSuccessMethod) callBackSuccessMethod();
};

export const validateFetchErrorResult = async (
  error: AxiosError<ResponseObject<string>>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  callBackErrorMethod?: () => void,
) => {
  if (!error.response) {
    // Check for no server response
    const message: string = "Server unreachable. Please try again later.";
    dispatch(showPopupMessage(createMessagePopup(500, message)));
  } else {
    // Check for server response
    const errorResult = error.response.data;
    if (errorResult.ErrorCode === 500) {
      dispatch(
        showPopupMessage(
          createMessagePopup(
            500,
            "An unexpected error occurred. Please try again later.",
          ),
        ),
      );
    } else {
      if (callBackErrorMethod) callBackErrorMethod();
    }
  }

  console.error(error);
  return null;
};

/* Validation methods for GET, PUT, PATCH, POST, DELETE API calls */
export const validateSuccessResult = async (
  response: AxiosResponse<ResponseObject<any>>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  successMessage: string,
  callBackSuccessMethod?: () => void,
) => {
  const result = response.data;
  if (result.IsError) throw new Error(result.ErrorMessage);
  if (callBackSuccessMethod) callBackSuccessMethod();

  dispatch(
    showPopupMessage(createMessagePopup(result.ErrorCode, successMessage)),
  );
};

export const validateErrorResult = async (
  error: AxiosError<ResponseObject<string>>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  if (!error.response) {
    // Check for no server response
    const message: string = "Server unreachable. Please try again later.";
    dispatch(showPopupMessage(createMessagePopup(500, message)));
  } else {
    // Check for server response
    const errorResult = error.response.data;
    if (errorResult.ErrorCode === 500) {
      dispatch(
        showPopupMessage(
          createMessagePopup(
            500,
            "An unexpected error occurred. Please try again later.",
          ),
        ),
      );
    } else {
      dispatch(
        showPopupMessage(
          createMessagePopup(errorResult.ErrorCode, errorResult.ErrorMessage),
        ),
      );
    }
  }

  console.error(error);
  return null;
};

/* Validation methods for Download APIs that returns an Array Buffer */
export const validateDownloadSuccessResult = async (
  response: AxiosResponse<ArrayBuffer>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  callBackSuccessMethod?: () => void,
) => {
  if (response.status !== 200) throw new Error(response.statusText);
  if (callBackSuccessMethod) callBackSuccessMethod();

  dispatch(
    showPopupMessage(
      createMessagePopup(response.status, "Downloaded successfully."),
    ),
  );
};

export const validateDownloadErrorResult = async (
  error: AxiosError<ArrayBuffer>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  if (!error.response) {
    // Check for no server response
    const message: string = "Server unreachable. Please try again later.";
    dispatch(showPopupMessage(createMessagePopup(500, message)));
  } else {
    // Check for server response
    if (error.response.status === 404) {
      dispatch(
        showPopupMessage(
          createMessagePopup(
            error.response.status,
            "We could not find the item/s you are trying to download.",
          ),
        ),
      );
    } else {
      dispatch(
        showPopupMessage(
          createMessagePopup(
            500,
            "An unexpected error occurred. Please try again later.",
          ),
        ),
      );
    }
  }

  console.error(error);
  return null;
};

/* Validation method strictly for user authentication */
export const validateAuthSuccessResult = async (
  response: AxiosResponse<ResponseObject<string>>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  callBackSuccessMethod?: () => void,
) => {
  if (response.status !== 200) throw new Error(response.statusText);
  if (callBackSuccessMethod) callBackSuccessMethod();

  dispatch(
    showPopupMessage(
      createMessagePopup(response.status, "Sign-in successful."),
    ),
  );
};

export const validateAuthErrorResult = async (
  error: AxiosError<ResponseObject<string>>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  console.error(error);

  if (!error.response) {
    // Check for no server response
    const message: string = "Server unreachable. Please try again later.";
    dispatch(showPopupMessage(createMessagePopup(500, message)));
  } else {
    // Check for server response
    const errorResult = error.response.data;
    if (errorResult.ErrorCode === 500) {
      dispatch(
        showPopupMessage(
          createMessagePopup(
            500,
            "An unexpected error occurred. Please try again later.",
          ),
        ),
      );
    } else {
      if (errorResult.ErrorCode == 404) {
        dispatch(
          showPopupMessage(createMessagePopup(200, "Sign-up successful.")),
        );
        return errorResult;
      } else {
        dispatch(
          showPopupMessage(
            createMessagePopup(errorResult.ErrorCode, errorResult.ErrorMessage),
          ),
        );
      }
    }
  }

  return null;
};
