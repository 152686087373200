import { useEffect } from "react";
import AiModelManagerTable from "../../tables/AiModelManagerTable";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import {
  DropdownItem,
  setDropdownOptions,
} from "../../../store/slices/dropdownOptions/dropdownListSlice";
import { buildDropdownOptionsObj } from "../../../utils/dropdownUtils";
import { Device } from "../../../interfaces/device/Device";
import { sortStringDates } from "../../../utils/dateUtils";
import AIModelFilterOptions from "../../filter-options/AIModelFilterOptions";
import AIModelService from "../../../services/aiModels/aimodel.service";
import { resetAllFiles } from "../../../store/slices/ai-models/aiModelSlice";
import { SwrmDevices } from "../../../interfaces/device/SwrmDevice";
import { switchSortBy } from "../../sort-by/sortBySlice";

interface EditProps {}

const Edit = (props: EditProps) => {
  const dispatch = useAppDispatch();
  const devices = useAppSelector((state: RootState) => state.devices);
  const aiModels = useAppSelector((state: RootState) => state.aiModels);
  const AIModelSearchParams = useAppSelector(
    (state: RootState) => state.fetchParams.AIModelSearchParams,
  );

  useEffect(() => {
    // Populate AI Model Manager Dropdowns
    const activeDevices: SwrmDevices[] = devices.data.filter((device) => {
      return device.Deployed;
    });
    const creationDates: string[] = aiModels.combinedData.map((model) => {
      return model.dateCreated;
    });
    const uniqueCreationDates: string[] = creationDates.filter(
      (date, index, self) => {
        return self.indexOf(date) === index;
      },
    );

    const activeDevicesOptions = buildDropdownOptionsObj(activeDevices);
    const creationDatesOptions = buildDropdownOptionsObj(
      sortStringDates(uniqueCreationDates),
      true,
    );

    dispatch(
      setDropdownOptions({
        id: DropdownItem.AICreatedDate,
        options: creationDatesOptions,
      }),
    );
    dispatch(
      setDropdownOptions({
        id: DropdownItem.ActiveDevices,
        options: activeDevicesOptions,
      }),
    );
  }, []);

  // Need to implemented when working on the filter options for the page
  const resetFilteredData = () => {};

  useEffect(() => {
    if (
      AIModelSearchParams.dateCreated !== "" ||
      AIModelSearchParams.activeDevice !== "" ||
      AIModelSearchParams.orderByDesc !== null
    ) {
      dispatch(
        AIModelService.filterAIModels({
          dateCreated: AIModelSearchParams.dateCreated,
          deviceId: AIModelSearchParams.activeDevice,
          orderByDesc: AIModelSearchParams.orderByDesc,
        }),
      );
    }
  }, [AIModelSearchParams, dispatch]);

  useEffect(() => {
    if (aiModels.aiModelFile !== null || aiModels.thumbnailFile !== null) {
      dispatch(resetAllFiles());
    }

    /*
      Switching Sort By because AI Models are being ordered Alphabetically
    */
    dispatch(switchSortBy(1));
  }, []);

  return (
    <>
      <AIModelFilterOptions />
      <AiModelManagerTable />
    </>
  );
};

export default Edit;
