import React, { useEffect, useState } from "react";
import TableButton from "./table-button/TableButton";
import Table from "./Table";
import RetrainModal from "../modals/retrain-modal/RetrainModal";
import ViewDevicesModal from "../modals/view-devices-modal/ViewDevicesModal";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  AiModelManagerData,
  resetAllCheckedStatus,
  resetAllFiles,
  selectCheckedAiModelIds,
  setCheckedStatus,
} from "../../store/slices/ai-models/aiModelSlice";
import Loader from "../loader/Loader";
import AIModelService, {
  getAllAIModels,
} from "../../services/aiModels/aimodel.service";
import { ResponseObject } from "../../interfaces/response/Response";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../notification/Notification";
import { filterDevices } from "../../services/device/deviceGet.service";
import ConfirmationModal from "../modals/confirmation-modal/ConfirmationModal";
import { buildDropdownOptionsObj } from "../../utils/dropdownUtils";
import { sortStringDates } from "../../utils/dateUtils";
import {
  DropdownItem,
  setDropdownOptions,
} from "../../store/slices/dropdownOptions/dropdownListSlice";
import {
  FetchParamsForDevices,
  setDeviceParameters,
} from "../../store/slices/fetchParameters/fetchParametersSlice";

interface AiModelManagerTableProps {}

const columns = ["Model", "Actions", "Active Devices", "Date Created"];

const AiModelManagerTable = (props: AiModelManagerTableProps) => {
  const dispatch = useAppDispatch();
  const aiModels = useAppSelector((state: RootState) => state.aiModels);
  const selectedAiModels = useAppSelector(selectCheckedAiModelIds);
  const aiModelFetchParams = useAppSelector(
    (state: RootState) => state.fetchParams,
  );
  const searchParams = useAppSelector(
    (state: RootState) => state.fetchParams.DeviceSearchParams,
  );
  const [data, setData] = useState(aiModels.combinedData);
  const [checkedAll, setCheckedAll] = useState(false);

  //setup pagination properties
  const [filterQuery, setFilterQuery] = useState("");
  const [downloadStatus, setDownloadStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filteredData, setFilteredData] = useState(data);
  const device = useSelector((state: RootState) => state.devices);

  useEffect(() => {
    if (aiModels.getDataStatus === "succeeded") {
      setData(aiModels.combinedData as AiModelManagerData[]);
    }
  }, [aiModels]);

  useEffect(() => {
    const filteredByDateModels = aiModels.combinedData.filter((model) => {
      if (
        model.dateCreated.includes(
          aiModelFetchParams.AIModelSearchParams.dateCreated,
        )
      ) {
        return model;
      }
    });

    setData(filteredByDateModels as AiModelManagerData[]);
  }, [
    aiModelFetchParams.AIModelSearchParams.dateCreated,
    aiModelFetchParams.AIModelSearchParams.activeDevice,
  ]);

  const getCurrentPageData = (
    currentPage: number,
    pageSize: number,
    data: AiModelManagerData[],
  ) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentPageData = data.slice(startIndex, endIndex);
    return currentPageData;
  };
  const pageData = getCurrentPageData(currentPage, pageSize, data);
  const startItemId = (currentPage - 1) * pageSize + 1;
  const endItemId = Math.min(currentPage * pageSize, data.length);
  const lastPage = Math.ceil(filteredData.length / pageSize);

  const [paginatedData, setPaginatedData] = useState(pageData);

  useEffect(() => {
    updateCheckAllCheckbox();
  }, [paginatedData]);

  // setup the modals open state
  const [isRetrainModalOpen, setIsRetrainModalOpen] = useState(false);
  const [isViewDevicesModalOpen, setIsViewDevicesModalOpen] = useState(false);
  const [isDuplicateDeviceModalOpen, setIsDuplicateDeviceModalOpen] =
    useState(false);
  const [isDeleteAIModelOpen, setIsDeleteAIModelOpen] = useState(false);
  const [isArchiveAIModelOpen, setIsArchiveAIModelOpen] = useState(false);
  const [aIModelIdForAction, setAIModelIdForAction] = useState(0); // for duplicate and other item

  // setup selected AI model to view it's devices
  const [selectedAIModelId, setSelectedAIModelId] = useState(0);

  const rebindData = () => {
    dispatch(getAllAIModels());
    handlePageChange(currentPage);
  };

  const handleOpenRetrainModal = () => {
    setIsRetrainModalOpen(true);
  };

  const handleCloseRetrainModal = () => {
    setIsRetrainModalOpen(false);
  };

  const handleOpenViewDevicesModal = (aiModelId: number) => {
    setSelectedAIModelId(aiModelId);
    dispatch(setCheckedStatus({ aiModelId: aiModelId, checked: true }));
    setIsViewDevicesModalOpen(true);
    dispatch(
      filterDevices({
        SearchedName: "",
        DeviceName: "",
        DeviceType: "",
        Deployed: null,
        GroupName: "",
        OrderByDesc: false,
        AiModelId: aiModelId,
      }),
    );
  };

  const filterData = (searchParams: FetchParamsForDevices) => {
    dispatch(setDeviceParameters(searchParams));
    dispatch(filterDevices(searchParams));
  };

  const handleCloseViewDevicesModal = () => {
    dispatch(
      setCheckedStatus({ aiModelId: selectedAiModels[0], checked: false }),
    );

    // Reset queried data
    const searchDeviceParams = {
      ...searchParams,
      SearchedName: "",
    };

    filterData(searchDeviceParams);
    setIsViewDevicesModalOpen(false);
  };
  // duplicate device model start
  const handleOpenDuplicateDeviceModal = (id: number) => {
    setIsDuplicateDeviceModalOpen(true);
    setAIModelIdForAction(id);
  };

  const handleCloseDuplicateDeviceModal = () => {
    setIsDuplicateDeviceModalOpen(false);
  };
  const handleDuplicateDevice = async () => {
    const response: ResponseObject<string> | null =
      await AIModelService.duplicateAIModel(aIModelIdForAction, dispatch);
    handleCloseDuplicateDeviceModal();

    if (response !== null && !response.IsError) rebindData();
  };
  // duplicate device model end

  const handleCloseDeleteAIModelModal = () => {
    setIsDeleteAIModelOpen(false);
  };
  const handleCloseArchiveAIModelModal = () => {
    setIsArchiveAIModelOpen(false);
  };

  const handleDeleteAIModelModal = (id: number) => {
    setIsDeleteAIModelOpen(true);
    setAIModelIdForAction(id);
  };
  const handleArchiveAIModalModal = (id: number) => {
    setIsArchiveAIModelOpen(true);
    setAIModelIdForAction(id);
  };
  const handleDeleteAIModel = async () => {
    const response: ResponseObject<string> | null =
      await AIModelService.deleteAIModel(aIModelIdForAction, dispatch);
    handleCloseDeleteAIModelModal();
    if (response !== null && !response.IsError) rebindData();
  };
  const handleArchiveAIModel = async () => {
    const response: ResponseObject<string> | null =
      await AIModelService.archiveAIModel(aIModelIdForAction, dispatch);
    handleCloseArchiveAIModelModal();
    if (response !== null && !response.IsError) rebindData();
  };
  const inputParams = {
    label: "",
    placeHolder: "Search devices",
    searchIcon: true,
    background: "#020A01",
  };

  const handleFilterQueryChange = (event: React.MouseEvent<HTMLLIElement>) => {
    const activeLiElement = event.target as HTMLLIElement;
    const activeFilter = activeLiElement.textContent;
    if (activeFilter !== null) {
      setFilterQuery(activeFilter);
      const filteredData = data.filter((item) =>
        item.model[2].toLowerCase().includes(activeFilter.toLowerCase()),
      );
      setFilteredData(filteredData);
      setCurrentPage(1);
    }
  };

  const handlePageChange = (pageNum: number) => {
    setCurrentPage(pageNum);
    setCheckedAll(false);
    const updatedData = data.map((item) => ({ ...item, checked: false }));
    setData(updatedData);
    // Reset all checked status for combinedAiModels state
    dispatch(resetAllCheckedStatus());
  };

  const handleCheckAllChange = () => {
    const selectedItems = pageData.map((selectItem) => selectItem.id);
    const updatedData = data.map((item) =>
      selectedItems.includes(item.id) ?
        { ...item, checked: !checkedAll }
      : item,
    );
    setData(updatedData);
    setCheckedAll(!pageData.some((item) => item.checked));

    // Update all checked status for combinedAiModels state
    pageData.map((aiModel) =>
      dispatch(
        setCheckedStatus({
          aiModelId: aiModel.id,
          checked: !pageData.some((item) => item.checked),
        }),
      ),
    );
  };

  const handleItemCheckChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const id = parseInt(event.target.value);
    const selectedItem = data.find((item) => item.id === id);
    const selectedCheckedStatus = selectedItem?.checked!;

    const updatedData = data.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item,
    );
    setData(updatedData);

    dispatch(
      setCheckedStatus({ aiModelId: id, checked: !selectedCheckedStatus }),
    );

    const updatedPaginatedData = getCurrentPageData(
      currentPage,
      pageSize,
      updatedData,
    );
    setPaginatedData(updatedPaginatedData);

    setCheckedAll(!updatedPaginatedData.some((item) => item.checked));
  };

  const updateCheckAllCheckbox = () => {
    const allChecked = paginatedData.some((item) => item.checked);
    setCheckedAll(allChecked);
  };

  const handleItemDownload = async (aiModelId: number) => {
    setDownloadStatus("pending");
    await AIModelService.downloadAIModel(aiModelId, dispatch);
    setDownloadStatus("");
  };

  const getUniqueFilterDates = () => {
    const creationDates: string[] = aiModels.combinedData.map((model) => {
      return model.dateCreated;
    });

    const uniqueCreationDates: string[] = creationDates.filter(
      (date, index, self) => {
        return self.indexOf(date) === index;
      },
    );

    const creationDatesOptions = buildDropdownOptionsObj(
      sortStringDates(uniqueCreationDates),
      true,
    );

    dispatch(
      setDropdownOptions({
        id: DropdownItem.AICreatedDate,
        options: creationDatesOptions,
      }),
    );
  };

  useEffect(() => {
    if (!aiModels.isUsingFilters) {
      getUniqueFilterDates();
    }
  }, [aiModels.data, aiModels.isUsingFilters]);

  return (
    <>
      {aiModels.getDataStatus === "loading" && <Loader isLoadingScreen />}
      {downloadStatus === "pending" && (
        <Loader
          loadingText="Downloading AI Model, please wait"
          isLoadingScreen
        />
      )}
      <TableButton
        isChecked={checkedAll}
        dataLength={data.length}
        handleCheckAllChange={handleCheckAllChange}
        handlePageChange={handlePageChange}
        handleRebindData={rebindData}
        currentPage={currentPage}
        lastPage={lastPage}
        startItemId={startItemId}
        endItemId={endItemId}
        page="AiModelManager"
      />
      <Table
        columns={columns}
        data={pageData}
        rebindData={rebindData}
        handleItemCheckChange={handleItemCheckChange}
        handleItemDownload={handleItemDownload}
        handleViewDevices={handleOpenViewDevicesModal}
        handleRetrainOnClick={() => {}} // handleOpenRetrainModal}
        handleDuplicateOnClick={handleOpenDuplicateDeviceModal}
        handleDeleteOnClick={handleDeleteAIModelModal}
        handleArchiveOnClick={handleArchiveAIModalModal}
      />
      <ConfirmationModal
        isOpen={isDuplicateDeviceModalOpen}
        onClose={handleCloseDuplicateDeviceModal}
        setIsOpen={setIsDuplicateDeviceModalOpen}
        onConfirm={handleDuplicateDevice}
        headerTitle={"Duplicate Device"}
        confirmMsg={
          "Are you sure that you would like to duplicate the selected models?"
        }
        OkBtnText="Yes"
        CancelBtnText="No"
      />
      <ConfirmationModal
        isOpen={isDeleteAIModelOpen}
        onClose={handleCloseDeleteAIModelModal}
        setIsOpen={setIsDeleteAIModelOpen}
        onConfirm={handleDeleteAIModel}
        headerTitle={"Delete AI Model"}
        confirmMsg={
          "Are you sure that you would like to delete the selected model?"
        }
        OkBtnText="Yes"
        CancelBtnText="No"
      />
      <ConfirmationModal
        isOpen={isArchiveAIModelOpen}
        onClose={handleCloseArchiveAIModelModal}
        setIsOpen={setIsArchiveAIModelOpen}
        onConfirm={handleArchiveAIModel}
        headerTitle={"Archive AI Model"}
        confirmMsg={
          "Are you sure that you would like to archive the selected model?"
        }
        OkBtnText="Yes"
        CancelBtnText="No"
      />
      <RetrainModal
        isOpen={isRetrainModalOpen}
        onClose={handleCloseRetrainModal}
        title={"Retrain Companion"}
        subTitle={
          "Drag and drop the adjusted AI files, or paste a URL link in the field below!"
        }
        inputLabel={"Paste URL"}
        buttonText={"Retrain on New Dataset"}
        inputPlaceholder={"URL"}
        buttonPadding="20px"
      />
      <ViewDevicesModal
        isOpen={isViewDevicesModalOpen}
        onClose={handleCloseViewDevicesModal}
        buttonPadding="20px"
        modalWidth="85vw"
        modalHeight="80vh"
        cardList={device?.allDevicesForModal || []}
        inputParams={inputParams}
        aiModelId={selectedAIModelId}
      />
    </>
  );
};

export default AiModelManagerTable;
