import {
  GraphTimelineButtonGroup,
  GraphTimelineButtonLabel,
  HiddenRadioButton,
} from "./GraphTimeline.style";

interface GraphTimelineProps {
  isIntegrationData: boolean;
  selectedTimeline: string;
  handleOnClick: (value: string) => void;
}

const GraphTimeline = ({
  isIntegrationData,
  selectedTimeline,
  handleOnClick,
}: GraphTimelineProps) => {
  return (
    <GraphTimelineButtonGroup>
      {["daily", "weekly", "monthly", "yearly"].map((value: string) => (
        <GraphTimelineButtonLabel
          key={value}
          isIntegrationData={isIntegrationData}
          selected={selectedTimeline === value}
          onClick={() => !isIntegrationData && handleOnClick(value)}
        >
          <HiddenRadioButton
            type="radio"
            name="graphView"
            value={value}
            checked={selectedTimeline === value}
            onChange={() => !isIntegrationData && handleOnClick(value)}
          />
          {value.charAt(0).toUpperCase() + value.slice(1)}
        </GraphTimelineButtonLabel>
      ))}
    </GraphTimelineButtonGroup>
  );
};

export default GraphTimeline;
