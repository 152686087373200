import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import {
  StyledTfiLayoutGrid2Alt,
  StyledTfiLayoutGrid3Alt,
  StyledTfiLayoutGrid4Alt,
  StyledFaList,
} from "./ThumbnailSize.style";

export interface ThumbnailSizeItem {
  id: number;
  name: string;
  icon: React.FunctionComponent<any>;
  pageSize: number;
  isActive: boolean;
}

const initialState: ThumbnailSizeItem[] = [
  {
    id: 1,
    name: "large",
    icon: StyledTfiLayoutGrid2Alt,
    pageSize: 15,
    isActive: true,
  },
  {
    id: 2,
    name: "medium",
    icon: StyledTfiLayoutGrid3Alt,
    pageSize: 24,
    isActive: false,
  },
  {
    id: 3,
    name: "small",
    icon: StyledTfiLayoutGrid4Alt,
    pageSize: 48,
    isActive: false,
  },
  { id: 4, name: "list", icon: StyledFaList, pageSize: 10, isActive: false },
];

export const thumbnailSizeSlice = createSlice({
  name: "thumbnailSize",
  initialState,
  reducers: {
    toggleThumbnailSize: (state, action: PayloadAction<number>) => {
      const activeThumbnailSizeId = action.payload;
      state.map((Item) => {
        if (Item.id === activeThumbnailSizeId) {
          return (Item.isActive = true);
        } else {
          return (Item.isActive = false);
        }
      });
    },
  },
});

export const selectAllThumbnailSizeItems = (state: RootState) =>
  state.thumbnailSize;
export const selectActiveThumbnailSizeItem = (state: RootState) =>
  state.thumbnailSize.filter((item) => item.isActive);

export const { toggleThumbnailSize } = thumbnailSizeSlice.actions;

export default thumbnailSizeSlice.reducer;
