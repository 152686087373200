import styled from "styled-components";
import { DefaultThemeColor, DefaultThemeBorder } from "../../app/Theme";

export const GraphTimelineButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin: 1.813rem 1.813rem 0;
  padding: 0 0.2rem 0;
`;

export const GraphTimelineButtonLabel = styled.label<{
  selected: boolean;
  isIntegrationData: boolean;
}>`
  padding: 10px 20px;
  border: ${DefaultThemeBorder["border-md-yellow"]};
  border-radius: 5px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
  user-select: none;
  ${({ selected, isIntegrationData }) =>
    selected ?
      `
    background-color: ${DefaultThemeColor.primary};
    color: ${DefaultThemeColor["black-100"]};
  `
    : `
    ${
      isIntegrationData ?
        `
        cursor: not-allowed;
        opacity: 0.5;
      `
      : `
        background-color: ${DefaultThemeColor["black-100"]};
        color: #FFF;
        &:hover {
          background-color: ${DefaultThemeColor.primary};
          color: ${DefaultThemeColor["black-100"]};
        } 
      `
    }
  `}
`;

export const HiddenRadioButton = styled.input`
  display: none;
`;
