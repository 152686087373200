import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAllDevices } from "../../../services/device/deviceGet.service";
import { useAppDispatch } from "../../../store/hooks";
import { DeviceTitle } from "../manage/DeviceCardList.style";
import Checkbox from "../../../components/checkbox/Checkbox";
import ConfirmationModal from "../../../components/modals/confirmation-modal/ConfirmationModal";
import devicePatchService from "../../../services/device/devicePatch.service";
import { useNavigate } from "react-router-dom";
import DeviceCard from "../DeviceCard";
import { SwrmDevices } from "../../../interfaces/device/SwrmDevice";
import { ImageCardWrapper } from "../../../components/image-card/ImageCard.style";
import { ResponseObject } from "../../../interfaces/response/Response";

export default function UndeployDevices() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const carouselRef = useRef<HTMLDivElement>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState<string>("");
  const [deviceIdToUndeploy, setDeviceIdToUndeploy] = useState<string>("");
  const [isBulkDeployChecked, setIsBulkDeployChecked] =
    useState<boolean>(false);

  const deployedDevices = useSelector((state: any) =>
    state.devices.data.filter((device: SwrmDevices) => device.Deployed),
  );
  useEffect(() => {
    dispatch(getAllDevices()).then(() => {
      // Logging the devices, for development environment only
      if (process.env.NODE_ENV === "development") {
        console.log(deployedDevices);
      }
    });
  }, [dispatch]);
  const handleBulkDeployChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsBulkDeployChecked(e.target.checked);
  };

  const handleImageCardClick = (device: SwrmDevices) => {
    setDeviceIdToUndeploy(device.Id || "");
    const msg = `Are you sure you'd like to undeploy the device (${device.Name})?`;
    setConfirmMsg(msg);
    setIsConfirmModalOpen(true);
  };

  const handleUndeployDevice = async () => {
    //Undeploy device and update device data in state
    const undeployDeviceResponse = await devicePatchService.patchDeviceUndeploy(
      deviceIdToUndeploy,
      dispatch,
    );

    if (undeployDeviceResponse !== null) {
      handleConfirmModalClose();
      navigate(`/devices`);
    }
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
    setDeviceIdToUndeploy("");
  };

  return (
    <>
      <DeviceTitle style={{ paddingBottom: "1rem", width: "100%" }}>
        <div>Deployed Devices</div>
        <div style={{ display: "flex" }}>
          {/* Temporarily Remove function because flow isn't finished*/}
          {/*<span style={{ marginRight: "10px" }}>Bulk Undeploy</span>*/}
          {/*<Checkbox*/}
          {/*  isChecked={isBulkDeployChecked}*/}
          {/*  onChange={handleBulkDeployChange}*/}
          {/*/>*/}
        </div>
      </DeviceTitle>

      <ImageCardWrapper id="deviceManageTabList" ref={carouselRef}>
        {deployedDevices.map((d: SwrmDevices) => (
          <DeviceCard
            key={d.Id}
            device={d}
            onClick={() => handleImageCardClick(d)}
          />
        ))}
      </ImageCardWrapper>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        onConfirm={handleUndeployDevice}
        headerTitle={"Undeploy Device"}
        confirmMsg={confirmMsg}
        onClose={handleConfirmModalClose}
        modalWidth="30vw"
        minWidth="450px"
      />
    </>
  );
}
