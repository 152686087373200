import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { integrationService } from "../../../services/integration/integration.service";
import { ResponseObject } from "../../../interfaces/response/Response";

interface Integration {
  Id: number;
  Name: string;
  URL: string;
  APIToken: string | null;
}

export interface IntegrationCompany {
  Integration: Integration;
  Enabled: boolean;
}
interface IntegrationState {
  integrationsIsLoading: boolean;
  integrations: IntegrationCompany[];
}

const initialState: IntegrationState = {
  integrationsIsLoading: false,
  integrations: [],
};

export const integrationSlice = createSlice({
  name: "integration",
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build.addCase(integrationService.getIntegrations.pending, (state) => {
      state.integrationsIsLoading = true;
    });
    build.addCase(
      integrationService.getIntegrations.fulfilled,
      (
        state,
        action: PayloadAction<
          ResponseObject<IntegrationCompany> | null | undefined
        >,
      ) => {
        if (action.payload !== null && action.payload !== undefined) {
          state.integrations = [action.payload.Result];
          state.integrationsIsLoading = false;
        }
      },
    );
  },
});

export default integrationSlice.reducer;
