import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAllDevices } from "../../../services/device/deviceGet.service";
import { useAppDispatch } from "../../../store/hooks";
import { DeviceTitle } from "../manage/DeviceCardList.style";
import Checkbox from "../../../components/checkbox/Checkbox";
import DeployDeviceModal from "../../../components/modals/deploy-device-modal/DeployDeviceModal";
import { SwrmDevices } from "../../../interfaces/device/SwrmDevice";
import DeviceCard from "../DeviceCard";
import { ImageCardWrapper } from "../../../components/image-card/ImageCard.style";

export default function DeployedDevices() {
  const carouselRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [isBulkDeployChecked, setIsBulkDeployChecked] = useState(false);
  const devices = useSelector((state: any) => state.devices.data);
  const [isOpenDevicePairingModal, setIsOpenDevicePairingModal] =
    useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<SwrmDevices | null>(
    null,
  );

  // Now, we filter out only undeployed devices based on the deployment status.
  const unDeployedDevices = devices.filter(
    (device: SwrmDevices) => !device.Deployed,
  );

  useEffect(() => {
    dispatch(getAllDevices()).then(() => {
      // Logging the devices, for development environment only
      if (process.env.NODE_ENV === "development") {
        console.log(devices);
      }
    });
  }, [dispatch]);

  const handleImageCardClick = (index: number) => {
    setSelectedDevice(unDeployedDevices[index]);
    handleSelectRelationModalOpen();
  };

  const handleBulkDeployChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsBulkDeployChecked(e.target.checked);
  };
  const handleSelectRelationModalOpen = () => {
    setIsOpenDevicePairingModal(true);
  };
  const handleSelectRelationModalClose = () => {
    document.body.style.overflow = "auto"; // enable scrolling
    setIsOpenDevicePairingModal(false);
    resetData();
  };
  const resetData = () => {
    setSelectedDevice(null);
  };
  return (
    <>
      <DeviceTitle style={{ paddingBottom: "1rem", width: "100%" }}>
        <div>Undeployed Devices</div>
        <div style={{ display: "flex" }}>
          {/* Temporarily Remove function because flow isn't finished*/}
          {/*<span style={{ marginRight: "10px" }}>Bulk Deploy</span>*/}
          {/*<Checkbox*/}
          {/*  isDisabled={true}*/}
          {/*  isChecked={isBulkDeployChecked}*/}
          {/*  onChange={handleBulkDeployChange}*/}
          {/*/>*/}
        </div>
      </DeviceTitle>

      <ImageCardWrapper id="deviceManageTabList" ref={carouselRef}>
        {unDeployedDevices.map((d: SwrmDevices, index: number) => (
          <DeviceCard
            key={d.Id}
            device={d}
            onClick={() => handleImageCardClick(index)}
          />
        ))}
      </ImageCardWrapper>
      {/* set relation with device */}
      {selectedDevice && (
        <DeployDeviceModal
          isOpenModel={isOpenDevicePairingModal}
          device={selectedDevice || undefined}
          onClose={handleSelectRelationModalClose}
        />
      )}
    </>
  );
}
