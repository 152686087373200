import { useEffect, useState } from "react";
import {
  AccountWrapper,
  PhotoWrapper,
  AvatarWrapper,
  StyledMdEdit,
  TextWrapper,
  InputButtonWrapper,
} from "./Account.style";
import { Typography } from "../../typrography/Typography";
import AvatarUploadModal from "../../modals/avatar-upload-modal/AvatarUploadModal";
import Input from "../../input/Input";
import { FormWrapper } from "../../modals/CustomModal.style";
import { Formik } from "formik";
import * as Yup from "yup";
import { StyledFormikForm } from "../ai-model-manager/Upload.style";
import { Button } from "../../button/Button";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { Image } from "../../image/Image";
import accountService from "../../../services/account/account.services"; // IUpdateAccountEmailPayload,
import { DefaultThemeColor } from "../../../app/Theme";
import { useAuth0 } from "@auth0/auth0-react";
import { awsUtils } from "../../../utils/awsUtils";
import { UserUpdatePayload } from "../../../interfaces/authentication/User";
import { integrationService } from "../../../services/integration/integration.service";

type Props = {};

interface FormValues {
  firstName: string;
  lastName: string;
  jobTitle: string;
  company: string;
}
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  company: Yup.string().required("Company Name is required"),
});

const btnStyle = {
  position: "absolute",
  bottom: "5px",
  right: "0",
  margin: "15px 0",
  hover: `color:red`,
};

const Account = (props: Props) => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state: RootState) => state.persisted.auth);
  const { user } = useAuth0();
  const [isAvatarUploadModalOpen, setIsAvatarUploadModalOpen] = useState(false);

  const currentUserUpdatePayload: UserUpdatePayload = {
    UserId: user?.sub,
    MediaId: auth.userInfo.MediaId,
    FirstName: auth.userInfo.FirstName || "",
    LastName: auth.userInfo.LastName || "",
    CompanyName: auth.company.Name || "",
    JobTitle: "",
  };

  const initialValues = {
    firstName: auth?.userInfo?.FirstName || "",
    lastName: auth?.userInfo?.LastName || "",
    email: auth?.userInfo?.Email || "",
    jobTitle: auth?.userInfo?.JobTitle || "",
    company: auth?.company?.Name || "",
  };

  const allowedFieldToEditDefault = {
    firstName: false,
    lastName: false,
    jobTitle: false,
    company: false,
  };

  const [allowedFieldToEdit, setAllowedFieldToEdit] = useState(
    allowedFieldToEditDefault,
  );

  const setIsValueToEdit = (field: "firstName" | "lastName" | "company") => {
    setAllowedFieldToEdit((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleSubmit = async (values: FormValues) => {
    if (auth?.userInfo) {
      const payload: UserUpdatePayload = {
        UserId: user?.sub,
        MediaId: auth.userInfo.MediaId,
        FirstName: values.firstName.trim() || "",
        LastName: values.lastName.trim() || "",
        CompanyName: values.company.trim() || "",
        JobTitle: "",
      };

      await dispatch(accountService.updateUserProfile(payload));
    }
  };

  const resetEditingFields = () => {
    setAllowedFieldToEdit((prev) => ({
      firstName: false,
      lastName: false,
      jobTitle: false,
      company: false,
    }));
  };

  const handleOpenAvatarUploadModal = () => {
    setIsAvatarUploadModalOpen(true);
  };

  const handleCloseRAvatarUploadModal = () => {
    setIsAvatarUploadModalOpen(false);
  };

  useEffect(() => {
    // Fetch Integrations
    if (
      auth.company.IntegratedWith !== null ||
      auth.company.IntegratedWith !== ""
    ) {
      dispatch(integrationService.getIntegrations());
    }
  }, []);

  return (
    <>
      <AccountWrapper>
        <PhotoWrapper>
          <Typography variant="2xl" weight="black">
            Photo
          </Typography>
          <AvatarWrapper onClick={handleOpenAvatarUploadModal}>
            <Image
              src={awsUtils.getSignedImageURL(
                auth?.userInfo?.MediaURL || user?.picture!,
              )}
              alt="User Image"
            />
            <div>
              <StyledMdEdit />
            </div>
          </AvatarWrapper>
        </PhotoWrapper>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }: any) => (
            <StyledFormikForm>
              <TextWrapper>
                <Typography
                  direction="flex-start"
                  variant="2xl"
                  style={{ padding: "0.5vw 0" }}
                >
                  Profile
                </Typography>
                <Typography
                  direction="flex-start"
                  variant="lg"
                  color="primary"
                  style={{ padding: "0.5vw 0" }}
                >
                  This information will be displayed throughout the website,
                  please ensure that the information is accurate.
                </Typography>
              </TextWrapper>
              <FormWrapper>
                <InputButtonWrapper>
                  <Input
                    disabled={!allowedFieldToEdit["firstName"]}
                    name={"firstName"}
                    placeHolder={"First Name"}
                    label={"First Name"}
                    width={"100%"}
                    height="5.3vh"
                    fontSize="1vw"
                    inputWrapperWidth="100%"
                  />
                  {!!allowedFieldToEdit["firstName"] && (
                    <Button
                      type="submit"
                      borderRadius="0"
                      height="5.3vh"
                      color={"success"}
                      hoverColor={DefaultThemeColor.secondary}
                      style={btnStyle}
                    >
                      Update
                    </Button>
                  )}
                  {!allowedFieldToEdit["firstName"] && (
                    <Button
                      onClick={() => setIsValueToEdit("firstName")}
                      borderRadius="0"
                      height="5.3vh"
                      hoverColor={DefaultThemeColor.secondary}
                      style={btnStyle}
                    >
                      Change
                    </Button>
                  )}
                </InputButtonWrapper>
                <InputButtonWrapper>
                  <Input
                    disabled={!allowedFieldToEdit["lastName"]}
                    name={"lastName"}
                    placeHolder={"Last Name"}
                    label={"Last Name"}
                    width={"100%"}
                    height="5.3vh"
                    fontSize="1vw"
                    inputWrapperWidth="100%"
                  />
                  {!!allowedFieldToEdit["lastName"] && (
                    <Button
                      type="submit"
                      borderRadius="0"
                      height="5.3vh"
                      color={"success"}
                      hoverColor={DefaultThemeColor.secondary}
                      style={btnStyle}
                    >
                      Update
                    </Button>
                  )}
                  {!allowedFieldToEdit["lastName"] && (
                    <Button
                      onClick={() => setIsValueToEdit("lastName")}
                      borderRadius="0"
                      height="5.3vh"
                      hoverColor={DefaultThemeColor.secondary}
                      style={btnStyle}
                    >
                      Change
                    </Button>
                  )}
                </InputButtonWrapper>
              </FormWrapper>
              <FormWrapper>
                <InputButtonWrapper>
                  <Input
                    disabled
                    name={"email"}
                    placeHolder={"Email"}
                    label={"Email (Cannot be changed)"}
                    width={"100%"}
                    height="5.3vh"
                    fontSize="1vw"
                    inputWrapperWidth="100%"
                    type="email"
                  />
                </InputButtonWrapper>
              </FormWrapper>

              {/*
                Conditionally render Company Information
                section based on user role
              */}
              {auth.userRole === "Owner" && (
                <>
                  <TextWrapper>
                    <Typography
                      direction="flex-start"
                      variant="2xl"
                      style={{ padding: "0.5vw 0" }}
                    >
                      Company Information
                    </Typography>
                    <Typography
                      direction="flex-start"
                      variant="lg"
                      color="primary"
                      style={{ padding: "0.5vw 0" }}
                    >
                      Update the company's information here.
                    </Typography>
                  </TextWrapper>
                  <FormWrapper>
                    <InputButtonWrapper>
                      <Input
                        disabled={!allowedFieldToEdit["company"]}
                        name={"company"}
                        placeHolder={"Company"}
                        label={"Company"}
                        width={"100%"}
                        height="5.3vh"
                        fontSize="1vw"
                        inputWrapperWidth="100%"
                      />
                      {!!allowedFieldToEdit["company"] && (
                        <Button
                          type="submit"
                          borderRadius="0"
                          height="5.3vh"
                          color={"success"}
                          hoverColor={DefaultThemeColor.secondary}
                          style={{
                            ...btnStyle,
                            ...(auth.userRole !== "Owner" && {
                              backgroundColor: "grey",
                            }),
                          }}
                        >
                          Update
                        </Button>
                      )}
                      {!allowedFieldToEdit["company"] && (
                        <Button
                          onClick={() => setIsValueToEdit("company")}
                          borderRadius="0"
                          height="5.3vh"
                          hoverColor={DefaultThemeColor.secondary}
                          style={{
                            ...btnStyle,
                          }}
                        >
                          Change
                        </Button>
                      )}
                    </InputButtonWrapper>
                    {/*
                      "Job Title" commented out for now. 
                      Will re-implement if needed
                    */}
                    {/* <InputButtonWrapper>
                      <Input
                        disabled
                        name={"jobTitle"}
                        placeHolder={"Job Title"}
                        label={"Job Title"}
                        width={"100%"}
                        height="5.3vh"
                        padding="10px 0 0"
                        fontSize="1vw"
                        inputWrapperWidth="100%"
                      />
                      <Button
                        disabled
                        borderRadius="0"
                        height="5.3vh"
                        hoverColor={DefaultThemeColor.secondary}
                        style={btnStyle}
                      >
                        Change
                      </Button>
                    </InputButtonWrapper> */}
                  </FormWrapper>
                </>
              )}
            </StyledFormikForm>
          )}
        </Formik>
      </AccountWrapper>
      {!!auth?.userInfo && (
        <AvatarUploadModal
          isOpen={isAvatarUploadModalOpen}
          onClose={handleCloseRAvatarUploadModal}
          user={currentUserUpdatePayload}
          title={"Upload"}
          buttonText={"Upload"}
          buttonPadding={"10px"}
        />
      )}
    </>
  );
};

export default Account;
