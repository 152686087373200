import React, { useState } from "react";
import { DeviceStatisticsWrapper } from "../../../device-manager-elements/DeviceManagerMain.style";
import { useAppSelector } from "../../../../store/hooks";
import HistoricalChartSection from "../../../../pages/dashboard/HistoricalChartSection";
import ExportDataBtn from "../../../../pages/dashboard/ExportDataBtn";

export const HistoricalData = () => {
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const { selectedDevice } = useAppSelector((state) => state.devices);
  return (
    <>
      {isDataAvailable && (
        <ExportDataBtn locationId={null} deviceId={selectedDevice?.Id || ""} />
      )}
      <DeviceStatisticsWrapper>
        {selectedDevice.Id ?
          <HistoricalChartSection
            selectedLocation={null}
            selectedDevice={selectedDevice?.Id}
            setIsDataAvailable={setIsDataAvailable}
          />
        : null}
      </DeviceStatisticsWrapper>
    </>
  );
};
