import { useEffect, useState } from "react";
import HistoricalChart from "./HistoricalChart";
import { getHistoricalScannedSensorData } from "../../services/dashboard/dashboard.service";
import { betterDateFormat } from "../../utils/dateUtils";
import { HistoricalChartWrapper } from "./Dashboard.style";
import { sensorNameToReadableName } from "../../utils/dashboardUtils";
import Loader from "../../components/loader/Loader";
import NoDataAvailable from "../../components/loader/NoDataAvailable";
import { integrationService } from "../../services/integration/integration.service";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import {
  GroupedDailySensorValue,
  GroupedHistoricalSensor,
  GroupedHistoricalSensorValue,
} from "../../interfaces/sensorData/SensorData";
import { ResponseObject } from "../../interfaces/response/Response";
import { IntegratedWeeklySensorData } from "../../interfaces/sensorData/IntegratedSensorData";
import GraphTimeline from "../../components/graph-timeline/GraphTimeline";

// Define the structure of the fetched data for the chart
export interface HistoricalData {
  date: string;
  average: number;
  type?: string;
  count?: number;
}

export interface DailyHistoricalData {
  date: string;
  time: string;
  value: number;
  type?: string;
  count?: number;
}

export interface DailyHistoricalData {
  date: string;
  time: string;
  value: number;
  type?: string;
  count?: number;
}

interface SensorHistoricalData {
  [key: string]: HistoricalData[] | DailyHistoricalData[];
}

const barChart = ["Door", "Water"];
// Function to format bar chart sensor type
const getBarChartSensorType = (sensorName: string): string => {
  switch (sensorName) {
    case barChart[0]:
      return "Open";
    case barChart[1]:
      return "Available";
    default:
      return "Open";
  }
};

// Functions to format sensor data
const formatHistoricalData = (
  data: GroupedHistoricalSensorValue[] | IntegratedWeeklySensorData[],
  sensor: string,
): HistoricalData[] => {
  if (barChart.includes(sensor)) {
    return data.map((d) => ({
      date: betterDateFormat(d.Date.toString()),
      type: getBarChartSensorType(sensor),
      count:
        d.AggregatedValue !== null ?
          parseFloat(d.AggregatedValue.toFixed(2))
        : 0,
      average: 0,
    }));
  }

  return data.map((d) => ({
    date: betterDateFormat(d.Date.toString()),
    average:
      d.AggregatedValue !== null ? parseFloat(d.AggregatedValue.toFixed(2)) : 0,
  }));
};

const formatDailyHistoricalData = (
  data: GroupedDailySensorValue[],
  sensor: string,
): DailyHistoricalData[] => {
  if (barChart.includes(sensor)) {
    return data.map((d) => ({
      date: betterDateFormat(d.Date.toString()),
      time: d.Time,
      type: sensor == "Water" ? "Available" : "Open",
      count: d.Value !== null ? parseFloat(d.Value.toFixed(2)) : 0,
      value: 0,
    }));
  }

  return data.map((d) => ({
    date: betterDateFormat(d.Date.toString()),
    time: d.Time,
    value: d.Value !== null ? parseFloat(d.Value.toFixed(2)) : 0,
  }));
};

interface Props {
  selectedLocation: number | null | undefined;
  selectedDevice: string | null | undefined;
  setIsDataAvailable?: (value: boolean) => void;
}

// Weekly Chart Section
const HistoricalChartSection = ({
  selectedLocation,
  selectedDevice,
  setIsDataAvailable,
}: Props) => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state: RootState) => state.persisted.auth);
  const integrations = useAppSelector(
    (state: RootState) => state.integration.integrations,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isIntegrationData, setIsIntegrationData] = useState<boolean>(false);
  const [currentTimelineForGraph, setCurrentTimelineForGraph] =
    useState<string>("weekly");
  // Define default sensor values
  const defaultChartData: SensorHistoricalData = {};
  const [historicalData, setHistoricalData] =
    useState<SensorHistoricalData>(defaultChartData);
  const [sensors, setSensors] = useState<string[]>([]);
  const [orderedSensors, setOrderedSensors] = useState<string[]>([]);
  const specificOrder = [
    "Temperature",
    "Humidity",
    "AQI",
    "Current",
    "Voltage",
    "Wind",
    "CO2",
    "Moisture",
    "Conductivity",
    "DiElectricity",
    "Salinity",
    "Field Capacity",
    "Wilting Point",
    "Water Balance",
    "Oxygen",
  ];
  const hideSensor = ["Unknown"];

  // Function to fetch Integration Data
  const fetchIntegrationData = async () => {
    const response = await integrationService.getWeeklySensorData(
      selectedDevice,
      selectedLocation,
      dispatch,
    );

    if (response !== null && response.Result !== null) {
      setIsDataAvailable && setIsDataAvailable(!!Object.keys(response.Result));
      const formattedSensorData: SensorHistoricalData = {};

      Object.keys(response.Result).forEach((key) => {
        const value: IntegratedWeeklySensorData[] =
          response.Result[key as keyof typeof response.Result];

        formattedSensorData[key] = formatHistoricalData(value, key);
      });

      Object.entries(formattedSensorData).length > 0 &&
        setIsIntegrationData(true);
      setSensors(Object.keys(response.Result));
      setHistoricalData(formattedSensorData);
    }
  };

  // Function to fetch SWRM Data
  const fetchSWRMData = async (timeline: string) => {
    const response: ResponseObject<GroupedHistoricalSensor[]> | null =
      await getHistoricalScannedSensorData(
        dispatch,
        selectedLocation,
        selectedDevice?.toLocaleString() || "",
        timeline,
      );

    if (response !== null) {
      if (response.Result.length > 0) {
        const sensorNameList = response.Result.filter(
          (gws: GroupedHistoricalSensor) => !hideSensor.includes(gws.Key),
        ).map((gws: GroupedHistoricalSensor) => gws.Key);

        setIsDataAvailable && setIsDataAvailable(!!sensorNameList.length);
        const formattedSensorData: SensorHistoricalData = {};

        sensorNameList.forEach((sensorName: string) => {
          const selectedSensor = response.Result.find((item) => {
            return item.Key == sensorName;
          });

          if (selectedSensor?.Key && selectedSensor?.Value) {
            if (timeline === "daily") {
              formattedSensorData[sensorName] = formatDailyHistoricalData(
                selectedSensor.Value as GroupedDailySensorValue[],
                selectedSensor.Key,
              );
            } else {
              formattedSensorData[sensorName] = formatHistoricalData(
                selectedSensor.Value as GroupedHistoricalSensorValue[],
                selectedSensor.Key,
              );
            }
            if (timeline === "daily") {
              formattedSensorData[sensorName] = formatDailyHistoricalData(
                selectedSensor.Value as GroupedDailySensorValue[],
                selectedSensor.Key,
              );
            } else {
              formattedSensorData[sensorName] = formatHistoricalData(
                selectedSensor.Value as GroupedHistoricalSensorValue[],
                selectedSensor.Key,
              );
            }
          }
        });

        setSensors(sensorNameList);
        setHistoricalData(formattedSensorData);
      }
    }
  };

  const fetchData = async (timeline: string) => {
    if (
      auth.company.IntegratedWith !== "" &&
      auth.company.IntegratedWith !== null &&
      integrations[0].Enabled
    ) {
      // Fetch Integration Data
      await fetchIntegrationData();
    }

    // Fetch SWRM Data
    await fetchSWRMData(timeline);

    // Finish Loading Status
    setIsLoading(false);
  };

  const resetStates = async () => {
    setIsLoading(true);
    setCurrentTimelineForGraph("weekly");
    setHistoricalData(defaultChartData);
    setIsIntegrationData(false);
  };

  const handleTimelineOnClick = async (timelineSelected: string) => {
    setIsLoading(true);
    setHistoricalData(defaultChartData);
    setCurrentTimelineForGraph(timelineSelected);
    await fetchData(timelineSelected);
  };

  useEffect(() => {
    resetStates();
    fetchData("weekly");
  }, [selectedLocation, selectedDevice]);

  // Update orderesensors state changes
  useEffect(() => {
    const restSensors = sensors.filter(
      (sensor) => !specificOrder.includes(sensor),
    );
    const reorderedSensors = specificOrder.concat(restSensors);
    setOrderedSensors(reorderedSensors);
  }, [sensors]);

  return (
    <div className="position-relative">
      {/* Render Graph Timeline options if not Integration Sensor Data*/}
      <GraphTimeline
        isIntegrationData={isIntegrationData}
        selectedTimeline={currentTimelineForGraph}
        handleOnClick={handleTimelineOnClick}
      />

      {
        isLoading ?
          <Loader loadingText="Loading sensor data" />
          // /* Render GaugePanel components based on sensor data */
        : !Object.keys(historicalData).length ?
          <NoDataAvailable />
        : <>
            <HistoricalChartWrapper>
              {/* Render charts components based on sensor data */}
              {orderedSensors.map(
                (sensor: string, index: number) =>
                  historicalData[sensor] && (
                    <HistoricalChart
                      key={index}
                      type={
                        isIntegrationData ? `Weekly` : (
                          `${currentTimelineForGraph
                            .charAt(0)
                            .toUpperCase()}${currentTimelineForGraph.slice(1)}`
                        )
                      }
                      title={`
                        ${sensorNameToReadableName(sensor)} ${
                          currentTimelineForGraph === "daily" ?
                            "Since Yesterday"
                          : "Per Day"
                        }
                      `}
                      data={historicalData[sensor]}
                      chartType={barChart.includes(sensor) ? "Bar" : "Line"}
                      yFieldLabel={
                        barChart.includes(sensor) ? "count"
                        : currentTimelineForGraph === "daily" ?
                          "value"
                        : "average"
                      }
                    />
                  ),
              )}
            </HistoricalChartWrapper>
          </>

      }
    </div>
  );
};

export default HistoricalChartSection;
