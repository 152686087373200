import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosResponse } from "axios";
import JSZip from "jszip";
import { ResponseObject } from "../../interfaces/response/Response";
import { AIModel } from "../../interfaces/aiModel/AIModel";
import {
  validateDownloadErrorResult,
  validateDownloadSuccessResult,
  validateErrorResult,
  validateFetchErrorResult,
  validateFetchSuccessResult,
  validateSuccessResult,
} from "../../utils/axiosUtils";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";
export interface LabelFile {
  labelFile: File;
}

export interface aiModelUploadParams {
  aiModelFile: File | null;
  name: string;
  /* Commented out for the time being, will re-implement if need be */
  // labelFiles: File[];
  imageFile: File | null;
  description: string;
  manualLabel: string;
}

export interface aiModelEditParams {
  id: number;
  aiModelFile: File | null;
  name: string;
  /* Commented out for the time being, will re-implement if need be */
  // labelFiles: File[];
  imageFile: File | null;
  description: string;
}

export interface aiModelFilterParams {
  dateCreated?: string;
  deviceId?: string | number;
  orderByDesc?: boolean;
}

export const getAllAIModels = createAsyncThunk(
  "device/getAllAIModels",
  async (_, { dispatch }) => {
    const response = await axios
      .get(`${API_URL}/AIModels`)
      .then(async (response: AxiosResponse<ResponseObject<AIModel[]>>) => {
        const result = response.data;
        await validateFetchSuccessResult(response);

        return result;
      })
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateFetchErrorResult(error, dispatch);
      });

    return response;
  },
);

export const filterAIModels = createAsyncThunk(
  "aiModels/filterAIModels",
  async (params: aiModelFilterParams, { dispatch }) => {
    const response = await axios
      .get(
        `${API_URL}/AIModels/fordropdown?dateCreated=${params.dateCreated}&deviceId=${params.deviceId}&orderByDesc=${params.orderByDesc}`,
      )
      .then(async (response: AxiosResponse<ResponseObject<AIModel[]>>) => {
        // Validate Success Result
        const result = response.data;
        await validateFetchSuccessResult(response);

        return result;
      })
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateFetchErrorResult(error, dispatch);
      });

    return response;
  },
);

export const downloadAIModel = async (
  aiModelId: number,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  /*
    Response is expected to be an arraybuffer. We are not
    returning any data, but we rendering appropriate error messages
  */
  await axios
    .get(`${API_URL}/AIModels/download?aiModelId=${aiModelId}`, {
      responseType: "arraybuffer",
    })
    .then(async (response: AxiosResponse<ArrayBuffer>) => {
      // Validate Success Result based on the data returned
      await validateDownloadSuccessResult(response, dispatch);
      const zip = new JSZip();
      return zip && zip.loadAsync(response.data);
    })
    .then(async (zip) => {
      const newZip = new JSZip();
      const promises: any = [];
      zip.forEach((relativePath, zipEntry) => {
        const promise = zipEntry.async("arraybuffer").then((fileContent) => {
          newZip.file(relativePath, fileContent, { binary: true });
        });

        promises.push(promise);
      });

      return Promise.all(promises).then(() => newZip);
    })
    .then(async (newZip) => {
      return newZip.generateAsync({ type: "arraybuffer" });
    })
    .then(async (newZipContent) => {
      const blob = new Blob([newZipContent]);

      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "model.zip";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })
    .catch(async (error: AxiosError<ArrayBuffer>) => {
      await validateDownloadErrorResult(error, dispatch);
    });
};

export const getAIModelDownloadURL = async (
  aiModelId: number,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .get(`${API_URL}/AIModels/downloadToDevice?aiModelId=${aiModelId}`)
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      await validateFetchSuccessResult(response);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateFetchErrorResult(error, dispatch);
    });

  return response;
};

export const uploadAIModel = async (
  params: aiModelUploadParams,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data;",
    },
  };

  const formData = new FormData();
  formData.append("Name", params.name.trim());
  formData.append("ManualLabel", params.manualLabel.trim());
  formData.append("Description", params.description.trim()!);
  formData.append("AIModelFile", params.aiModelFile!);
  formData.append("ImageFile", params.imageFile!);

  /* Commented out for the time being, will re-implement if need be */
  // params.labelFiles.forEach((file: File) => {
  //   formData.append("LabelFiles", file);
  // });

  const response = await axios
    .post(`${API_URL}/AIModels/upload`, formData, config)
    .then(async (response: AxiosResponse<ResponseObject<AIModel>>) => {
      // Validate Success Result
      const result = response.data;
      await validateSuccessResult(
        response,
        dispatch,
        "Model uploaded successfully.",
      );

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

export const editAIModel = async (
  payload: aiModelEditParams,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data;",
    },
  };

  const formData = new FormData();
  formData.append("Name", payload.name.trim());
  formData.append("Description", payload.description.trim()!);
  formData.append("AIModelFile", payload.aiModelFile!);
  formData.append("ImageFile", payload.imageFile!);

  /* Commented out for the time being, will re-implement if need be */
  // payload.labelFiles.forEach((file: File) => {
  //   formData.append("LabelFiles", file);
  // });

  const response = await axios
    .patch(`${API_URL}/AIModels`, payload, config)
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      await validateSuccessResult(
        response,
        dispatch,
        "AI Model updated successfully.",
      );

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

export const duplicateAIModel = async (
  id: number,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .post(`${API_URL}/AIModels/duplicate?id=${id}`)
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      await validateSuccessResult(
        response,
        dispatch,
        "AI Model duplicated successfully.",
      );

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

export const bulkDownloadAIModels = async (
  ids: number[],
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  /*
    Response is expected to be an arraybuffer. We are not
    returning any data, but we rendering appropriate error messages
  */
  const queryParams = ids.join(",");
  await axios
    .get(`${API_URL}/AIModels/bulkDownload?aiModelIds=${queryParams}`, {
      responseType: "arraybuffer",
    })
    .then(async (response: AxiosResponse<ArrayBuffer>) => {
      // Validate Success Result based on the data returned
      await validateDownloadSuccessResult(response, dispatch);
      const blob = new Blob([response.data]);
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "models.zip";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })
    .catch(async (error: AxiosError<ArrayBuffer>) => {
      await validateDownloadErrorResult(error, dispatch);
    });
};

export const bulkDeleteAIModels = async (
  ids: number[],
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(`${API_URL}/AIModels/bulkDelete`, ids, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      await validateSuccessResult(
        response,
        dispatch,
        "AI Models deleted successfully.",
      );

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

export const bulkArchiveAIModels = async (
  ids: number[],
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(`${API_URL}/AIModels/bulkArchive`, ids, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      await validateSuccessResult(
        response,
        dispatch,
        "AI Models archived successfully.",
      );

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

export const deleteAIModel = async (
  id: number,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(`${API_URL}/AIModels/delete?id=${id}`)
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      await validateSuccessResult(
        response,
        dispatch,
        "AI Model deleted successfully.",
      );

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

export const archiveAIModel = async (
  id: number,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(`${API_URL}/AIModels/archive?id=${id}`)
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      await validateSuccessResult(
        response,
        dispatch,
        "AI Model archived successfully.",
      );

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const AIModelService = {
  getAllAIModels,
  filterAIModels,
  downloadAIModel,
  archiveAIModel,
  getAIModelDownloadURL,
  uploadAIModel,
  duplicateAIModel,
  editAIModel,
  bulkDeleteAIModels,
  bulkArchiveAIModels,
  bulkDownloadAIModels,
  deleteAIModel,
};

export default AIModelService;
