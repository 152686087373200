import { useState } from "react";
import { DefaultThemeBorder } from "../../../../app/Theme";
import { Routine } from "../../../../interfaces/device/Routine";
import { FlexContainer } from "../../../flex-container/FlexContainer";
import { Typography } from "../../../typrography/Typography";
import EditIcon from "../../../icons/EditIcon";
import DeleteIcon from "../../../icons/DeleteIcon";
import ToggleButton from "../../../button/toggle-button/ToggleButton";
import { useAppSelector } from "../../../../store/hooks";
import RelayRoutineEdit from "./RelayRoutineEdit";
import { FilterOption } from "../../../dropdown/filter-dropdown/FilterDropdown";

export interface RelayRoutineItemProps {
  routine: Routine;
  relayOptions: FilterOption[];
  onToggleRoutine: (
    deviceId: string,
    status: boolean,
    revert: () => void,
  ) => void;
  onDeleteRoutine: (routineId: number) => void;
}

const RelayRoutineItem = ({
  routine,
  relayOptions,
  onToggleRoutine,
  onDeleteRoutine,
}: RelayRoutineItemProps) => {
  const [openEditRoutineForm, setOpenEditRoutineForm] = useState(false);
  const devices = useAppSelector((state) => state.devices);
  const selectedDevice = devices.selectedDevice;

  const handleToggleRoutine = (status: boolean, revert: () => void) => {
    onToggleRoutine(selectedDevice.Id, status, revert);
  };

  const handleDeleteRoutine = () => {
    onDeleteRoutine(routine.Id);
  };

  const onCancel = () => {
    setOpenEditRoutineForm(false);
  };

  return (
    <>
      {openEditRoutineForm ?
        <RelayRoutineEdit
          routine={routine}
          relayOptions={relayOptions}
          steps={selectedDevice.Steps}
          onCancel={onCancel}
        />
      : <div
          style={{
            padding: "0.5rem",
            border: DefaultThemeBorder["border-md-yellow"],
            borderRadius: "4px",
          }}
        >
          <FlexContainer justifyContent="space-between">
            <Typography>
              Current Routine - {selectedDevice.Steps.length} Steps
              {routine.Enabled ?
                <Typography color="green-200">Routine in progress</Typography>
              : <Typography color="red-200">Routine stopped</Typography>}
            </Typography>
            <Typography variant="md" color="white" weight="bold">
              <div
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <EditIcon
                  handleEditOnClick={() => setOpenEditRoutineForm(true)}
                  size="26px"
                />
                <DeleteIcon
                  handleDeleteOnClick={handleDeleteRoutine}
                  size="26px"
                />
                <ToggleButton
                  id={routine.Id.toString()}
                  isActive={routine.Enabled || false}
                  onToggle={(status, revert) =>
                    handleToggleRoutine(status, revert)
                  }
                  margin="0"
                />
              </div>
            </Typography>
          </FlexContainer>
        </div>
      }
    </>
  );
};

export default RelayRoutineItem;
