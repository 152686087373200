import axios, { AxiosError, AxiosResponse } from "axios";
import { Device } from "../../interfaces/device/Device";
import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { ResponseObject } from "../../interfaces/response/Response";
import {
  validateErrorResult,
  validateSuccessResult,
} from "../../utils/axiosUtils";
import { Location } from "../../interfaces/device/Location";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

export interface DeviceDeployPayload {
  DeviceId: string;
  Name: string;
  MediaId: number;
  LocationId: number;
}

const patchDevice = async (
  device: Device,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(`${API_URL}/Device`, device)
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Device updated.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const patchDeviceDeployed = async (
  payload: DeviceDeployPayload,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(`${API_URL}/Device/deployDevice`, payload)
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Device updated.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const patchDeviceUndeploy = async (
  deviceId: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(`${API_URL}/Device/undeployDevice`, { deviceId })
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Device updated.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const patchDeviceEnabled = async (
  deviceId: string,
  enabled: boolean,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(`${API_URL}/Device/toggleEnabled`, {
      DeviceId: deviceId,
      Enabled: enabled,
    })
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Device updated.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

export const patchDeviceLocation = createAsyncThunk(
  "device/updateLocation",
  async (payload: { deviceId: string; locationId: number }, { dispatch }) => {
    const response = await axios
      .patch(`${API_URL}/Device/updateLocation`, payload)
      .then(async (response: AxiosResponse<ResponseObject<Location>>) => {
        // Validate Success Result
        const result = response.data;
        const successMessage: string = "Device updated.";
        await validateSuccessResult(response, dispatch, successMessage);

        return result;
      })
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateErrorResult(error, dispatch);
      });

    return response;
  },
);

export const patchDeviceArchive = async (
  payload: {
    id: string;
    archive: boolean;
  },
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(`${API_URL}/Device/archive`, payload)
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Device archived.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const devicePatchService = {
  patchDevice,
  patchDeviceDeployed,
  patchDeviceEnabled,
  patchDeviceUndeploy,
  patchDeviceArchive,
};

export default devicePatchService;
