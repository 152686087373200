import React, { Fragment } from "react";
import { CardType } from "../../interfaces/cardTypes/card-types";
import { DeviceManagerElement } from "./deviceManagerElement";

export interface Props {
  data: CardType[];
  styling?: {};
  children?: React.ReactNode[] | React.ReactNode;
}

export const DeviceManagerMain: React.FC<Props> = ({
  data,
  styling,
  children,
}) => {
  return (
    <Fragment>
      {data.map((config) => (
        //refactor into styled component later
        <div key={config.index} style={styling}>
          <DeviceManagerElement config={config} />
        </div>
      ))}
      {children ? children : null}
    </Fragment>
  );
};
