import { MessagePopupSetup } from "../store/slices/messagePopups/messagePopupSlice";

export const createMessagePopup = (
  code: number,
  message: string,
): MessagePopupSetup => {
  const popupMessage: MessagePopupSetup = {
    code: code,
    message: message,
  };

  return popupMessage;
};
