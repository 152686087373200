import React, { useState } from "react";
import { Theme } from "../../app/Theme";
import SideNavBar from "../../components/nav/side-nav/SideNavBar";
import { ContentWrapper } from "../../components/content-wrapper/ContentWrapper";
import TopNavBar from "../../components/nav/top-nav/TopNavBar";
import Titles from "../../components/title/Titles";
import { Tabs } from "../../components/tab/Tabs";
import General from "../../components/tab/help/General";
import DeviceIssues from "../../components/tab/help/DeviceIssues";
import CaptureIssues from "../../components/tab/help/CaptureIssues";
import LocationIssues from "../../components/tab/help/LocationIssues";
import PermissionIssues from "../../components/tab/help/PermissionIssues";
import IntegrationIssues from "../../components/tab/help/IntegrationIssues";
import AiModelIssues from "../../components/tab/help/AiModelIssues";
import Legal from "../../components/tab/help/Legal";
import { ITab } from "../../interfaces/tabs/pageTabs";

interface HelpProps {}

const tabs: ITab[] = [
  {
    label: "General",
    index: 1,
    Component: General,
  },
  {
    label: "Device Issues",
    index: 2,
    Component: DeviceIssues,
  },
  {
    label: "Capture Issues",
    index: 3,
    Component: CaptureIssues,
  },
  {
    label: "Location Issues",
    index: 4,
    Component: LocationIssues,
  },
  {
    label: "Permission Issues",
    index: 5,
    Component: PermissionIssues,
  },
  {
    label: "Integration Issues",
    index: 6,
    Component: IntegrationIssues,
  },
  {
    label: "AI Model Issues",
    index: 7,
    Component: AiModelIssues,
  },
  {
    label: "Legal",
    index: 8,
    Component: Legal,
  },
  // {
  //   label: "Submit A Ticket",
  //   index: 9,
  //   Component: SubmitATicket,
  // },
];

const Help = (props: HelpProps) => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);
  const handleEvent = (e: number) => {
    setSelectedTab(e);
  };
  return (
    <>
      <Theme>
        <SideNavBar />
        <ContentWrapper>
          <TopNavBar />
          <Titles
            heading="FAQ & Help"
            subheading="Select below the option that best represents your issue"
          />
          <Tabs
            selectedTab={selectedTab}
            onClick={handleEvent}
            tabs={tabs}
            tabsDirection="column"
            customTabsDisplay="flex"
            borderDirection="row"
            tabWidth="12.5vw"
            fontSize="1.25vw"
            tabPanelPadding="0"
          />
        </ContentWrapper>
      </Theme>
    </>
  );
};

export default Help;
