import axios, { AxiosError, AxiosResponse } from "axios";
import { Auth0User } from "../../interfaces/authentication/UserFromToken";
import { ResponseObject } from "../../interfaces/response/Response";
import { CompanyReturnPayload } from "../company/company.service";
import { decryptData } from "../../utils/awsUtils";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import {
  validateAuthErrorResult,
  validateAuthSuccessResult,
  validateErrorResult,
  validateFetchErrorResult,
  validateFetchSuccessResult,
  validateSuccessResult,
} from "../../utils/axiosUtils";
import {
  MessagePopupSetup,
  showPopupMessage,
} from "../../store/slices/messagePopups/messagePopupSlice";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

const verifyInSystem = async (
  id: string,
  accessToken: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .get(`${API_URL}/Authentication/verify?id=${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      await validateFetchSuccessResult(response);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateAuthErrorResult(error, dispatch);
    });
  return response;
};

const registerToSystem = async (
  auth0User: Auth0User,
  accessToken: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .post(`${API_URL}/Authentication/register`, auth0User, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      await validateSuccessResult(
        response,
        dispatch,
        "User created successfully.",
      );

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const authenticateToSystem = async (
  auth0User: Auth0User,
  accessToken: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .post(`${API_URL}/Authentication/authenticate`, auth0User, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      console.log("Validating response");
      // Validate response
      const result = response.data;
      await validateAuthSuccessResult(response, dispatch);

      // Decrypt Data
      const decryptDataResponse = await decryptData(response.data.Result).then(
        (result) => {
          const companyPayload: CompanyReturnPayload = JSON.parse(
            window.atob(result!),
          );
          setCompanyTokenToHeader(companyPayload.CompanyToken);

          return companyPayload;
        },
      );

      // Build new ResponseObject with appropriate return type
      const decryptedResponse: ResponseObject<CompanyReturnPayload> = {
        IsError: result.ErrorCode == 200 || (result.ErrorCode == 404 && false),
        ErrorCode: result.ErrorCode,
        ErrorMessage: result.ErrorMessage,
        Result: decryptDataResponse,
      };

      return decryptedResponse;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      /* 
        Not using axios utility methods here because of how 
        specific the Authenticate API is 
      */
      console.log("I am now in catch statement because there is an error");
      return await validateAuthErrorResult(error, dispatch);
    });

  return response;
};

const setAuthTokenToHeader = (Token: any) => {
  if (Token) {
    axios.defaults.headers.Authorization = `Bearer ${Token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

const setIntegrationAuthTokenToHeader = (Token: string) => {
  if (Token) {
    axios.defaults.headers.common["Integration-Authorization"] = Token;
  } else {
    delete axios.defaults.headers.common["Integration-Authorization"];
  }
};

const setIntegrationRefreshTokenToHeader = (Token: string) => {
  if (Token) {
    axios.defaults.headers.common["Integration-Refresh"] = Token;
  } else {
    delete axios.defaults.headers.common["Integration-Refresh"];
  }
};

const setCompanyHeaderLogin = (company: any) => {
  if (company) {
    const CompanyString = JSON.stringify(company, null, 2);
    //localStorage.setItem('companyToken', btoa(CompanyString));
    localStorage.setItem("company", btoa(CompanyString));

    axios.defaults.headers.common["BSD-Company"] = btoa(CompanyString);
  } else {
    delete axios.defaults.headers.common["BSD-Company"];
  }
};
const setCompanyTokenToHeader = (Token: any) => {
  if (Token) {
    localStorage.setItem("company", Token);
    axios.defaults.headers.common["BSD-Company"] = Token;
  } else {
    delete axios.defaults.headers.common["BSD-Company"];
  }
};

const setCompanyHeaderLanding = (company: any) => {
  if (company) {
    //localStorage.setItem('companyToken', btoa(CompanyString));
    const parsedCompany = isJson(company);
    localStorage.setItem("company", parsedCompany);
    axios.defaults.headers.common["BSD-Company"] = parsedCompany;
  } else {
    delete axios.defaults.headers.common["BSD-Company"];
  }
};

function isJson(str: any) {
  try {
    JSON.parse(str);
  } catch (e) {
    return str;
  }
  return JSON.parse(str);
}

const authService = {
  verifyInSystem,
  registerToSystem,
  authenticateToSystem,
  setAuthTokenToHeader,
  setIntegrationAuthTokenToHeader,
  setIntegrationRefreshTokenToHeader,
  setCompanyHeaderLogin,
  setCompanyHeaderLanding,
  setCompanyTokenToHeader,
};

export default authService;
