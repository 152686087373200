import {
  PopupText,
  PopupTextWrapper,
  PopupWrapper,
} from "./MessagePopup.style";
import {
  closePopupMessage,
  MessagePopupState,
} from "../../store/slices/messagePopups/messagePopupSlice";
import { useAppDispatch } from "../../store/hooks";
import { useEffect } from "react";

const MessagePopup = ({
  code: errorCode,
  message,
  isOpen,
}: MessagePopupState) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closePopupMessage());
  };

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        dispatch(closePopupMessage());
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  return (
    <PopupWrapper errorCode={errorCode} isOpen={isOpen} onClick={handleClose}>
      <PopupTextWrapper>
        <PopupText>{message}</PopupText>
      </PopupTextWrapper>
    </PopupWrapper>
  );
};

export default MessagePopup;
