import { Navigate, Route } from "react-router-dom";
import authService from "../../services/authentication/auth.service";
import { useAppDispatch } from "../../store/hooks";
import {
  editorRoutes,
  loggedInRoutes,
  newUserRoutes,
  notLoggedInRoutes,
  setupRoutes,
  viewerRoutes,
} from "./componentRegistry";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { setAccessToken, userLogout } from "../../store/slices/auth/authSlice";
import { resetNavLink } from "../../components/nav/side-nav/sideNavBarSlice";
import { setRouteOption } from "../../store/slices/routes/routesSlice";
import { OpenErrorNotification } from "../../components/notification/Notification";

export interface PathComponent {
  path: string;
  component: any;
}

export function RouteSelector(
  isAuthenticated: boolean,
  routeOption: number,
  hasSignedInPreviously: boolean,
  userRole: string,
) {
  let routes: any = [];
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently, getAccessTokenWithPopup, logout } =
    useAuth0();
  const accessTokenOnHeader = axios.defaults.headers.common["Authorization"];
  const companyTokenOnHeader = axios.defaults.headers.common["BSD-Company"];
  const isActiveSession = sessionStorage.getItem("isActiveSession");

  if (isAuthenticated && hasSignedInPreviously && isActiveSession === null) {
    logout();
    dispatch(userLogout());
    dispatch(resetNavLink());
    dispatch(setRouteOption(0));
  }

  switch (routeOption) {
    case 1: // New User Routes
      routes = buildRoutes(newUserRoutes);
      break;
    case 2: // Logged In Routes
      if (
        accessTokenOnHeader === undefined &&
        companyTokenOnHeader === undefined &&
        isActiveSession !== null
      ) {
        getAccessTokenSilently().then((token) => {
          authService.setAuthTokenToHeader(token);
        });
        authService.setCompanyTokenToHeader(localStorage.getItem("company"));
      }
      if (userRole?.toLocaleLowerCase() === "viewer") {
        routes = buildRoutes(viewerRoutes);
      } else if (userRole?.toLocaleLowerCase() === "editor") {
        routes = buildRoutes(editorRoutes);
      } else {
        routes = buildRoutes(loggedInRoutes);
      }

      break;
    case 3: // Not Logged In Routes
      routes = buildRoutes(notLoggedInRoutes);
      break;
    default: // Setup Routes
      if (isAuthenticated) {
        if (accessTokenOnHeader === undefined) {
          // Access Token Setup with failsafe
          const getAccessToken = async () => {
            let accessToken: string;
            try {
              accessToken = await getAccessTokenSilently();

              authService.setAuthTokenToHeader(accessToken);
              dispatch(setAccessToken(accessToken));
            } catch (e: any) {
              // Log user out
              logout();
              dispatch(userLogout());
              dispatch(resetNavLink());
              dispatch(setRouteOption(0));
              OpenErrorNotification(
                "A session error occurred. Please try logging in again",
              );
            }
          };

          getAccessToken();
        }

        routes = buildRoutes(setupRoutes);
      } else {
        routes = buildRoutes(notLoggedInRoutes);
      }
  }

  return routes;
}

function buildRoutes(pathCompLists: Array<PathComponent>) {
  let Routes: any = [];
  pathCompLists.map((item: PathComponent, index: number) =>
    Routes.push(
      <Route key={index} path={item.path} element={item.component} />,
    ),
  );
  // Create catch all route to redirect to the home screen
  Routes.push(<Route path="*" element={<Navigate to="/" replace />} />);
  return Routes;
}
