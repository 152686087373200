import React, { useState, useEffect, ChangeEvent } from "react";
import { FormControl } from "../../form-field/form-control/FormControl";
import { FormLabel } from "../../form-field/form-label/FormLabel";
import Input from "../../input/Input";
import TextEditor from "../../rich-text-editor/TextEditor";
import { EditorWrapper } from "../../rich-text-editor/TextEditor.style";
import { Typography } from "../../typrography/Typography";
import CustomModal from "../CustomModal";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  CustomGrid,
  LocalModalFormWrapper,
  ScaledButtonWrapper,
} from "../CustomModal.style";
import { Formik, FormikHelpers, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import {
  ErrorText,
  StyledFormikForm,
  UploadWrapper,
} from "../../tab/ai-model-manager/Upload.style";
import { getLocationTypeDropdown } from "../../../store/slices/dropdownOptions/dropdownListSlice";
import {
  getAllLocationsType,
  postLocation,
  searchLocations,
} from "../../../services/locations/Location.service";
import { postGroup } from "../../../services/locations/Group.service";
import { getAllGroupType } from "../../../services/locations/GroupType.service";
import DragUpload from "../../input/upload-input/DragUpload";
import { DeleteMedia, UploadMedia } from "../../../services/media/Media.Upload";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../notification/Notification";
import { DefaultThemeColor } from "../../../app/Theme";
import { DropdownMenuContainer } from "../../form-field/drop-down/Dropdown.style";
import { CustomDropdown } from "../../form-field/drop-down/CustomDropdown";
import {
  FilterDropdown,
  FilterOption,
} from "../../dropdown/filter-dropdown/FilterDropdown";
import { allLocationNames } from "../../../store/slices/locations/locationsSlice";
/**
 * Props for the LocationCreateModal component
 */
export interface LocationCreateModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  inputLabel: string;
  dropdownLabel: string;
  editorLabel: string;
  buttonText: string;
  width?: string;
  buttonHeight?: string;
  buttonPadding?: string;
  buttonMargin?: string;
  editorHeight?: number;
  formPadding?: string;
  style?: any;
}

/**
 * Form values for the LocationCreateModal
 */

interface FormValues {
  name: string;
  description: string;
  address: string;
  latitude: string;
  longitude: string;
}
/**
 * LocationCreateModal component to handle creation of locations
 */
const LocationCreateModal = (props: LocationCreateModalProps) => {
  const {
    isOpen,
    onClose,
    title,
    inputLabel,
    dropdownLabel,
    buttonText,
    buttonPadding,
    buttonMargin,
    editorLabel,
    formPadding,
    style,
  } = props;
  const initialValues = {
    name: "",
    description: "",
    address: "",
    longitude: "",
    latitude: "",
  };

  const dispatch = useAppDispatch();
  const [selectedOption, setSelectedOption] = useState(0);
  const [mediaId, setMediaId] = useState<null | number>(null);
  // get all dropdown list items for the dashboard page
  const locationTypeDropdown = useAppSelector(getLocationTypeDropdown);
  const currentLocationNames = useAppSelector(allLocationNames);
  // fetch all locations, devices and ai models on initial load of page
  useEffect(() => {
    dispatch(getAllLocationsType({}));
    dispatch(getAllGroupType({}));
  }, []);
  /**
   * Use Effect to get a list of location names for checking
   */
  useEffect(() => {
    if (isOpen) {
      dispatch(searchLocations({ searchString: "", namesOnly: true }));
    }
  }, [isOpen]);
  /**
   * Validation schema for the form.
   *
   * - 'name' field is required.
   * - 'name' must have a minimum length of 2 characters.
   * - 'name' can have a maximum length of 50 characters.
   */

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name must have at least 2 characters")
      .max(50, "Name must have less than 50 characters")
      .test(
        "existsCheck",
        "A location with that name already exists",
        (value: string) => !currentLocationNames.includes(value.toLowerCase()),
      ),
    description: Yup.string().required("Description is required"),
    latitude: Yup.number().min(-90, "-90° to 90°").max(90, "-90° to 90°"),
    longitude: Yup.number()
      .min(-180, "-180° to 180°")
      .max(180, "-180° to 180°"),
  });
  /**
   * Handle form submission
   */
  const handelSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    try {
      if (title === "Create a Group") {
        const response = await postGroup({
          Name: values.name,
          Description: values.description || "",
        });
        if (!response.IsError) {
          OpenSuccessNotification("Group created successfully!");
          handleModalClose();
        } else {
          OpenErrorNotification(response.ErrorMessage);
        }
        handleModalClose();
      } else {
        if (selectedOption) {
          const response = await postLocation({
            StoreID: 1,
            Enabled: false,
            Operating: false,
            Name: values.name,
            LocationTypeId: selectedOption,
            Description: values.description || "",
            Address: values.address,
            GPSLatitude: values.latitude ? values.latitude.toString() : "",
            GPSLongitude: values.longitude ? values.longitude.toString() : "",
            MediaId: mediaId ? mediaId : null,
          });
          if (!response.IsError) {
            OpenSuccessNotification("Location created successfully!");
            handleModalClose();
          } else {
            OpenErrorNotification(response.ErrorMessage);
          }
        } else {
          OpenErrorNotification("Please Select Location Type!");
        }
      }
    } catch (error) {
      console.error("Error creating location:", error);
    }
    actions.setSubmitting(false);
  };

  /**
   * Handle dropdown change
   */
  const handleDropdownChange = (selectedValue: number) => {
    setSelectedOption(selectedValue);
  };

  /**
   * Handle modal close
   */
  const handleModalClose = () => {
    onClose();
    resetData();
  };
  const resetData = () => {
    setSelectedOption(0);
  };
  const handleFileUpload = async (uploadedFile: File) => {
    try {
      await UploadMedia("Locations", uploadedFile).then((response) => {
        if (response && response?.Result?.Id) {
          setMediaId(response.Result.Id);
          OpenSuccessNotification("The file has been uploaded successfully.");
        } else {
          OpenErrorNotification(
            "Oops! The image upload failed. Please try again later.",
          );
          console.log("Upload File Error:", response);
        }
      });
    } catch (error) {
      console.error("handleFileUpload Error:", error);
    }
  };
  const handleFileRemove = async () => {
    if (mediaId) {
      try {
        const response = await DeleteMedia(mediaId);
        if (response && !response.IsError) {
          setMediaId(null);
        }
      } catch (error) {
        console.error("handleFileUpload Error:", error);
      }
    }
  };
  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0 20px", fontWeight: "700" }}
      >
        {title}
      </Typography>
    );
  };
  const cardFooter = () => {
    return (
      <ScaledButtonWrapper buttonPadding={buttonPadding}>
        <button type="submit" form="create-location-group">
          {buttonText}
        </button>
      </ScaledButtonWrapper>
    );
  };
  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => handleModalClose()}
      closeBtnTopPosition="0.2rem"
      padding="10px 0"
      width={title === "Create a Location" ? "auto" : ""}
      height={title === "Create a Location" ? "auto" : ""}
      header={cardHeader()}
      footer={cardFooter()}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handelSubmit}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          handleBlur,
          submitForm,
          setFieldTouched,
        }) => (
          <StyledFormikForm id={"create-location-group"}>
            <CustomGrid $customColumns="78% 22%" style={{ padding: "0 20px" }}>
              <LocalModalFormWrapper formPadding="0 20px 0 0" style={style}>
                <Input
                  name="name"
                  placeHolder="Insert Name"
                  label={inputLabel}
                  width="100%"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {title === "Create a Location" && (
                  <FormControl>
                    <FormLabel
                      htmlFor="location type"
                      fontSize="1.25vw"
                      fontWeight="700"
                      style={{ color: "white" }}
                    >
                      {dropdownLabel}
                    </FormLabel>

                    <div style={{ padding: "0.1rem", width: "100%" }}>
                      <FilterDropdown
                        title="Location Type"
                        options={locationTypeDropdown?.options || []}
                        isFullSize
                        onChange={({ label, value }: FilterOption) => {
                          handleDropdownChange(Number(value));
                        }}
                      />
                    </div>
                  </FormControl>
                )}
                {title === "Create a Location" && (
                  <>
                    <Input
                      name="address"
                      placeHolder="Insert Address"
                      label={"Location Address"}
                      width="100%"
                      value={values.address} // Now it will recognize values.name
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <LocalModalFormWrapper>
                      <Input
                        type="number"
                        name="latitude"
                        placeHolder="Latitude"
                        label={"Latitude"}
                        width="100%"
                        value={values?.latitude}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        type="number"
                        name="longitude"
                        placeHolder="Longitude"
                        label={"Longitude"}
                        width="100%"
                        value={values?.longitude}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </LocalModalFormWrapper>
                  </>
                )}
              </LocalModalFormWrapper>
              {title === "Create a Location" && (
                <UploadWrapper>
                  <DragUpload
                    color="white"
                    iconColor={DefaultThemeColor["primary"]}
                    accept="image/*"
                    onFileUpload={handleFileUpload}
                    onFileRemove={handleFileRemove}
                    fileType={["image"]}
                    width="10rem"
                    height="10rem"
                  />
                </UploadWrapper>
              )}
            </CustomGrid>
            <Typography
              variant="lg"
              direction="flex-start"
              style={{ padding: "10px 20px", fontWeight: "700" }}
            >
              {editorLabel}
            </Typography>
            <EditorWrapper>
              <Field name="description">
                {({ field, form }: any) => (
                  <TextEditor
                    field={field}
                    form={form}
                    onBlur={() => setFieldTouched("description", true)}
                  />
                )}
              </Field>
              <ErrorMessage name="description" component={ErrorText} />
            </EditorWrapper>
          </StyledFormikForm>
        )}
      </Formik>
    </CustomModal>
  );
};

export default LocationCreateModal;
