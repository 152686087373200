import React from "react";
import { IconWrapper, StyledMasterIcon } from "./MastercardIcon.style";
import icon from "../../assets/union-pay.png";

type Props = {};

const UnionPayIcon = (props: Props) => {
  return (
    <IconWrapper>
      <StyledMasterIcon src={icon} alt="union pay icon" />
    </IconWrapper>
  );
};

export default UnionPayIcon;
