import React from "react";
import AvailableSubscriptions from "./AvailableSubscriptions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { setSelectedSubscription } from "../../../store/slices/subscriptions/subscriptionsSlice";
import { StripeProduct } from "../../../interfaces/authentication/Subscription";
import CurrentSubscription from "./CurrentSubscription";
import {
  SubscriptionWrapper,
  TextWrapper,
} from "../../../components/card/subscription-card/SubscriptionCard.style";
import { Typography } from "../../../components/typrography/Typography";

type Props = {};

const Subscription: React.FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const { subscriptions, selectedSubscription, companySubscription } =
    useAppSelector((state: RootState) => state.subscriptions);

  // Helper function to check if a subscription is purchased
  const isSubscriptionPurchased = (): boolean =>
    Array.isArray(companySubscription) && companySubscription.length > 0;

  // Helper function to handle subscription selection
  const handleSubscriptionSelection = (sub: StripeProduct): void => {
    if (!selectedSubscription || selectedSubscription.Id !== sub.Id) {
      dispatch(setSelectedSubscription(sub));
    }
  };

  // Helper function to render a message when no subscription is purchased
  const renderNoSubscriptionPurchased = () => (
    <TextWrapper>
      <Typography
        direction="flex-start"
        variant="lg"
        style={{ padding: "0.5vw 0" }}
      >
        There is no subscription purchased yet! Please select a subscription and
        make payment.
      </Typography>
    </TextWrapper>
  );

  // Helper function to render subscription remaining days
  const renderSubscriptionRemainingDays = () => {
    if (!isSubscriptionPurchased() || !companySubscription[0]?.EndAt) {
      return renderNoSubscriptionPurchased();
    }

    const givenDate: Date = new Date(companySubscription[0].EndAt);
    const currentDate: Date = new Date();
    let isExpired = false;
    let expiredMsg = "";

    if (currentDate > givenDate) {
      const expirationDateString: string = givenDate.toLocaleDateString(
        "en-US",
        {
          year: "numeric",
          month: "long",
          day: "numeric",
        },
      );

      isExpired = true;
      expiredMsg = `The subscription has expired on ${expirationDateString}.`;
    }

    return (
      <TextWrapper style={{ padding: "0.5vw 0" }}>
        {!isExpired && (
          <Typography
            direction="flex-start"
            variant="2xl"
            style={{ padding: "0.5vw 0" }}
          >
            Current Subscription
          </Typography>
        )}
        <Typography
          direction="flex-start"
          variant="lg"
          color="primary"
          style={{ padding: "0.5vw 0" }}
        >
          {isExpired ?
            expiredMsg
          : getSubscriptionMessage(companySubscription[0].EndAt)}
        </Typography>
      </TextWrapper>
    );
  };

  // Helper function to get subscription message based on remaining days
  const getSubscriptionMessage = (expirationDate: Date): string => {
    const givenDate: Date = new Date(expirationDate);
    const currentDate: Date = new Date();
    const timeDifferenceInMilliseconds: number =
      givenDate.getTime() - currentDate.getTime();
    const timeDifferenceInSeconds: number = timeDifferenceInMilliseconds / 1000;
    const remainingDays: number = Math.ceil(
      timeDifferenceInSeconds / (24 * 60 * 60),
    );

    // if (remainingDays > 365) {
    //   const remainingYears: number = Math.floor(remainingDays / 365);
    //   const remainingMonths: number = Math.floor((remainingDays % 365) / 30);
    //   const remainingDaysInMonth: number = remainingDays % 30;

    //   const yearsMessage: string =
    //     remainingYears > 0
    //       ? `${remainingYears} ${remainingYears === 1 ? "year" : "years"}`
    //       : "";
    //   const monthsMessage: string =
    //     remainingMonths > 0
    //       ? `${remainingMonths} ${remainingMonths === 1 ? "month" : "months"}`
    //       : "";
    //   const daysMessage: string =
    //     remainingDaysInMonth > 0
    //       ? `${remainingDaysInMonth} ${
    //           remainingDaysInMonth === 1 ? "day" : "days"
    //         }`
    //       : "";

    //   return `${yearsMessage} ${monthsMessage} ${daysMessage} left on this subscription.`;
    // } else if (remainingDays > 30) {
    //   const remainingMonths: number = Math.floor(remainingDays / 30);
    //   const remainingDaysInMonth: number = remainingDays % 30;

    //   const monthsMessage: string = `${remainingMonths} ${
    //     remainingMonths === 1 ? "month" : "months"
    //   }`;
    //   const daysMessage: string =
    //     remainingDaysInMonth > 0
    //       ? `${remainingDaysInMonth} ${
    //           remainingDaysInMonth === 1 ? "day" : "days"
    //         }`
    //       : "";

    //   return `${monthsMessage} ${daysMessage} left on this subscription.`;
    // } else
    if (remainingDays > 0) {
      return `${remainingDays} ${
        remainingDays === 1 ? "day" : "days"
      } left on this subscription.`;
    } else if (remainingDays === 0) {
      return "Today is the last day of the subscription.";
    } else {
      return `The subscription has expired.`;
    }
  };

  return (
    <>
      {/* Wrapper for the entire subscription section */}
      <SubscriptionWrapper>
        {/* Component to display the current subscription */}
        <CurrentSubscription
          isSubscriptionPurchased={isSubscriptionPurchased}
          companySubscription={companySubscription}
          subscriptions={subscriptions}
        />
        {/* Component to display available subscriptions and handle selection */}
        <AvailableSubscriptions
          subscriptions={subscriptions}
          selectedSubscription={selectedSubscription}
          handleSubscriptionSelection={handleSubscriptionSelection}
        />
      </SubscriptionWrapper>
      {/* Render remaining days message */}
      {renderSubscriptionRemainingDays()}
    </>
  );
};

export default Subscription;
