import React, { useEffect, useState } from "react";
import { Theme } from "../../app/Theme";
import { ContentWrapper } from "../../components/content-wrapper/ContentWrapper";
import SideNavBar from "../../components/nav/side-nav/SideNavBar";
import TopNavBar from "../../components/nav/top-nav/TopNavBar";
import Edit from "../../components/tab/ai-model-manager/Edit";
import Upload from "../../components/tab/ai-model-manager/Upload";
import { Tabs } from "../../components/tab/Tabs";
import Titles from "../../components/title/Titles";
import { TabsType } from "../location";
import { useAppDispatch } from "../../store/hooks";
import { getAllAIModels } from "../../services/aiModels/aimodel.service";
import { useNavigate, useParams } from "react-router-dom";

interface AiModelManagerProps {}

const tabs: TabsType = [
  {
    label: "Upload",
    index: 0,
    Component: Upload,
    path: "upload",
  },
  {
    label: "Edit",
    index: 1,
    Component: Edit,
    path: "edit",
  },
];

const AiModelManager: React.FC = (props: AiModelManagerProps) => {
  const initialTabIndex = 0;
  const navigate = useNavigate();
  const { activeTab } = useParams<any>();
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState<number>(initialTabIndex);

  useEffect(() => {
    dispatch(getAllAIModels());
  }, []);

  useEffect(() => {
    if (!activeTab) {
      navigate(`/cv-model-manager/${getPathByIndex()}`);
    } else {
      const tab =
        tabs?.find((t) => t.path === activeTab)?.index || initialTabIndex;
      setSelectedTab(tab);
    }
  }, [activeTab]);

  const getPathByIndex = (index = 0) => {
    return tabs[index].path;
  };

  const handleTabEvent = (e: number) => {
    setSelectedTab(e);
    // update navigation
    navigate(`/cv-model-manager/${getPathByIndex(e)}`);
  };

  return (
    <>
      <Theme>
        <SideNavBar />
        <ContentWrapper>
          <TopNavBar />
          <Titles
            heading="Model Manager"
            subheading="Update and edit models for devices"
          />
          <Tabs
            selectedTab={selectedTab}
            onClick={handleTabEvent}
            tabs={tabs}
          />
        </ContentWrapper>
      </Theme>
    </>
  );
};

export default AiModelManager;
