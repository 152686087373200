import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  filterAIModels,
  getAllAIModels,
} from "../../../services/aiModels/aimodel.service";
import { AIModel } from "../../../interfaces/aiModel/AIModel";
import EditIcon from "../../../components/icons/EditIcon";
import DownloadIcon from "../../../components/icons/DownloadIcon";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import { formatDate } from "../../../utils/dateUtils";
import { RootState } from "../../store";
import ViewDevicesIcon from "../../../components/icons/ViewDevicesIcon";
import ArchiveIcon from "../../../components/icons/ArchiveIcon";
import DuplicateIcon from "../../../components/icons/DuplicateIcon";
import RetrainIcon from "../../../components/icons/RetrainIcon";
import { awsUtils } from "../../../utils/awsUtils";
import { ResponseObject } from "../../../interfaces/response/Response";

export interface AiModelManagerData {
  id: number;
  checked: boolean;
  isDeleted: boolean;
  isArchived: boolean;
  model: [string | null, string, string];
  actionIcons: React.FC<any>[];
  activeDevices: number;
  dateCreated: string;
}
interface AIModelState {
  data: AIModel[];
  combinedData: AiModelManagerData[];
  getDataStatus: "idle" | "succeeded" | "loading" | "failed";
  errorMessage: string;
  /* Commented out for the time being, will re-implement if need be */
  // labelFiles?: File[];
  aiModelFile?: File | null;
  thumbnailFile?: File | null;
  isUsingFilters: boolean;
}

export interface FileActions {
  file?: File | null;
  type: string;
  action: string;
}

const initialState: AIModelState = {
  data: [] as AIModel[],
  combinedData: [] as AiModelManagerData[],
  getDataStatus: "idle",
  errorMessage: "",
  /* Commented out for the time being, will re-implement if need be */
  // labelFiles: [],
  aiModelFile: null,
  thumbnailFile: null,
  isUsingFilters: false,
};

export const aiModelSlice = createSlice({
  name: "aiModels",
  initialState,
  reducers: {
    setCheckedStatus: (
      state,
      action: PayloadAction<{ aiModelId: number; checked: boolean }>,
    ) => {
      const { aiModelId, checked } = action.payload;
      const selectedAiModel = state.combinedData.find(
        (aiModel) => aiModel.id === aiModelId,
      );
      if (selectedAiModel) {
        selectedAiModel.checked = checked;
      }
    },

    resetAllCheckedStatus: (state) => {
      const updatedCombinedAiModels = state.combinedData.map((aiModel) => ({
        ...aiModel,
        checked: false,
      }));
      state.combinedData = updatedCombinedAiModels;
    },

    /* Commented out for the time being, will re-implement if need be */
    // uploadLabelFiles: (state, action: PayloadAction<File[]>) => {
    //   const duplicateFilesArray = [...state.labelFiles!, ...action.payload];
    //   const uniqueFilesArray = duplicateFilesArray.filter(
    //     (obj, index, array) => {
    //       return (
    //         array.findIndex((innerObj) => innerObj.name === obj.name) === index
    //       );
    //     },
    //   );

    //   state.labelFiles = [...uniqueFilesArray];
    // },

    fileAction: (state, action: PayloadAction<FileActions>) => {
      switch (action.payload.type) {
        case "AI_MODEL":
          if (action.payload.action === "UPLOAD")
            state.aiModelFile = action.payload.file;
          if (action.payload.action === "REMOVE") state.aiModelFile = null;
          break;
        case "THUMBNAIL":
          if (action.payload.action === "UPLOAD")
            state.thumbnailFile = action.payload.file;
          if (action.payload.action === "REMOVE") state.thumbnailFile = null;
          break;
      }
    },

    addNewAIModel: (state, action: PayloadAction<AIModel>) => {
      const createdAIModel: AIModel = action.payload;
      createdAIModel.ActiveDevices = 0;

      const sortedModels = [createdAIModel, ...state.data].sort((a, b) => {
        if (a.Name < b.Name) {
          return -1;
        } else {
          return 1;
        }
      });

      state.data = sortedModels;
      const combinedAIModels = setCombinedAIModels(sortedModels);
      state.combinedData = combinedAIModels;
    },

    /* Commented out for the time being, will re-implement if need be */
    // deleteLabelFile: (state, action: PayloadAction<File>) => {
    //   state.labelFiles = state.labelFiles!.filter(
    //     (file) => file.name !== action.payload.name,
    //   );
    // },

    updateIsUsingFilters: (state, action: PayloadAction<boolean>) => {
      state.isUsingFilters = action.payload;
    },

    resetAllFiles: (state) => {
      /* Commented out for the time being, will re-implement if need be */
      // state.labelFiles = [];
      state.aiModelFile = null;
      state.thumbnailFile = null;
    },
  },
  extraReducers: (build) => {
    build.addCase(getAllAIModels.pending, (state) => {
      state.getDataStatus = "loading";
    });
    build.addCase(
      getAllAIModels.fulfilled,
      (
        state,
        action: PayloadAction<ResponseObject<AIModel[]> | null | undefined>,
      ) => {
        state.getDataStatus = "succeeded";

        if (action.payload !== null && action.payload !== undefined) {
          state.getDataStatus = "succeeded";
          state.data = action.payload.Result;
          const combinedAIModels = setCombinedAIModels(state.data);
          state.combinedData = combinedAIModels;
          state.isUsingFilters = false;
        }
      },
    );
    build.addCase(filterAIModels.pending, (state) => {
      state.getDataStatus = "loading";
      state.isUsingFilters = true;
    });
    build.addCase(
      filterAIModels.fulfilled,
      (
        state,
        action: PayloadAction<ResponseObject<AIModel[]> | null | undefined>,
      ) => {
        if (action.payload !== null && action.payload !== undefined) {
          state.getDataStatus = "succeeded";
          state.data = action.payload.Result;
          const combinedAIModels = setCombinedAIModels(state.data);
          state.combinedData = combinedAIModels;
        }
      },
    );
  },
});

const checkPreSignedURL = (preSignedUrl: string) => {
  return fetch(preSignedUrl)
    .then((response) => {
      if (response.ok) {
        // URL is still valid
        return false;
      } else if (response.status === 403) {
        // URL is expired
        return true;
      } else {
        // Handle other errors
        throw new Error("Failed to fetch resource from presigned URL");
      }
    })
    .catch((error) => {
      console.error("Error checking presigned URL expiration:", error);
      return true; // Assume URL is expired in case of errors
    });
};

const setCombinedAIModels = (data: AIModel[]) => {
  const aiModels: AiModelManagerData[] = [];
  data?.map((item) => {
    const formattedDate = formatDate(item.CreatedAt);
    return aiModels.push({
      id: item.Id,
      checked: false,
      isDeleted: item.Deleted,
      isArchived: item.Archived,
      model: [
        awsUtils.getSignedImageURL(item.Image),
        item.Name,
        item.Description,
      ],
      actionIcons: [
        EditIcon,
        ArchiveIcon,
        DownloadIcon,
        DeleteIcon,
        DuplicateIcon,
        ViewDevicesIcon,
        RetrainIcon,
      ],
      activeDevices: item.ActiveDevices,
      dateCreated: formattedDate,
    });
  });
  return aiModels;
};

export const { setCheckedStatus, resetAllCheckedStatus } = aiModelSlice.actions;

export const {
  /* Commented out for the time being, will re-implement if need be */
  // uploadLabelFiles,
  // deleteLabelFile,
  fileAction,
  resetAllFiles,
  addNewAIModel,
} = aiModelSlice.actions;
export const selectCheckedAiModels = (state: RootState) =>
  state.aiModels.combinedData.filter((capture) => capture.checked === true);

export const selectCheckedAiModelIds = (state: RootState) =>
  state.aiModels.combinedData
    .filter((aiModel) => aiModel.checked === true)
    .map((aiModel) => aiModel.id);

export default aiModelSlice.reducer;
