import axios, { AxiosError, AxiosResponse } from "axios";
import { DeviceModel } from "../../interfaces/device/DeviceModel";
import { OpenErrorNotification } from "../../components/notification/Notification";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { ResponseObject } from "../../interfaces/response/Response";
import {
  validateErrorResult,
  validateFetchErrorResult,
  validateFetchSuccessResult,
  validateSuccessResult,
} from "../../utils/axiosUtils";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";
const getAllDeviceModels = async (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .get(`${API_URL}/DeviceModel`)
    .then(async (response: AxiosResponse<ResponseObject<DeviceModel[]>>) => {
      // Validate Success Result
      const result = response.data;
      await validateFetchSuccessResult(response);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateFetchErrorResult(error, dispatch);
    });

  return response;
};

const searchDeviceModels = async (
  searchedName: string,
  deviceName: string,
  deviceType: string,
  deployed: boolean,
  groupName: string,
  orderByDesc: boolean,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const searchString = `?SearchedName${
    searchedName.length > 0 ? "=" + searchedName : ""
  }&DeviceName${deviceName.length > 0 ? "=" + deviceName : ""}&DeviceType${
    deviceType.length > 0 ? "=" + deviceType : ""
  }&Deployed${deployed ? "=" + deployed : ""}&GroupName${
    groupName.length > 0 ? "=" + groupName : ""
  }&OrderByDesc${orderByDesc ? "=" + orderByDesc : ""}`;

  const response = await axios
    .get(`${API_URL}/Device/search?${searchString}`)
    .then(async (response: AxiosResponse<ResponseObject<DeviceModel[]>>) => {
      // Validate Success Result
      const result = response.data;
      await validateFetchSuccessResult(response);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateFetchErrorResult(error, dispatch);
    });

  return response;
};

const postDeviceModel = async (
  deviceModel: DeviceModel,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .post(`${API_URL}/DeviceModel`, deviceModel, {
      headers: {
        "Model-Hash-Code": deviceModel.ModelHashCode,
      },
    })
    .then(async (response: AxiosResponse<ResponseObject<DeviceModel>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Model added to device.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const patchDeviceModel = async (
  deviceModel: DeviceModel,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(`${API_URL}/DeviceModel`, deviceModel, {
      headers: {
        "Model-Hash-Code": deviceModel.ModelHashCode,
      },
    })
    .then(async (response: AxiosResponse<ResponseObject<DeviceModel>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Model updated on device.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const deleteDeviceModel = async (
  deviceModel: DeviceModel,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .delete(`${API_URL}/DeviceModel`, { data: deviceModel })
    .then(async (response: AxiosResponse<ResponseObject<string>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Model removed from device.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const sendDetectionUploadLimit = async (
  deviceId: string,
  limit: number,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(
      `${API_URL}/DeviceModel/sendDetectionUploadLimit?deviceId=${deviceId}&limit=${limit}`,
    )
    .then(async (response: AxiosResponse<ResponseObject<DeviceModel>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Model upload limit updated.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const deviceModelService = {
  getAllDeviceModels,
  searchDeviceModels,
  postDeviceModel,
  patchDeviceModel,
  deleteDeviceModel,
  sendDetectionUploadLimit,
};

export default deviceModelService;
