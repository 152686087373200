import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
// import authReducer from './auth';
import radioReducer from "../components/radio-button/radioSlice";
import dropdownListReducer from "./slices/dropdownOptions/dropdownListSlice";
import sortByReducer from "../components/sort-by/sortBySlice";
import sideNavBarReducer from "../components/nav/side-nav/sideNavBarSlice";
import tabListReducer from "./slices/tabs/tabListSlice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { combineReducers } from "redux";
import authReducer from "./slices/auth/authSlice";
import ThumbnailSizeReducer from "../components/thumbnail-size/thumbnailSizeSlice";
import devicesSliceReducer from "./slices/devices/devicesSlice";
import fetchParametersReducer from "./slices/fetchParameters/fetchParametersSlice";
import locationsReducer from "./slices/locations/locationsSlice";
import captureReducer from "./slices/captures/captureSlice";
import aiModelsReducer from "./slices/ai-models/aiModelSlice";
import subscriptionReducer from "./slices/subscriptions/subscriptionsSlice";
import loaderReducer from "./slices/loader/loaderSlice";
import routesReducer from "./slices/routes/routesSlice";
import usersReducer from "./slices/users/usersSlice";
import integrationReducer from "./slices/integration/integrationSlice";
import messagePopupReducer from "./slices/messagePopups/messagePopupSlice";

const rootReducer = combineReducers({
  sideNavBar: sideNavBarReducer,
  auth: authReducer, //setup the auth state to redux persist
  routes: routesReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    // routes: routesReducer,
    loader: loaderReducer,
    dropdownList: dropdownListReducer,
    radio: radioReducer,
    sortBy: sortByReducer,
    tabList: tabListReducer,
    persisted: persistedReducer,
    thumbnailSize: ThumbnailSizeReducer,
    devices: devicesSliceReducer,
    locations: locationsReducer,
    fetchParams: fetchParametersReducer,
    captures: captureReducer,
    aiModels: aiModelsReducer,
    subscriptions: subscriptionReducer,
    users: usersReducer,
    integration: integrationReducer,
    messagePopup: messagePopupReducer,
  },
  /*
  we should ignore all the action types it dispatches when using Redux-Persist
  to avoid non-serializable value error
  */
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //serializableCheck: {
      //  ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      //},
      serializableCheck: false,
    }),
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
