import React from "react";
import {
  RelayRule,
  RelayRuleAction,
  RuleComparisonToDisplay,
} from "../../../../interfaces/device/Relay";
import { Typography } from "../../../typrography/Typography";
import DeleteIcon from "../../../icons/DeleteIcon";
import { FlexContainer } from "../../../flex-container/FlexContainer";
import EditIcon from "../../../icons/EditIcon";
import { DefaultThemeColor } from "../../../../app/Theme";
import { SimplifiedSensor } from "../../../../interfaces/device/Sensor";
import { nonNumericalSensors } from "./RelayRuleDialog";

interface Props {
  item: RelayRule;
  sensor: SimplifiedSensor | undefined | null;
  handleEditClick: (relayRule: RelayRule) => void;
  handleDeleteClick: (ruleId: number) => void;
  handleEnableDisabled: (rule: RelayRule, checked: boolean) => void;
}

export default function RelayRuleItem({
  item,
  sensor,
  handleEditClick,
  handleDeleteClick,
  handleEnableDisabled,
}: Props) {
  const onDeleteIconClick = () => {
    handleDeleteClick(item.Id);
  };

  const onEditIconClick = () => {
    handleEditClick(item);
  };

  const onCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleEnableDisabled(item, e.target.checked);
  };

  const renderRelayRuleInfo = () => {
    if (sensor) {
      const relayRuleInfo: string = `Turn ${
        RelayRuleAction[item.Action]
      } when ${sensor.value} is ${RuleComparisonToDisplay[item.Comparison]} 
        ${formatRelayRuleValue(sensor, item.Value)}
      `;
      return relayRuleInfo;
    } else {
      const relayRuleInfo: string = `Turn ${
        RelayRuleAction[item.Action]
      } when Unknown is 
        ${RuleComparisonToDisplay[item.Comparison]} 
        ${item.Value}
      `;
      return relayRuleInfo;
    }
  };

  const formatRelayRuleValue = (sensor: SimplifiedSensor, value: number) => {
    const isNonNumericalSensor = nonNumericalSensors.includes(sensor.value);

    if (isNonNumericalSensor) {
      switch (sensor.value) {
        case "Door":
          return value === 1 ? "Open" : "Closed";
        case "Water":
          return value === 1 ? "Available" : "Unavailable";
        default:
          return value === 1 ? "Open" : "Closed";
      }
    }

    return value;
  };

  return (
    <div
      style={{
        padding: "0.5rem",
        borderLeft: `4px solid ${DefaultThemeColor["green-100"]}`,
      }}
    >
      <FlexContainer justifyContent="space-between">
        <Typography>{renderRelayRuleInfo()}</Typography>
        <Typography variant="md" color="white" weight="bold">
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <EditIcon handleEditOnClick={onEditIconClick} size="26px" />
            <DeleteIcon handleDeleteOnClick={onDeleteIconClick} size="26px" />
          </div>
        </Typography>
      </FlexContainer>
    </div>
  );
}
