import React, { ReactElement } from "react";
import { connect } from "react-redux";
import Loader from "../components/loader/Loader";
import { useAppSelector } from "../store/hooks";
import {
  getLoaderMessage,
  isGlobalLoaderDisplay,
} from "../store/slices/loader/loaderSlice";
import { ResponsiveComponent } from "./MainApp.style";

interface Props {
  //auth: Auth,
  children?: ReactElement;
}

const LayOut = (props: Props) => {
  const displayLoader = useAppSelector(isGlobalLoaderDisplay);
  const loaderMessage = useAppSelector(getLoaderMessage);
  return (
    <div>
      {displayLoader && (
        <Loader
          isLoadingScreen
          displayLoadingText={!!loaderMessage}
          loadingText={loaderMessage}
        />
      )}
      <ResponsiveComponent>{props.children}</ResponsiveComponent>
    </div>
  );
};

function mapToStateToProps(state: any) {
  return {
    auth: state.auth,
  };
}

export default connect(mapToStateToProps)(LayOut);
