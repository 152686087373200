import { Formik } from "formik";
import CustomModal from "../../modals/CustomModal";
import { Typography } from "../../typrography/Typography";
import * as Yup from "yup";
import { StyledFormikForm } from "../ai-model-manager/Upload.style";
import { ScaledButtonWrapper } from "../../modals/CustomModal.style";
import Input from "../../input/Input";
import SwrmNavLogo from "../../../assets/SWRM_Logo_512x80_Yellow.png";
import { FlexContainer } from "../../flex-container/FlexContainer";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import {
  AuthenticatePayload,
  integrationService,
  UpdateIntegrationStatusPayload,
} from "../../../services/integration/integration.service";
import { useEffect, useState } from "react";
import { clearIntegrationTokens } from "../../../store/slices/auth/authSlice";
import authService from "../../../services/authentication/auth.service";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  integrationId: number;
  integrationStatus: boolean;
  integrationName: string;
}

interface FormValues {
  username: string;
  password: string;
}

const initialValues: FormValues = {
  username: "",
  password: "",
};

const validationSchema = Yup.object({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
});

const IntegrationLoginModal = (props: Props) => {
  const { isOpen, onClose, integrationId, integrationStatus, integrationName } =
    props;
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state: RootState) => state.persisted.auth);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { integrationAccessToken, integrationRefreshToken } = auth.tokens;

  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0 20px", fontWeight: "700" }}
      >
        Integration Login
      </Typography>
    );
  };
  const cardFooter = () => {
    return (
      <FlexContainer gap="1rem" justifyContent="center" alignItems="center">
        <ScaledButtonWrapper buttonPadding={"10px"}>
          <button type="submit" form="integration-authentication">
            {isLoading ? "Logging in..." : "Login"}
          </button>
        </ScaledButtonWrapper>
      </FlexContainer>
    );
  };

  const handleToggleEnable = async (integrationId: number, status: boolean) => {
    const payload: UpdateIntegrationStatusPayload = {
      companyId: auth.company.UID,
      integrationId: integrationId,
      status: status,
    };

    await dispatch(integrationService.updateIntegrationStatus(payload));
  };

  const handleSubmit = async (values: FormValues) => {
    setIsLoading(true);
    const payload: AuthenticatePayload = {
      Username: values.username,
      Password: values.password,
      IntegratedWith: auth.company.IntegratedWith,
    };

    await dispatch(integrationService.authenticate(payload));
    setIsLoading(false);

    if (integrationAccessToken && integrationRefreshToken) {
      await handleToggleEnable(integrationId, integrationStatus);
      onClose();
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      closeBtnTopPosition="0.188rem"
      padding="10px 0"
      width={"35%"}
      header={cardHeader()}
      footer={cardFooter()}
    >
      <FlexContainer
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="1.3rem"
      >
        <div>
          <img
            src={SwrmNavLogo}
            alt="SWRM Logo"
            style={{ width: "14vw", marginTop: "1rem" }}
          ></img>
        </div>
        <div style={{ textAlign: "center" }}>
          <Typography>
            Enter your {integrationName} integration credentials
          </Typography>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleBlur, handleChange, errors }: any) => (
            <StyledFormikForm id="integration-authentication">
              <FlexContainer flexDirection="column" gap="0.5rem">
                <div style={{ position: "relative" }}>
                  <Input
                    type="text"
                    name="username"
                    placeHolder="Username"
                    label={""}
                    width="18rem"
                    margin="0 auto 22px"
                    padding="0 10px 0"
                    value={values.username}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>

                <div style={{ position: "relative" }}>
                  <Input
                    type="password"
                    name="password"
                    placeHolder="Password"
                    label={""}
                    width="18rem"
                    margin="0 auto 22px"
                    padding="0 10px 0"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
              </FlexContainer>
            </StyledFormikForm>
          )}
        </Formik>
      </FlexContainer>
    </CustomModal>
  );
};

export default IntegrationLoginModal;
