import React, { useEffect, useState } from "react";
import { Theme } from "../../app/Theme";
import { Tabs } from "../../components/tab/Tabs";
import TopNavBar from "../../components/nav/top-nav/TopNavBar";
import SideNavBar from "../../components/nav/side-nav/SideNavBar";
import { ContentWrapper } from "../../components/content-wrapper/ContentWrapper";
import Titles from "../../components/title/Titles";
import DeviceCardList from "./manage/DeviceCardList";
import DeviceSetup from "./setup/DeviceSetup";
import { useAppSelector } from "../../store/hooks";
import DeviceCarouselList from "./manage/DeviceCarouselList";
import { useAppDispatch } from "../../store/hooks";
import { getAllBoardTypes } from "../../services/device/deviceBoardType.service";
import { getAllDevices } from "../../services/device/deviceGet.service";
import {
  isDeviceLoading,
  resetDeviceImageCard,
  resetSelectedDevice,
} from "../../store/slices/devices/devicesSlice";
import { ScrollableWrapper } from "../../components/content-wrapper/ContentWrapper.style";
import Loader from "../../components/loader/Loader";
import { useParams, useNavigate } from "react-router-dom";
import { getAllGroups } from "../../services/locations/GroupType.service";

type TabsType = {
  label: string;
  index: number;
  path: string;
  Component: React.FC<{}>;
}[];

const deviceManagerMainTabs: TabsType = [
  {
    label: "Device Manage",
    index: 0,
    path: "device-manage",
    Component: DeviceCardList,
  },
  {
    label: "Setup",
    index: 1,
    path: "setup",
    Component: DeviceSetup,
  },
];

const deviceManagerCarouselTabs: TabsType = [
  {
    label: "Device Manage",
    index: 0,
    path: "device-manage",
    Component: DeviceCarouselList,
  },
  {
    label: "Setup",
    index: 1,
    path: "setup",
    Component: DeviceSetup,
  },
];

const DeviceManager = () => {
  const initialTabIndex = 0;
  const navigate = useNavigate();
  const { activeTab } = useParams<any>();
  const dispatch = useAppDispatch();
  const activeDeviceId = useAppSelector(
    (state) => state.devices.activeDeviceId,
  );
  const isLoading = useAppSelector(isDeviceLoading);
  const [selectedTab, setSelectedTab] = useState<number>(initialTabIndex);

  useEffect(() => {
    // Fetching Dropdown List Options Data
    dispatch(getAllDevices());
    dispatch(getAllBoardTypes());
    dispatch(getAllGroups());
  }, []);

  useEffect(() => {
    return () => {
      resetSelection();
    };
  }, []);

  useEffect(() => {
    if (!activeTab) {
      resetSelection();
      navigate(`/devices/${getPathByIndex()}`);
    } else {
      const tab =
        deviceManagerMainTabs?.find((t) => t.path === activeTab)?.index ||
        initialTabIndex;
      setSelectedTab(tab);
    }
  }, [activeTab]);

  const getPathByIndex = (index = 0) => {
    return deviceManagerMainTabs[index].path;
  };

  const resetSelection = () => {
    // reset selected device from image carousel slice
    dispatch(resetDeviceImageCard());
    // reset selected device from device slice
    dispatch(resetSelectedDevice());
    // set index to initial state
    setSelectedTab(initialTabIndex);
  };

  const handleTabEvent = (e: number) => {
    if (selectedTab === 0 && e === 1) {
      // reset selection
      resetSelection();
    }
    // set tab
    setSelectedTab(e);
    // update navigation
    navigate(`/devices/${getPathByIndex(e)}`);
  };

  return (
    <>
      {isLoading && <Loader isLoadingScreen />}
      <Theme>
        <SideNavBar />
        <ContentWrapper>
          <TopNavBar />
          <ScrollableWrapper>
            <Titles
              heading="Device Manager"
              subheading="Find and manage devices within the network"
              marginTop={"0"}
            />
            <Tabs
              selectedTab={selectedTab}
              onClick={handleTabEvent}
              tabs={
                activeDeviceId !== ""
                  ? deviceManagerCarouselTabs
                  : deviceManagerMainTabs
              }
              variant="pageTab"
            />
          </ScrollableWrapper>
        </ContentWrapper>
      </Theme>
    </>
  );
};

export default DeviceManager;
