import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { UserCompany } from "../../../interfaces/authentication/User";
import { getCompanyUsers } from "../../../services/company/company.service";
//Define a type for the slice state
interface UsersState {
  data: UserCompany[];
  isLoading: boolean;
}
const initialState: UsersState = {
  data: [],
  isLoading: false,
};

export const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserRole: (state, action: PayloadAction<UserCompany>) => {
      const index = state.data.findIndex((u) => u.Id === action.payload.Id);
      if (index !== -1) {
        state.data[index] = action.payload;
      }
    },
    removeUser: (state, action: PayloadAction<number>) => {
      const index = state.data.findIndex((u) => u.Id === action.payload);
      if (index !== -1) {
        state.data.splice(index, 1);
      }
    },
  },
  extraReducers: (build) => {
    build.addCase(getCompanyUsers.pending, (state, action) => {
      state.isLoading = true;
    });
    build.addCase(getCompanyUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.Result && Array.isArray(action.payload.Result)) {
        state.data = [...action.payload.Result];
      }
    });
  },
});

export const { removeUser, updateUserRole } = usersSlice.actions;

export default usersSlice.reducer;
