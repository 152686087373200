import React, { Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  DropdownItem,
  getDevicesDropdown,
  getLocationsDropdown,
} from "../../store/slices/dropdownOptions/dropdownListSlice";
import { getAllLocations } from "../../services/locations/Location.service";
import { getAllDevices } from "../../services/device/deviceGet.service";
import { TopActionBarWrapper } from "./Dashboard.style";
import ExportDataBtn from "./ExportDataBtn";
import { CustomDropdown } from "../../components/form-field/drop-down/CustomDropdown";
import { DropdownMenuContainer } from "../../components/form-field/drop-down/Dropdown.style";
import { Button } from "../../components/button/Button";
import {
  FilterDropdown,
  FilterOption,
} from "../../components/dropdown/filter-dropdown/FilterDropdown";
import { AppDispatch } from "../../store/store";
import { getAllGroups } from "../../services/locations/GroupType.service";
interface Props {
  setLocationId: (locationId: number) => void;
  setDeviceId: (deviceId: string) => void;
}

const DashboardDropdowns: React.FC<Props> = ({
  setLocationId,
  setDeviceId,
}: Props) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [keyToResetLocationDropdown, setKeyToResetLocationDropdown] =
    useState<number>(0);
  const [keyToResetDeviceDropdown, setKeyToResetDeviceDropdown] =
    useState<number>(2);
  const [selectedLocation, setSelectedLocation] = useState<number>(0);
  const [selectedDevice, setSelectedDevice] = useState<string>("");

  // Get dropdown list and dashboard options from Redux store
  const locationsDropdown = useAppSelector(getLocationsDropdown);
  const devicesDropdown = useAppSelector(getDevicesDropdown);

  // Fetch all locations, devices, and AI models on initial load of the page
  useEffect(() => {
    dispatch(getAllLocations({}));
    dispatch(getAllDevices());
    dispatch(getAllGroups());
  }, []);

  const onValueChange = (
    { label, value }: FilterOption,
    dropdownId: DropdownItem,
  ) => {
    switch (dropdownId) {
      case DropdownItem.Devices:
        setSelectedDevice(value.toString());
        setDeviceId(value.toString());
        break;
      case DropdownItem.Locations:
        setSelectedLocation(Number(value));
        setLocationId(Number(value));
        break;
    }
  };

  const clearDropdowns = () => {
    setSelectedLocation(0);
    setLocationId(Number(null));
    setKeyToResetLocationDropdown((prev) => prev + 1);

    setSelectedDevice("");
    setDeviceId("");
    setKeyToResetDeviceDropdown((prev) => prev + 2);
  };

  // Render Dropdown components based on the dropdownList
  return (
    <TopActionBarWrapper>
      <DropdownMenuContainer padding="0 2rem 2rem">
        {locationsDropdown ?
          <Fragment key={keyToResetLocationDropdown}>
            <FilterDropdown
              title="Location"
              options={locationsDropdown.options}
              onChange={({ label, value }: FilterOption) => {
                onValueChange({ label, value }, locationsDropdown.id);
              }}
            />
          </Fragment>
        : null}
        {devicesDropdown ?
          <Fragment key={keyToResetDeviceDropdown}>
            <FilterDropdown
              title="Device"
              options={devicesDropdown?.options}
              onChange={({ label, value }: FilterOption) => {
                onValueChange({ label, value }, devicesDropdown.id);
              }}
            />
          </Fragment>
        : null}
        <Button color="primary" variant="filled" onClick={clearDropdowns}>
          Clear Filters
        </Button>
      </DropdownMenuContainer>
      <ExportDataBtn
        locationId={selectedLocation || null}
        deviceId={selectedDevice || ""}
      />
    </TopActionBarWrapper>
  );
};

export default DashboardDropdowns;
