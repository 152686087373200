import styled from "styled-components";

interface PopupWrapperProps {
  errorCode?: number | null;
  isOpen: boolean;
}

export const PopupWrapper = styled.div<PopupWrapperProps>`
  position: fixed;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${(props) => {
    switch (props.errorCode) {
      case 200:
      case 201:
        return "rgba(76, 175, 80, 0.9)"; // Slightly darker green with transparency
      case 400:
      case 404:
      case 500:
        return "rgba(244, 67, 54, 0.9)"; // Slightly darker red with transparency
      case null:
        return "rgba(33, 150, 243, 0.9)"; // Slightly darker blue with transparency
      default:
        return "rgba(244, 67, 54, 0.9)"; // Default to red with transparency
    }
  }};
  color: white;
  padding: 0.2rem 0.5rem;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  transition: 0.3s ease-in-out;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const PopupTextWrapper = styled.article`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupText = styled.span`
  font-size: ${(props) => props.theme.fontSizes["md"]};
  color: #fff;
`;
