import React, { useState } from "react";
import { StyledTable } from "./Table.style";
import { TokenGenerationTableData } from "../../data/tokenGenerationTable";
import { StyledBgIoMdTrash } from "./Table.style";
import ConfirmationModal from "../modals/confirmation-modal/ConfirmationModal";

export interface TableProps {
  columns: string[];
  data: TokenGenerationTableData[];
  handleItemCheckChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  width?: string;
  trBorder?: string;
  tdColor?: string;
}

const TokenGenerationTable = ({
  columns,
  data,
  width,
  trBorder,
  tdColor,
  handleItemCheckChange,
}: TableProps) => {
  const options: any = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentKeyToDelete, setCurrentKeyToDelete] = useState<string | null>(
    null,
  );
  const [confirmMsg, setConfirmMsg] = useState<string>("");

  const handleDeleteClick = (keyName: string) => {
    const msg = `Are you sure you'd like to delete the key ${keyName}? This cannot be undone.`;
    setCurrentKeyToDelete(keyName);
    setConfirmMsg(msg);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    console.log(`Delete key: ${currentKeyToDelete}`);
    // You can place your deletion logic here, like making an API call
    // After the deletion, close the modal
    setDeleteModalOpen(false);
    setCurrentKeyToDelete(null);
  };

  const handleConfirmModalClose = () => {
    setDeleteModalOpen(false);
    setCurrentKeyToDelete(null);
  };

  return (
    <>
      <StyledTable width={width} trBorder={trBorder} tdColor={tdColor}>
        <thead>
          <tr>
            {columns.map((column, index) => {
              return <th key={index}>{column}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.secureKey}</td>
                <td>{item.created.toLocaleString("en-CA", options)}</td>
                <td>{item.lastUsed.toLocaleString("en-CA", options)}</td>
                <td>
                  <StyledBgIoMdTrash
                    onClick={() => handleDeleteClick(item.secureKey)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        onConfirm={handleConfirmDelete}
        headerTitle={"Delete Key"}
        confirmMsg={confirmMsg}
        onClose={handleConfirmModalClose}
        modalWidth="30vw"
        minWidth="450px"
      />
    </>
  );
};

export default TokenGenerationTable;
