import React from "react";
import ImageCard from "../../components/image-card/ImageCard";
import locationPage1 from "../../assets/locationpage1.png";
import { ControlButtons } from "../../components/device-manager-card/image-card/ImageCard.style";
import { Chip } from "../../components/chip/Chip";
import { ChipStyles } from "../../enums/chip/ChipEnums";
import { Location } from "../../interfaces/device/Location";

// Define props interface
interface CardProps {
  location: Location;
  className?: string;
  onClick?: any;
}

// Define functional component
const LocationCard: React.FC<CardProps> = ({
  className,
  location,
  onClick,
}) => {
  return (
    <ImageCard
      className={`location-card ${className || ""}`}
      image={location.Thumbnail! || locationPage1}
      title={location.Name || ""}
      subtitle={location.Address}
      onClick={onClick}
    >
      <ControlButtons>
        <Chip
          children={location.Enabled ? ChipStyles.Enabled : ChipStyles.Disabled}
          backgroundColor={location.Enabled ? "blue" : "grey"}
        />
      </ControlButtons>
    </ImageCard>
  );
};
export default LocationCard;
