import React from "react";
import { useAppDispatch } from "../../../../../store/hooks";
import { Group } from "../../../../../interfaces/device/Group";
import DeleteIcon from "../../../../../components/icons/DeleteIcon";
import { OpenSuccessNotification } from "../../../../../components/notification/Notification";
import {
  GroupBox,
  GroupDelete,
  GroupTitle,
} from "../../../../../components/tab/device-manager/grouping/Grouping.style";
import GroupService, {
  removeLocationFromGroup,
} from "../../../../../services/locations/Group.service";
import {
  removeGroupFromLocation,
  setAllGroups,
} from "../../../../../store/slices/locations/locationsSlice";
import RemoveIcon from "../../../../../components/icons/RemoveIcon";
interface Props {
  group: Group;
}
export default function LocationGroupCard({ group }: Props) {
  const dispatch = useAppDispatch();
  const onRemove = async () => {
    try {
      const response = await removeLocationFromGroup(group.Id);
      console.log("On remove Response", response);
      if (response && !response.IsError) {
        OpenSuccessNotification(
          "The Group has been removed successfully from Location",
        );
        dispatch(removeGroupFromLocation(response.Result));
        GroupService.getGroups({}).then((response) => {
          if (response?.Result) {
            dispatch(
              setAllGroups(
                response?.Result.filter((g: Group) => !g.LocationId),
              ),
            );
          }
        });
      }
    } catch (e: any) {
      console.log("onRemove Error", e.message);
    }
  };
  return (
    <GroupBox>
      <GroupDelete>
        <RemoveIcon handleRemoveOnClick={onRemove} size={"1.5rem"} />
      </GroupDelete>
      <GroupTitle>{group.Name}</GroupTitle>
    </GroupBox>
  );
}
