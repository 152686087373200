import { ScaledButtonWrapper } from "../../components/modals/CustomModal.style";
import Titles from "../../components/title/Titles";
import { NotFoundContainer } from "./styles/NotFound.style";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const handleHomeReturn = () => {
    navigate("/");
  };

  return (
    <NotFoundContainer>
      <Titles
        heading="Page Not Found"
        subheading="Oops! The page you're looking for does not exist or it may have been moved."
        marginTop="0"
      />
      <ScaledButtonWrapper>
        <button onClick={handleHomeReturn}>{"Back to home"}</button>
      </ScaledButtonWrapper>
    </NotFoundContainer>
  );
};

export default NotFound;
