import React, { useState } from "react";
import Checkbox from "../checkbox/Checkbox";
import { AiModelManagerData } from "../../store/slices/ai-models/aiModelSlice";
import EditForm from "../tab/ai-model-manager/EditForm";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import imagePlaceholder from "../../assets/Icon_AIModel.svg";

interface TableRowProps {
  item: AiModelManagerData;
  rebindData: () => void;
  handleItemCheckChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleItemDownload: (aiModelId: number) => void;
  handleViewDevices: (aiModelId: number) => void;
  handleRetrainOnClick: (aiModelId: number) => void;
  handleDuplicateOnClick: (aiModelId: number) => void;
  handleDeleteOnClick: (aiModelId: number) => void;
  handleArchiveOnClick: (aiModelId: number) => void;
}

const TableRow = ({
  item,
  rebindData,
  handleItemCheckChange,
  handleItemDownload,
  handleViewDevices,
  handleRetrainOnClick,
  handleDuplicateOnClick,
  handleDeleteOnClick,
  handleArchiveOnClick,
}: TableRowProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const imageSrc = item.model[0];
  const imageAlt = item.model[1];
  const aiModelName = item.model[1];

  const handleEditOnClick = () => {
    setIsEdit(true);
  };
  const handleCancelOnClick = () => {
    setIsEdit(false);
  };
  const handleCloseEditMode = () => {
    setIsEdit(false);
    rebindData();
  };

  return (
    <>
      <tr key={item.id}>
        <td>
          <Checkbox
            isChecked={item.checked}
            value={item.id}
            onChange={handleItemCheckChange}
          />
        </td>
        <td>
          <div style={{ display: "flex", alignItems: "center" }}>
            <LazyLoadImage
              key={imageSrc}
              src={imageSrc! || imagePlaceholder}
              alt={imageAlt}
              effect="blur"
              width="150px"
              height="77px"
              style={{
                borderRadius: "6px",
                background: "#FFF",
              }}
            />
            <span style={{ margin: "0 0 0 2rem" }}>{aiModelName}</span>
          </div>
        </td>
        <td>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {item.actionIcons.map((Icon, index) => (
              <Icon
                key={index}
                handleArchiveOnClick={() => handleArchiveOnClick(item.id)}
                handleEditOnClick={handleEditOnClick}
                handleDownloadOnClick={() => handleItemDownload(item.id)}
                handleViewDevicesOnClick={() => handleViewDevices(item.id)}
                handleRetrainOnClick={() => handleRetrainOnClick(item.id)}
                handleDuplicateOnClick={() => handleDuplicateOnClick(item.id)}
                handleDeleteOnClick={() => handleDeleteOnClick(item.id)}
              />
            ))}
          </div>
        </td>
        <td>{item.activeDevices}</td>
        <td>{item.dateCreated}</td>
      </tr>
      {isEdit && (
        <tr>
          <td colSpan={6}>
            <EditForm
              item={item}
              onCancel={handleCancelOnClick}
              onSubmitCloseEditMode={handleCloseEditMode}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default TableRow;
