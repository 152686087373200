import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

const initialState = [
  { id: 1, name: "ascending", isActive: true },
  { id: 2, name: "descending", isActive: false },
  { id: 3, name: "image", isActive: true },
  { id: 4, name: "video", isActive: false },
];

export const sortBySlice = createSlice({
  name: "sortBy",
  initialState,
  reducers: {
    setSortBysForCaptures: (state) => {
      state[0].isActive = false;
      state[1].isActive = true;
    },
    switchSortBy: (state, action: PayloadAction<number>) => {
      const activeSortById = action.payload;
      let sortByGroup: number[];

      if (activeSortById == 1 || activeSortById == 2) {
        sortByGroup = [1, 2];
      } else {
        sortByGroup = [3, 4];
      }

      state.map((sortByItem) => {
        if (sortByGroup.includes(activeSortById && sortByItem.id)) {
          sortByItem.isActive = sortByItem.id === activeSortById ? true : false;
        }
      });
    },
    resetSortByState: (state) => {
      return initialState;
    },
  },
});

export const selectAllSortByIcons = (state: RootState) => state.sortBy;

export const { setSortBysForCaptures, switchSortBy, resetSortByState } =
  sortBySlice.actions;

export default sortBySlice.reducer;
