import { useEffect, useState } from "react";
import Input from "../../input/Input";
import {
  FormWrapper,
  ScaledButtonWrapper,
} from "../../modals/CustomModal.style";
import TextEditor from "../../rich-text-editor/TextEditor";
import Titles from "../../title/Titles";
import { Typography } from "../../typrography/Typography";
import {
  EditorWrapper,
  ErrorText,
  StyledFormikForm,
  UploadWrapper,
} from "./Upload.style";
import DragUpload from "../../input/upload-input/DragUpload";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AppDispatch, RootState } from "../../../store/store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import AIModelService, {
  aiModelUploadParams,
} from "../../../services/aiModels/aimodel.service";
import {
  fileAction,
  addNewAIModel,
  resetAllFiles,
  FileActions,
} from "../../../store/slices/ai-models/aiModelSlice";
import {
  OpenErrorNotification,
  OpenInfoNotification,
  OpenSuccessNotification,
} from "../../notification/Notification";
import { ResponseObject } from "../../../interfaces/response/Response";
import { AIModel } from "../../../interfaces/aiModel/AIModel";
import Loader from "../../loader/Loader";

export interface UploadProps {
  file?: File | null;
  width?: string;
  height?: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must not exceed 50 characters")
    .required("Name is required")
    .matches(/^[A-Za-z_\s]+$/g, "No numbers or special characters besides _"),
  description: Yup.string()
    .min(2, "Description must be at least 2 characters")
    .max(50, "Description must not exceed 50 characters")
    .required("Description is required"),
});

const Upload = ({ width, height }: UploadProps) => {
  const dispatch: AppDispatch = useAppDispatch();
  const aiModels = useAppSelector((state: RootState) => state.aiModels);
  const [keyToResetValue, setKeyToResetValue] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("");

  const initialValues: aiModelUploadParams = {
    aiModelFile: null,
    name: "",
    /* Commented out for the time being, will re-implement if need be */
    // labelFiles: [],
    imageFile: null,
    description: "",
    manualLabel: "",
  };

  const handleAIModelFileUpload = (uploadedFile: File) => {
    const fileUploadObj: FileActions = {
      file: uploadedFile,
      type: "AI_MODEL",
      action: "UPLOAD",
    };

    dispatch(fileAction(fileUploadObj));
  };

  const handleThumbnailFileUpload = (uploadedFile: File) => {
    const fileUploadObj: FileActions = {
      file: uploadedFile,
      type: "THUMBNAIL",
      action: "UPLOAD",
    };

    dispatch(fileAction(fileUploadObj));
  };

  const handleAIModelFileRemove = () => {
    const fileRemoveObj: FileActions = {
      file: null,
      type: "AI_MODEL",
      action: "REMOVE",
    };

    dispatch(fileAction(fileRemoveObj));
  };

  const handleThumbnailFileRemove = () => {
    const fileRemoveObj: FileActions = {
      file: null,
      type: "THUMBNAIL",
      action: "REMOVE",
    };

    dispatch(fileAction(fileRemoveObj));
  };

  const handleSubmit = async (
    values: aiModelUploadParams,
    { setSubmitting, resetForm }: any,
  ) => {
    setUploadStatus("pending");
    values.aiModelFile = aiModels.aiModelFile!;
    values.imageFile = aiModels.thumbnailFile!;
    /* Commented out for the time being, will re-implement if need be */
    //values.labelFiles = aiModels.labelFiles!;

    const response: ResponseObject<AIModel> | null =
      await AIModelService.uploadAIModel(values, dispatch);

    const resetInputs = async () => {
      setKeyToResetValue(keyToResetValue + 1);
      dispatch(resetAllFiles());
      resetForm();
    };

    setUploadStatus("");
    if (response !== null && !response.IsError) {
      await resetInputs();
      setSubmitting(false);
      dispatch(addNewAIModel(response.Result));
    }
  };

  useEffect(() => {
    if (aiModels.aiModelFile !== null || aiModels.thumbnailFile !== null) {
      dispatch(resetAllFiles());
    }
  }, []);

  return (
    <>
      {uploadStatus === "pending" && (
        <Loader loadingText="Uploading AI Model, please wait" isLoadingScreen />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          isValid,
          handleChange,
          handleBlur,
          setFieldTouched,
          values,
        }) => (
          <StyledFormikForm>
            <UploadWrapper>
              <DragUpload
                key={`aiModelUploadFile_${keyToResetValue}`}
                onFileUpload={handleAIModelFileUpload}
                onFileRemove={handleAIModelFileRemove}
                labelText="Choose an AI model file or drag it here"
                height="13rem"
                flex={2}
                id="aiModelFile"
              />
              <DragUpload
                key={`thumbnailUploadFile_${keyToResetValue}`}
                onFileUpload={handleThumbnailFileUpload}
                onFileRemove={handleThumbnailFileRemove}
                labelText="Choose a thumbnail file or drag it here"
                height="13rem"
                flex={1}
                id="imageFile"
              />
            </UploadWrapper>
            <FormWrapper>
              <Input
                name={"name"}
                placeHolder={"Insert Name"}
                label={"Model Name"}
                width={"23vw"}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/*
                Label files commented out for the time being
              */}
              {/* <MultipleUploadInput
                name={"labelFiles"}
                variant="input"
                placeHolder={"Insert Label Files"}
                label={"Label files"}
                width={"23vw"}
              />
              <Input
                name={"manualLabel"}
                placeHolder={"Insert Labels Manually"}
                label={"Manually input labels"}
                subLabel={'(Separate with space or ",")'}
                width={"23vw"}
                onChange={handleChange}
                onBlur={handleBlur}
              /> */}
            </FormWrapper>
            <EditorWrapper key={`aiModelTextEditor_${keyToResetValue}`}>
              <Typography
                variant="lg"
                direction="flex-start"
                style={{ fontWeight: "700", padding: "10px 0" }}
              >
                {"Model Description"}
              </Typography>
              <Field name="description">
                {({ field, form }: any) => (
                  <TextEditor
                    initialValue={initialValues.description}
                    field={field}
                    form={form}
                    onBlur={() => setFieldTouched("description", true)}
                  />
                )}
              </Field>
              <ErrorMessage name="description" component={ErrorText} />
            </EditorWrapper>
            <ScaledButtonWrapper buttonPadding="1rem">
              <button type="submit">Upload Model</button>
            </ScaledButtonWrapper>
          </StyledFormikForm>
        )}
      </Formik>
    </>
  );
};

export default Upload;
