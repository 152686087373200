import React from "react";
import americanExpress from "../../assets/AmericanExpress.svg";
import {
  AmericanExpressWrapper,
  StyledAmericanExpressIcon,
} from "./MastercardIcon.style";

type Props = {};

const AmericanExpress = (props: Props) => {
  return (
    <AmericanExpressWrapper>
      <StyledAmericanExpressIcon
        src={americanExpress}
        alt="american express card icon"
      />
    </AmericanExpressWrapper>
  );
};

export default AmericanExpress;
