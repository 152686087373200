import React from "react";
import { DeviceManagerMain } from "../../../device-manager-elements/deviceManagerMain";
import { SensorBlock } from "../sensors/Sensors.style";
import { Typography } from "../../../typrography/Typography";
import { useAppSelector } from "../../../../store/hooks";
import AddDeviceGroup from "./AddDeviceGroup";
import { DeviceGroup } from "../../../../interfaces/device/DeviceGroup";
import DeviceGroupCard from "./DeviceGroupCard";

export const Grouping = () => {
  const deviceGroups = useAppSelector(
    (state) => state.devices.selectedDevice.Groups || [],
  );
  const selectedDeviceLocationId = useAppSelector(
    (state) => state.devices.selectedDevice.Location,
  );
  const allGroups = useAppSelector(
    (state) => state.locations?.groupOptions || [],
  );
  const locationGroups =
    allGroups?.filter(
      (g) =>
        g.LocationId === selectedDeviceLocationId?.Id &&
        deviceGroups.findIndex((dg) => dg.GroupId === g.Id) === -1,
    ) || [];

  return (
    <DeviceManagerMain data={[]}>
      <SensorBlock>
        {!locationGroups.length && !deviceGroups.length ?
          <Typography color="red-100" variant="lg">
            No Group Available
          </Typography>
        : null}
        {!!deviceGroups.length &&
          deviceGroups.map((group: DeviceGroup) => (
            <DeviceGroupCard group={group} key={group.Id} />
          ))}
        {!!locationGroups.length && (
          <AddDeviceGroup locationGroups={locationGroups} />
        )}
      </SensorBlock>
    </DeviceManagerMain>
  );
};
