import axios, { AxiosError, AxiosResponse } from "axios";
import { Sensor } from "../../interfaces/device/Sensor";
import { OpenErrorNotification } from "../../components/notification/Notification";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import {
  validateErrorResult,
  validateFetchErrorResult,
  validateFetchSuccessResult,
  validateSuccessResult,
} from "../../utils/axiosUtils";
import { ResponseObject } from "../../interfaces/response/Response";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

const searchSensor = async (
  deviceId: string,
  id: number,
  active: boolean,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const searchString = `?deviceId${
    deviceId.length > 0 ? "=" + deviceId : ""
  }&id${id > 0 ? "=" + id : ""}&active${active ? "=" + active : ""}`;

  const response = await axios
    .get(`${API_URL}/Sensor/filter${searchString}`)
    .then(async (response: AxiosResponse<ResponseObject<Sensor[]>>) => {
      // Validate Success Result
      const result = response.data;
      await validateFetchSuccessResult(response);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateFetchErrorResult(error, dispatch);
    });

  return response;
};

const patchSensor = async (
  sensor: Sensor,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const response = await axios
    .patch(`${API_URL}/sensor/active`, sensor)
    .then(async (response: AxiosResponse<ResponseObject<Sensor>>) => {
      // Validate Success Result
      const result = response.data;
      const successMessage: string = "Sensor updated.";
      await validateSuccessResult(response, dispatch, successMessage);

      return result;
    })
    .catch(async (error: AxiosError<ResponseObject<string>>) => {
      return await validateErrorResult(error, dispatch);
    });

  return response;
};

const sensorService = {
  searchSensor,
  patchSensor,
};

export default sensorService;
