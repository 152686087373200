import React, { useState, useEffect } from "react";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavigationButton } from "../../../../components/button/navigation-button/NavigationButton";
import { FlexContainer } from "../../../../components/flex-container/FlexContainer";
import { FormControl } from "../../../../components/form-field/form-control/FormControl";
import { FormLabel } from "../../../../components/form-field/form-label/FormLabel";
import { TextField } from "../../../../components/form-field/text-field/TextField";
import { Image } from "../../../../components/image/Image";
import { Typography } from "../../../../components/typrography/Typography";
import {
  CustomInfoCard,
  CustomInfoCardSingleColumn,
} from "../../../../components/info-card/InfoCard.style";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getLocationTypeDropdown } from "../../../../store/slices/dropdownOptions/dropdownListSlice";
import { RootState } from "../../../../store/store";
import LocationService, {
  getAllLocationsType,
  patchLocation,
} from "../../../../services/locations/Location.service";
import GroupService from "../../../../services/locations/Group.service";
import ToggleButton from "../../../../components/button/toggle-button/ToggleButton";
import {
  faBuilding,
  faLocation,
  faTruck,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import { Location } from "../../../../interfaces/device/Location";
import { Group } from "../../../../interfaces/device/Group";
import {
  setAllGroups,
  updateLocationGroups,
} from "../../../../store/slices/locations/locationsSlice";
import LocationEditToolTip from "./LocationEditToolTip";
import LocationToolTipGroup from "./LocationToolTipGroup";
import locationPage1 from "../../../../assets/locationpage1.png";
import LocationImageUpload from "./LocationImageUpload";
import LocationAddress from "./LocationAddress";
import LocationGroups from "./Groups/LocationGroups";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ResponseObject } from "../../../../interfaces/response/Response";
import {
  FilterDropdown,
  FilterOption,
} from "../../../../components/dropdown/filter-dropdown/FilterDropdown";
import LocationDevicesMap from "../../../../components/map/LocationDevicesMap";
import { LatLngExpression } from "leaflet";
import { DeviceMarker } from "../../../../interfaces/mapMarkers/DeviceMarker";
import { SwrmDevices } from "../../../../interfaces/device/SwrmDevice";
import { IntegratedLatestSensorData } from "../../../../interfaces/sensorData/IntegratedSensorData";
import { integrationService } from "../../../../services/integration/integration.service";
import { getLastScannedSensorData } from "../../../../services/dashboard/dashboard.service";
import Loader from "../../../../components/loader/Loader";

interface Props {
  location: Location;
  devices: SwrmDevices[];
  next: any;
  prev: any;
}
export const InfoCardLocation: React.FC<Props> = ({
  location,
  devices,
  next,
  prev,
}) => {
  /* Hooks */
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state: RootState) => state.persisted.auth);
  const groups = useAppSelector((state: RootState) => state.locations.groups);
  const locationTypeDropdown = useAppSelector(getLocationTypeDropdown);
  const allLocations = useAppSelector(
    (state: RootState) => state.locations.data,
  );
  const integrations = useAppSelector(
    (state: RootState) => state.integration.integrations,
  );

  /* Constants Variables */
  const address: string | undefined = location.Address?.toString();
  const selectedLocationType: FilterOption | undefined =
    locationTypeDropdown?.options.find((item) => {
      return item.value == location.LocationTypeId;
    });

  /* States */
  const [isLoadingLocationData, setIsLoadingLocationData] =
    useState<boolean>(true);
  const [textFieldValue, setTextFieldValue] = useState(location.Name || "");
  const [deviceMarkers, setDeviceMarkers] = useState<DeviceMarker[]>([]);
  const [textfieldAddress, settextfieldAddress] = useState<string | undefined>(
    address,
  );
  const [selectedOptions, setSelectedOptions] = useState<number[]>(
    new Array(5).fill(0),
  );
  const [locationPosition, setLocationPosition] = useState<LatLngExpression>([
    parseFloat(location.GPSLatitude),
    parseFloat(location.GPSLongitude),
  ]);

  /* Methods */
  const handleDropdownChange = async (selectedOptionId: number) => {
    try {
      const locationObj: Location | undefined = allLocations.find(
        (l: Location) => l.Id === location.Id,
      );
      if (locationObj) {
        handleLocationUpdate({
          ...locationObj,
          LocationTypeId: selectedOptionId,
        });
      }
    } catch (error) {
      console.error("Error updating enabled status:", error);
    }
  };
  const handleGroupDropdownChange = async (
    selectedOptionId: number,
    selectedOption: any,
  ) => {
    try {
      LocationService.patchGroup({
        id: location.Id,
        groupID: selectedOptionId,
      })
        .then((response: any) => {
          // update Selected option
          setSelectedOptions(new Array(5).fill(0));
          // update state with value
          dispatch(
            updateLocationGroups({
              locationId: location.Id,
              groupID: selectedOptionId,
              group: response.Result,
            }),
          );
          // remove group from dropdown
          dispatch(
            setAllGroups(
              groups.filter((g: Group) => g.Id !== selectedOptionId),
            ),
          );
        })
        .catch((error: unknown) => {
          console.error("API call error:", error);
        });
    } catch (error) {
      console.error("Error updating enabled status:", error);
    }
  };

  const handleToggleChange = (newState: boolean, revert: () => void) => {
    try {
      const locationObj: Location | undefined = allLocations.find(
        (l: Location) => l.Id === location.Id,
      );
      if (locationObj) {
        handleLocationUpdate({ ...locationObj, Enabled: newState }, revert);
      }
    } catch (error) {
      console.error("Error updating enabled status:", error);
    }
  };
  const handleNameChange = () => {
    try {
      const locationObj: Location | undefined = allLocations.find(
        (l: Location) => l.Id === location.Id,
      );
      if (locationObj) {
        handleLocationUpdate({ ...locationObj, Name: textFieldValue || "" });
      }
    } catch (error) {
      console.error("Error updating enabled status:", error);
    }
  };
  const handleAddressChange = () => {
    try {
      const locationObj: Location | undefined = allLocations.find(
        (l: Location) => l.Id === location.Id,
      );
      if (locationObj) {
        handleLocationUpdate({
          ...locationObj,
          Address: textfieldAddress || "",
        });
      }
    } catch (error) {
      console.error("Error updating enabled status:", error);
    }
  };
  const handleLocationUpdate = async (
    locationObj?: Location,
    revert?: () => void,
  ) => {
    if (locationObj) {
      const result = await dispatch(patchLocation(locationObj));
      const response: ResponseObject<Location> = result.payload;

      if (response.IsError) revert!();
    }
  };

  const fetchLocationData = async () => {
    const integrationSensorData: IntegratedLatestSensorData[] = [];

    // Fetch Integration Devices Sensor Data
    if (auth.company.IntegratedWith !== null && integrations[0].Enabled) {
      const integrationResponse: ResponseObject<
        IntegratedLatestSensorData[]
      > | null = await integrationService.getLatestSensorData(
        dispatch,
        null,
        location.Id,
      );

      if (integrationResponse !== null) {
        if (
          !integrationResponse.IsError &&
          integrationResponse.Result.length > 0
        )
          integrationSensorData.push(...integrationResponse.Result);
      }
    }

    // Fetch SWRM Devices Sensor Data
    const swrmResponse: ResponseObject<any[]> = await getLastScannedSensorData(
      location.Id,
      "",
    );

    // Fetch Location, Device, and AI Model data
    await dispatch(getAllLocationsType({}));
    GroupService.getGroups({}).then((response: any) => {
      if (response?.Result) {
        dispatch(
          setAllGroups(response?.Result.filter((g: Group) => !g.LocationId)),
        );
      }
    });

    setSelectedOptions((prevState) =>
      selectedOptions.map((o, i) =>
        Array.isArray(location.Groups) && location.Groups.length > i ?
          location.Groups[i].Id
        : selectedOptions[i],
      ),
    );

    // Set Device Markers
    const devicesOfLocation: SwrmDevices[] = devices.filter((device) => {
      return device.Location?.Id === location.Id;
    });

    const deviceMarkersOfLocation: DeviceMarker[] = devicesOfLocation.map(
      (device) => {
        return {
          Device: device,
          DeviceSensorData: {
            SWRMSensorData: swrmResponse.Result,
            IntegrationSensorData: integrationSensorData,
          },
        };
      },
    );

    setDeviceMarkers(deviceMarkersOfLocation);
    setIsLoadingLocationData(false);
  };

  // Fetch location data
  useEffect(() => {
    fetchLocationData();
  }, []);

  return (
    <>
      {isLoadingLocationData ?
        <FlexContainer width="100%" justifyContent="center">
          <Loader loadingText="Loading location data" />
        </FlexContainer>
      : <>
          <CustomInfoCard>
            <LazyLoadImage
              key={location.Thumbnail}
              src={location.Thumbnail || locationPage1}
              alt={location.Name}
              effect="blur"
              width="100%"
              height="100%"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />

            <FlexContainer
              justifyContent="space-between"
              alignItems="flex-start"
              height="100%"
            >
              <FlexContainer
                flexDirection="column"
                justifyContent="stretch"
                alignItems="flex-start"
                gap="32px"
              >
                <FlexContainer
                  justifyContent="flex-start"
                  gap="12px"
                  alignItems="flex-start"
                >
                  {/* the id */}
                  {
                    <FlexContainer gap="10px">
                      <FlexContainer gap="4px">
                        <Typography weight="bold">GPS:</Typography>
                        <Typography>{location.Address}</Typography>
                      </FlexContainer>
                    </FlexContainer>
                  }
                </FlexContainer>

                {/* header and additional content */}
                <FlexContainer
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  {/* primary and secondary header */}
                  <FlexContainer
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="12px"
                  >
                    <FlexContainer justifyContent="flex-start" gap="30px">
                      <Typography variant="4xl" weight="bold">
                        {location.Name}
                      </Typography>
                      <LocationEditToolTip
                        locationName={location.Name}
                        locationId={location.Id}
                      />
                      <ToggleButton
                        isActive={location.Enabled}
                        onToggle={(status, revert) =>
                          handleToggleChange(status, revert)
                        }
                      />
                    </FlexContainer>

                    {/* location address and edit address */}
                    <LocationAddress
                      locationId={location.Id}
                      address={location.Address}
                    />

                    {/* other info */}
                    <FlexContainer justifyContent="flex-start" gap="15px">
                      <LocationToolTipGroup locationId={location.Id} />
                    </FlexContainer>
                  </FlexContainer>

                  {/* Additional data container */}
                  <div
                    style={{
                      marginTop: "15px",
                      gap: "8px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <FlexContainer
                      flexDirection="column"
                      gap="8px"
                      alignItems="flex-start"
                    >
                      {location.LocationTypeId &&
                        locationTypeDropdown &&
                        locationTypeDropdown.options && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography variant="md">
                              {(
                                locationTypeDropdown.options.find(
                                  (l) => l.value === location.LocationTypeId,
                                )?.label === "Vehicle"
                              ) ?
                                <FontAwesomeIcon icon={faTruck} />
                              : (
                                locationTypeDropdown.options.find(
                                  (l) => l.value === location.LocationTypeId,
                                )?.label === "Job Site"
                              ) ?
                                <FontAwesomeIcon icon={faLocation} />
                              : (
                                locationTypeDropdown.options.find(
                                  (l) => l.value === location.LocationTypeId,
                                )?.label === "Building"
                              ) ?
                                <FontAwesomeIcon icon={faBuilding} />
                              : null}
                            </Typography>
                            <Typography
                              variant="md"
                              weight="bold"
                              style={{ marginLeft: "10px" }}
                            >
                              {
                                locationTypeDropdown.options.find(
                                  (l) => l.value === location.LocationTypeId,
                                )?.label
                              }
                            </Typography>
                          </div>
                        )}
                      {location?.Groups &&
                        locationTypeDropdown &&
                        [...Array(locationTypeDropdown.options)].map(
                          (group, index) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                }}
                                key={index}
                              >
                                <Typography
                                  variant="md"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  <FontAwesomeIcon icon={faLayerGroup} />
                                  <span style={{ marginLeft: "10px" }}>
                                    Groups:{" "}
                                    {Array.isArray(location.Groups) ?
                                      location.Groups.map((item) =>
                                        item.Name ? item.Name : "",
                                      ).join(", ")
                                    : location?.Groups}
                                  </span>
                                </Typography>
                              </div>
                            );
                          },
                        )}
                    </FlexContainer>
                  </div>
                </FlexContainer>
              </FlexContainer>

              {/* navigation buttons */}
              <div>
                <FlexContainer
                  justifyContent="space-between"
                  flexDirection="column"
                  alignItems="flex-end"
                  height="100%"
                >
                  <FlexContainer gap="4px">
                    <NavigationButton onClick={prev}>
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </NavigationButton>
                    <NavigationButton onClick={next}>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </NavigationButton>
                  </FlexContainer>
                </FlexContainer>
              </div>
            </FlexContainer>
          </CustomInfoCard>

          {/* location devices map */}
          {location.GPSLatitude &&
            location.GPSLongitude &&
            deviceMarkers.length !== 0 && (
              <LocationDevicesMap
                position={locationPosition}
                markers={deviceMarkers}
              />
            )}

          {/* manage cards */}
          <CustomInfoCardSingleColumn>
            <FlexContainer
              alignItems="flex-start"
              justifyContent="space-around"
              height="100%"
              flexDirection="column"
            >
              <FlexContainer gap="32px">
                <LocationImageUpload
                  locationId={location.Id}
                  media={location.Media}
                />
                <FormControl>
                  <FormLabel htmlFor="" style={{ paddingTop: "10px" }}>
                    Location Name
                  </FormLabel>
                  <div style={{ marginTop: "12px", marginBottom: "19px" }}>
                    <TextField
                      id=""
                      type="text"
                      name="name"
                      placeholder="Location Name"
                      color="secondary"
                      label=""
                      value={textFieldValue}
                      onChange={(e) => setTextFieldValue(e.target.value)}
                      onBlur={() => handleNameChange()}
                      style={{ fontWeight: "bold" }}
                    />
                  </div>
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="" style={{ paddingTop: "10px" }}>
                    Address
                  </FormLabel>
                  <div style={{ marginTop: "12px", marginBottom: "19px" }}>
                    <TextField
                      id=""
                      type="text"
                      name="address"
                      placeholder="Enter address"
                      color="secondary"
                      label=""
                      value={textfieldAddress}
                      onChange={(e) => settextfieldAddress(e.target.value)}
                      onBlur={() => handleAddressChange()}
                      style={{ fontWeight: "bold" }}
                    />
                  </div>
                </FormControl>

                {/* Render dropdowns */}
                <FormControl>
                  <FormLabel htmlFor="">Location Type</FormLabel>
                  <FilterDropdown
                    defaultValue={
                      selectedLocationType ? selectedLocationType : (
                        { label: "", value: 0 }
                      )
                    }
                    title="Location Type"
                    options={locationTypeDropdown?.options || []}
                    onChange={({ label, value }: FilterOption) => {
                      handleDropdownChange(Number(value));
                    }}
                  />
                </FormControl>
              </FlexContainer>

              <LocationGroups
                locationGroups={location.Groups || []}
                groups={groups}
                selectedOptions={selectedOptions}
                handleGroupDropdownChange={handleGroupDropdownChange}
              />
            </FlexContainer>
          </CustomInfoCardSingleColumn>
        </>
      }
    </>
  );
};
