import React from "react";
import { IconWrapper, StyledMasterIcon } from "./MastercardIcon.style";
import icon from "../../assets/jcb.png";

type Props = {};

const JCBIcon = (props: Props) => {
  return (
    <IconWrapper>
      <StyledMasterIcon src={icon} alt="jcb icon" />
    </IconWrapper>
  );
};

export default JCBIcon;
